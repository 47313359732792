import React from "react";
import Button from "../../components/Button/Button";
import PartnershipMarqee from "../../components/Marqee/PartnershipMarqee";
import HomeVIdeos from "../../components/HomeVideos/HomeVIdeos";
import Scrollcard from "../../pages/Home/Scrollcard";
import heroImg from "../../assets/img/ServicePage2/image 373.png";
import girls from "../../assets/img/ServicePage2/girls.png";
import cardImage1 from "../../assets/img/ServicePage2/cardImage1.png";
import "./ServicePage2.css";
import Marquee from "react-fast-marquee";
import Faq from "../../components/Faq/Faq";
import NewsSlider from '../../components/NewsSlider/NewsSlider';
import { Helmet } from "react-helmet-async";

const ServicePage2 = () => {
  return (
    <div id="service_Page2">

<Helmet>
      <title>Service Page | Webapps Software Solutions </title>
        <meta name="copyright" content="https://webappssoft.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webappssoft.com/" />
        <link rel="next" href="https://webappssoft.com/" />
        <meta property="og:title" content="Property Dealers in Jamshedpur | Property Dealers Near Me" />
        <meta property="og:url" content="https://webappssoft.com/" />
        <meta property="og:image" content="https://webappssoft.com/logo/sy-logo.jpg" />
        <meta property="og:description" content="Property Dealers in Jamshedpur: Find 150+ Reliable real estate agents and Brokers in Jamshedpur. Contact residential and Commercial property consultants online." />
        <meta name="description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="keywords" content="website design company India,web designing India, best web design company,top web design company,top web design companies India, web design and development company in india" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta property="og:site_name" content="Webapps Software Solutions" />
        <meta name="twitter:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="twitter:title" content="Website Design & Development Company In Kolkata, India" />
        <meta property="og:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:site" content="webapps software Solutions" />
        <meta name="twitter:creator" content="@webappsoftwares" />
        <meta name="theme-color" content="#0098DA" />
        <meta name="author" content="WASS Integrated Tech Enable It Solutions" />
        <meta name="google-site-verification" content="3qKB507bM-zleASA7ZJYxnvl9L30Wk2o4KA7dZksurY" />
      </Helmet>

      <div className="hero">
        <div className="hero_content">
          <h6>👋 HI THERE? I’ M MORPHY</h6>
          <h2>CREATIVE & MEDIA SOLUTIONS </h2>
          <p>
            We will implement a marketing strategy for your brand. If you would
            like to achieve more but you do not know how – we will define new
            directions of{" "}
          </p>
          <div className="btnnn">
            <Button bgColor={"#fff"} color="#800080" title='Schedule Call' />
          </div>
        </div>

        <div className="hero_Img">
          <img src={heroImg} alt="" />
        </div>
      </div>

      <div className="tech_services">
        <div className="skills_list">
          <ul>
            <li>
              <h2>
                UI UX DESIGNER{" "}
                <span>
                  <i className="bx bx-arrow-back"></i>
                </span>
              </h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi,
                inventore iste assumenda debitis nemo accusamus molestias
                praesentium nihil. Id, est.
              </p>
            </li>

            <li>
              <h2>
                UI UX DESIGNER{" "}
                <span>
                  <i className="bx bx-arrow-back"></i>
                </span>
              </h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi,
                inventore iste assumenda debitis nemo accusamus molestias
                praesentium nihil. Id, est.
              </p>
            </li>

            <li>
              <h2>
                UI UX DESIGNER{" "}
                <span>
                  <i className="bx bx-arrow-back"></i>
                </span>
              </h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi,
                inventore iste assumenda debitis nemo accusamus molestias
                praesentium nihil. Id, est.
              </p>
            </li>

            <li>
              <h2>
                UI UX DESIGNER{" "}
                <span>
                  <i className="bx bx-arrow-back"></i>
                </span>
              </h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi,
                inventore iste assumenda debitis nemo accusamus molestias
                praesentium nihil. Id, est.
              </p>
            </li>

            <li>
              <h2>
                UI UX DESIGNER{" "}
                <span>
                  <i className="bx bx-arrow-back"></i>
                </span>
              </h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi,
                inventore iste assumenda debitis nemo accusamus molestias
                praesentium nihil. Id, est.
              </p>
            </li>
          </ul>
        </div>

        <div className="skill_bg">
          <h4>OUR SERVICES</h4>

          <h1>COME ON, WE WILL HELP YOU BUILD YOUR DREAM PROJECT.</h1>

          <div className="img_container">
            <img src={girls} alt="" />
          </div>

          <div className="status">
            <div className="experience">
              <h4>9+</h4>
              <small>Years of Experience</small>
            </div>

            <div className="clients">
              <h4>900+</h4>
              <small>Happy Clinets</small>
            </div>

            <div className="live_prject">
              <h4>350+</h4>
              <small>Happy Clinets</small>
            </div>
          </div>
        </div>
      </div>

      <div className="marquee">
        <PartnershipMarqee />
      </div>

      <div className="recent_work">
        <div className="heading">
          <h1>OUR RECENT WORK</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Praesentium, aspernatur! Lorem ipsum dolor sit amet.
          </p>
        </div>

        <div className="recent_work-cards">
          <div className="cards1">
            <div className="card_title">
              <h5>
                Mobile App Design
                <span className="arrow_">
                  <i className="bx bx-arrow-back"></i>
                </span>
              </h5>
              <h6>Mobile App Design</h6>
            </div>

            <div className="card_image">
              <img src={cardImage1} alt="" />
            </div>
          </div>

          <div className="cards1 cards2">
            <div className="card_title">
              <h5>
                Mobile App Design
                <span className="arrow_">
                  <i className="bx bx-arrow-back"></i>
                </span>
              </h5>
              <h6>Mobile App Design</h6>
            </div>

            <div className="card_image">
              <img src={cardImage1} alt="" />
            </div>
          </div>

          <div className="cards1">
            <div className="card_title">
              <h5>
                Mobile App Design
                <span className="arrow_">
                  <i className="bx bx-arrow-back"></i>
                </span>
              </h5>
              <h6>Mobile App Design</h6>
            </div>

            <div className="card_image">
              <img src={cardImage1} alt="" />
            </div>
          </div>

          <div className="cards1 cards2">
            <div className="card_title">
              <h5>
                Mobile App Design
                <span className="arrow_">
                  <i className="bx bx-arrow-back"></i>
                </span>
              </h5>
              <h6>Mobile App Design</h6>
            </div>

            <div className="card_image">
              <img src={cardImage1} alt="" />
            </div>
          </div>

          <div className="cards1">
            <div className="card_title">
              <h5>
                Mobile App Design
                <span className="arrow_">
                  <i className="bx bx-arrow-back"></i>
                </span>
              </h5>
              <h6>Mobile App Design</h6>
            </div>

            <div className="card_image">
              <img src={cardImage1} alt="" />
            </div>
          </div>

          <div className="cards1 cards2">
            <div className="card_title">
              <h5>
                Mobile App Design
                <span className="arrow_">
                  <i className="bx bx-arrow-back"></i>
                </span>
              </h5>
              <h6>Mobile App Design</h6>
            </div>

            <div className="card_image">
              <img src={cardImage1} alt="" />
            </div>
          </div>

          <div className="cards1">
            <div className="card_title">
              <h5>
                Mobile App Design
                <span className="arrow_">
                  <i className="bx bx-arrow-back"></i>
                </span>
              </h5>
              <h6>Mobile App Design</h6>
            </div>

            <div className="card_image">
              <img src={cardImage1} alt="" />
            </div>
          </div>

          <div className="cards1 cards2">
            <div className="card_title">
              <h5>
                Mobile App Design
                <span className="arrow_">
                  <i className="bx bx-arrow-back"></i>
                </span>
              </h5>
              <h6>Mobile App Design</h6>
            </div>

            <div className="card_image">
              <img src={cardImage1} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="wass_fourney_at-glance">
        <div className="title">
          <h1>WASS Journey at a Glance</h1>
          <p>
            Our team has deep expertise across all areas of digital. Need a deep
            dive on how to set up event tracking for a complex lead process?
            Want to optimize your sales pipeline.
          </p>
        </div>

        <div className="">
          <HomeVIdeos />
        </div>
      </div>

      <div className="scroll_cards">
        <Scrollcard />
      </div>

      <div className="wass_in_news">
        <h1>WASS IN NEWS</h1>

        <div className="news_marqee">
          <NewsSlider />
        </div>
      </div>

      <div className="faq">
        <Faq />
      </div>
    </div>
  );
};

export default ServicePage2;
