import React from "react";
import "./CarrerPage.css";
import Button from "../../components/Button/Button";
import hero from "../../assets/img/CarrerPage/hero.png";
import wass from "../../assets/img/CarrerPage/wsss.png";
import girl from "../../assets/img/CarrerPage/girl;.png";
import kids from "../../assets/img/CarrerPage/kids.png";
import animGirl from "../../assets/img/CarrerPage/aniLoho.png";
import hommie from "../../assets/img/CarrerPage/hommies.png";
import ceo from "../../assets/img/CarrerPage/CEo.png";
import learn from "../../assets/img/CarrerPage/learhn.png";
import wass1 from "../../assets/img/CarrerPage/wass1.png";
import wass2 from "../../assets/img/CarrerPage/wass2.png";
import wass3 from "../../assets/img/CarrerPage/wass3.png";
import Marquee from "react-fast-marquee";
import { Helmet } from "react-helmet-async";

const CarrerPage = () => {
  return (
    <div id="carrer">

      <Helmet>
        <title>Carrer | Webapps Software Solutions </title>
        <meta name="copyright" content="https://webappssoft.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webappssoft.com/" />
        <link rel="next" href="https://webappssoft.com/" />
        <meta property="og:title" content="Property Dealers in Jamshedpur | Property Dealers Near Me" />
        <meta property="og:url" content="https://webappssoft.com/" />
        <meta property="og:image" content="https://webappssoft.com/logo/sy-logo.jpg" />
        <meta property="og:description" content="Property Dealers in Jamshedpur: Find 150+ Reliable real estate agents and Brokers in Jamshedpur. Contact residential and Commercial property consultants online." />
        <meta name="description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="keywords" content="website design company India,web designing India, best web design company,top web design company,top web design companies India, web design and development company in india" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta property="og:site_name" content="Webapps Software Solutions" />
        <meta name="twitter:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="twitter:title" content="Website Design & Development Company In Kolkata, India" />
        <meta property="og:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:site" content="webapps software Solutions" />
        <meta name="twitter:creator" content="@webappsoftwares" />
        <meta name="theme-color" content="#0098DA" />
        <meta name="author" content="WASS Integrated Tech Enable It Solutions" />
        <meta name="google-site-verification" content="3qKB507bM-zleASA7ZJYxnvl9L30Wk2o4KA7dZksurY" />
      </Helmet>
      <div className="hero">
        <img src={hero} alt="" />

        <div className="content">
          <div className="box">
            <h1>
              We make the future of work, work @ <span>wass</span>.
            </h1>

            <div className="btnnn">
              <Button bgColor="#fff" color="#800080" title='Connect ' />
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="heading">
          <h1>#CREATEWASS</h1>
          <p>
            We owe our success to our exceptional people. We wholeheartedly
            prioritise their health, wellness, and financial security,
            empowering them for a fulfilling future. Here's how we stand with
            our people.
          </p>
        </div>

        <div className="cardss">
          <div className="overlay">
            <img src={girl} alt="" />
          </div>

          <div className="cxardd cxardd1">
            <img src={wass} alt="" />
            <h4>Learning and upskilling</h4>
            <p>
              We owe our success to our exceptional people. We wholeheartedly
              prioritise their health, wellness, and financial security,
              empowering them for a fulfilling future. Here's how we stand with
              our people.
            </p>
            <h1>01</h1>
          </div>

          <div className="cxardd cxardd2 ">
            <h4>Learning and upskilling</h4>
            <p>
              We owe our success to our exceptional people. We wholeheartedly
              prioritise their health, wellness, and financial security,
              empowering them for a fulfilling future. Here's how we stand with
              our people.
            </p>
            <h1>02</h1>
          </div>

          <div className="cxardd cxardd3">
            <img src={kids} alt="" />
            <h4>Learning and upskilling</h4>
            <p>
              We owe our success to our exceptional people. We wholeheartedly
              prioritise their health, wellness, and financial security,
              empowering them for a fulfilling future. Here's how we stand with
              our people.
            </p>
            <h1>03</h1>
          </div>

          <div className="cxardd cxardd4">
            <img src={kids} alt="" />
            <h4>Learning and upskilling</h4>
            <p>
              We owe our success to our exceptional people. We wholeheartedly
              prioritise their health, wellness, and financial security,
              empowering them for a fulfilling future. Here's how we stand with
              our people.
            </p>
            <h1>04</h1>
          </div>

          <div className="cxardd cxardd5">
            <h4>Learning and upskilling</h4>
            <p>
              We owe our success to our exceptional people. We wholeheartedly
              prioritise their health, wellness, and financial security,
              empowering them for a fulfilling future. Here's how we stand with
              our people.
            </p>
            <h1>05</h1>
          </div>

          <div className="cxardd cxardd6">
            <img src={animGirl} alt="" />
            <h4>Learning and upskilling</h4>
            <p>
              We owe our success to our exceptional people. We wholeheartedly
              prioritise their health, wellness, and financial security,
              empowering them for a fulfilling future. Here's how we stand with
              our people.
            </p>
            <h1>06</h1>
          </div>
        </div>
      </div>

      <div className="started">
        <div className="timeline">
          <Marquee speed={20}>
            <img src={hommie} alt="" />
            <img src={hommie} alt="" />
          </Marquee>

          <div className="title">
            <h4>Where We started</h4>

            <h1>WASS Journey at a Glance</h1>
          </div>

          <div className="line">
            <div className="timeline_line">
              <div className="timeline_round">
                <div className="date">2024</div>
                <div className="mini_round"></div>
                <ul>
                  <li>Started office In Kolkata</li>
                  <li>Stated with 2 Member </li>
                  <li>Started with Mostly</li>
                </ul>
              </div>

              <div className="timeline_round timeline_round2">
                <div className="date">2024</div>
                <div className="mini_round"></div>
                <ul>
                  <li>Started office In Kolkata</li>
                  <li>Stated with 2 Member </li>
                  <li>Started with Mostly</li>
                  <li>Started office In Kolkata</li>
                  <li>Stated with 2 Member </li>
                  <li>Started with Mostly</li>
                </ul>
              </div>

              <div className="timeline_round">
                <div className="date">2024</div>
                <div className="mini_round"></div>
                <ul>
                  <li>Started office In Kolkata</li>
                  <li>Stated with 2 Member </li>
                  <li>Started with Mostly</li>
                </ul>
              </div>

              <div className="timeline_round">
                <div className="date">2024</div>
                <div className="mini_round"></div>
                <ul>
                  <li>Started office In Kolkata</li>
                  <li>Stated with 2 Member </li>
                  <li>Started with Mostly</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="collaboration">
        <div className="title">
          <h4>Where We started</h4>

          <h1>WASS Journey at a Glance</h1>
        </div>

        <div className="colaboration_video">
          <div className="video_box">
            {/* <video
              src="https://cdn.cuberto.com/cb/projects/riyadh/cover.mp4"
              loop
              autoPlay
              muted
            ></video> */}

            <img
              src="https://www.way2studyabroad.com/assets/value02-Uiw5afDP.jpg"
              alt="img"
            />

            <div className="video_description">
              <div className="controls">
                <div className="control_button">
                  <h5>View Profile</h5>
                  <div className="outer_button_circle"></div>
                </div>
              </div>

              <div className="detailss">
                <div className="titleee">
                  <div className="namei">
                    <h4>Prince Kumar</h4>
                    <h5>Sr. Frontend Engineer</h5>
                  </div>

                  <div className="duration">2 Year @ WASS</div>
                </div>

                <div className="des">
                  <div className="line"></div>
                  We start with a holistic understanding of the problem
                  statement before designing solutions for customers. We then
                  create the most optimal solution possible based on first
                  principles.
                </div>
              </div>
            </div>
          </div>

          <div className="video_box">
            {/* <video
              src="https://cdn.cuberto.com/cb/projects/ferrumpipe/cover.mp4"
              loop
              autoPlay
              muted
            ></video> */}

            <img
              src="https://www.way2studyabroad.com/assets/value03-GHoSTutu.jpg"
              alt="img"
            />

            <div className="video_description">
              <div className="controls">
                <div className="control_button">
                  <h5>View Profile</h5>
                  <div className="outer_button_circle"></div>
                </div>
              </div>

              <div className="detailss">
                <div className="titleee">
                  <div className="namei">
                    <h4>Prince Kumar</h4>
                    <h5>Sr. Frontend Engineer</h5>
                  </div>

                  <div className="duration">2 Year @ WASS</div>
                </div>

                <div className="des">
                  <div className="line"></div>
                  We start with a holistic understanding of the problem
                  statement before designing solutions for customers. We then
                  create the most optimal solution possible based on first
                  principles.
                </div>
              </div>
            </div>
          </div>

          <div className="video_box">
            <video
              src="https://cdn.cuberto.com/cb/projects/zelt/cover.mp4"
              loop
              autoPlay
              muted
            ></video>

            <div className="video_description">
              <div className="controls">
                <div className="control_button">
                  <h5>View Profile</h5>
                  <div className="outer_button_circle"></div>
                </div>
              </div>

              <div className="detailss">
                <div className="titleee">
                  <div className="namei">
                    <h4>Prince Kumar</h4>
                    <h5>Sr. Frontend Engineer</h5>
                  </div>

                  <div className="duration">2 Year @ WASS</div>
                </div>

                <div className="des">
                  <div className="line"></div>
                  We start with a holistic understanding of the problem
                  statement before designing solutions for customers. We then
                  create the most optimal solution possible based on first
                  principles.
                </div>
              </div>
            </div>
          </div>

          <div className="video_box">
            <video
              src="https://cdn.cuberto.com/cb/projects/qvino/cover.mp4"
              loop
              autoPlay
              muted
            ></video>

            <div className="video_description">
              <div className="controls">
                <div className="control_button">
                  <h5>View Profile</h5>
                  <div className="outer_button_circle"></div>
                </div>
              </div>

              <div className="detailss">
                <div className="titleee">
                  <div className="namei">
                    <h4>Prince Kumar</h4>
                    <h5>Sr. Frontend Engineer</h5>
                  </div>

                  <div className="duration">2 Year @ WASS</div>
                </div>

                <div className="des">
                  <div className="line"></div>
                  We start with a holistic understanding of the problem
                  statement before designing solutions for customers. We then
                  create the most optimal solution possible based on first
                  principles.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="meet_ceo">
        <div className="ceo_image">
          <img src={ceo} alt="" />

          <div className="ceo_line">
            <div className="ball"></div>
            <div className="ball"></div>
          </div>
        </div>

        <div className="ceo_details">
          <h4>Meet Our CEO</h4>

          <h1>Prince Kumar</h1>

          <h5>The founder of WASS GROUP</h5>

          <p className="des1">
            You will collaborate with a dynamic and ambitious team comprising
            tech enthusiasts, finance experts,
          </p>

          <p className="des2">
            Acquire knowledge in cutting-edge tech, innovative solutions,
            industry insights, financial expertise, collaborative teamwork,
            problem-solving, customer centric approaches, and the art of driving
            financial inclusion through technology-driven solutions.Acquire
            knowledge in cutting-edge tech, innovative solutions, industry
            insights, financial expertise, collaborative teamwork,
          </p>
        </div>
      </div>

      <div className="what_learn">
        <div className="title">
          <h4>Where We started</h4>
          <h1>WASS Journey at a Glance</h1>
        </div>

        <div className="conta9iner">
          <div className="box">
            <div className="text">
              <h1>What you will learn</h1>

              <p>
                You will collaborate with a dynamic and ambitious team
                comprising tech enthusiasts, finance experts,
                <br />
                Acquire knowledge in cutting-edge tech, innovative solutions,
                industry insights, financial expertise, collaborative teamwork,
                problem-solving, customer centric approaches, and the art of
                driving financial inclusion through technology-driven solutions.
              </p>
            </div>

            <div className="img">
              <img src={learn} alt="" />
            </div>
          </div>

          <div className="box">
            <div className="img">
              <img src={learn} alt="" />
            </div>

            <div className="text">
              <h1>What you will learn</h1>

              <p>
                You will collaborate with a dynamic and ambitious team
                comprising tech enthusiasts, finance experts,
                <br />
                Acquire knowledge in cutting-edge tech, innovative solutions,
                industry insights, financial expertise, collaborative teamwork,
                problem-solving, customer centric approaches, and the art of
                driving financial inclusion through technology-driven solutions.
              </p>
            </div>
          </div>

          <div className="box">
            <div className="text">
              <h1>What you will learn</h1>

              <p>
                You will collaborate with a dynamic and ambitious team
                comprising tech enthusiasts, finance experts,
                <br />
                Acquire knowledge in cutting-edge tech, innovative solutions,
                industry insights, financial expertise, collaborative teamwork,
                problem-solving, customer centric approaches, and the art of
                driving financial inclusion through technology-driven solutions.
              </p>
            </div>

            <div className="img">
              <img src={learn} alt="" />
            </div>
          </div>
        </div>

        <div className="learn_cardyy">
          <div className="cardy_1_container">
            <div className="item">
              <img src={wass1} alt="" />
            </div>
            <div className="item">
              <img src={wass2} alt="" />
            </div>
            <div className="item">
              <img src={wass3} alt="" />
            </div>
          </div>

          <div className="cardy_2_container">
            <div className="item">
              <img src={learn} alt="" />
            </div>
            <div className="item">
              <img src={learn} alt="" />
            </div>
            <div className="item">
              <img src={learn} alt="" />
            </div>
            <div className="item">
              <img src={learn} alt="" />
            </div>
          </div>

          <div className="btnnn">
            <Button bgColor="#800080" color="#fff" title="Schedule Call" />
          </div>
        </div>
      </div>

      <div className="view_openings">
        <div className="btnnnn">
          <h3>All Current Openings</h3>

          <button>
            View Openings <span>&#x2192;</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CarrerPage;
