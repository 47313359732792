import React from "react";
import logo from "../../assets/img/logo/logo.svg";
import facebook from "../../assets/img/Social/facebook.png";
import instagram from "../../assets/img/Social/instagram.png";
import linkedin from "../../assets/img/Social/linkedin.png";
import twiteer from "../../assets/img/Social/twiteer.png";
import youtube from "../../assets/img/Social/youtube.png";
import gmail from "../../assets/img/Social/Gmail.png";
import skype from "../../assets/img/Social/skype.png";
import whatsapp from "../../assets/img/Social/whatsapp.png";
import rating from "../../assets/img/ServicePage3/rating.png";
import pinterest from "../../assets/img/Social/pinterest.png";
import Button from "../../components/Button/Button";
import "./EnqueryDetails.css";
import { Link } from "react-router-dom";

const EnqueryDetails = () => {
  return (
    <div id="enqueryDetails">
      <div className="enquery_det-socal">
        <div className="logos">
          <img src={logo} alt="" />
        </div>

        <div className="socal_links">
          <a href="#">
            <img src={facebook} alt="" />
          </a>

          <a href="#">
            <img src={instagram} alt="" />
          </a>

          <a href="#">
            <img src={pinterest} alt="" />
          </a>

          <a href="#">
            <img src={twiteer} alt="" />
          </a>
          <a href="#">
            <img src={instagram} alt="" />
          </a>

          <a href="#">
            <img src={youtube} alt="" />
          </a>
        </div>

        <div className="quick">
          <div className="email">
            <img src={gmail} alt="" />
            <div className="">
              <h4>Mail to our Sales Department</h4>
              <small>info@webappssoft.com</small>
            </div>
          </div>

          <div className="email">
            <img src={skype} alt="" />
            <div className="">
              <h4>Our Skype ID</h4>
              <small>SKYPPEaddress</small>
            </div>
          </div>

          <div className="email">
            <img src={whatsapp} alt="" />
            <div className="">
              <h4>Whats App Number</h4>
              <small>+91 9763500291</small>
            </div>
          </div>
        </div>

        <div className="ratinggg">
          <img src={rating} alt="" />
          <p>By 700+ clients for 1500+ Web and Mobile App projects.</p>
        </div>
      </div>

      <div className="enquery_det-form">
        <h2> Enquiry Details</h2>

        <form action="">
          <input type="text" placeholder="Your Name" />
          <input type="text" placeholder="Your Company" />
          <input type="text" placeholder="Your city/location" />
          <input type="text" placeholder="Your business email" />
          <input type="number" placeholder="Your mobile number" />
          <input type="text" placeholder="Skyp ID" />
          <input type="number" placeholder="Your Budget" />
          <input type="text" placeholder="Your Product Service Name" />
          <div className="file">
            <input type="file" id="file" placeholder="" />
            <label htmlFor="file">file Attachment</label>
          </div>
<div className=""></div>
          <div className="btnnn">
          <Link to="/enquery">
            <Button bgColor="#800080" color="#fff" title='SUBMIT NOW' />
          </Link>
        </div>
        </form>
      </div>
    </div>
  );
};

export default EnqueryDetails;
