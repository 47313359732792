import "./Connect.css";
import call from "../../assets/img/call.png";
const Connect = () => {
  return (
    <>
      <div className="request_call">
        <div className="call">
          <img src={call} />
          <div className="">
            <small>Call For More info</small>
            <h3>
              <a href="tel:+91 9763500291"></a> +91 97635 00291
            </h3>
          </div>
        </div>

        <div className="line"></div>

        <h2 className="title">Let's Connect with Consulting Practice</h2>
        <section>
          <a target="_blank" href="https://wa.link/9sbw44" className="pulse">
            <h4>Let's Connect</h4>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </a>
        </section>
      </div>
    </>
  );
};

export default Connect;
