import React, { useEffect, useState } from "react";

const Modal = () => {
  const [modal, setModal] = useState(false);
  const [display, setDisplay] = useState("flex");
  useEffect(() => {
    const intervalId = setInterval(() => {
      setModal(true);
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      {modal && (
        <div className="popUp" style={{ display: display }}>
          <div className="box">
            <i
              onClick={() => {
                setModal(false);
                setDisplay("none");
              }}
              className="bx bx-x cross"
            ></i>

            <div className="left">
              <h2>
                Let's discuss on something <span>cool</span> together 🤝
              </h2>

              <div className="box-em">
                <div className="email">
                  <a href="mailto:info@webappssoft.com" target="_blank">
                    <i className="bx bx-envelope colo"></i>
                    info@webappssoft.com
                  </a>
                </div>

                <div className="email">
                  <a href="tel:+919763500291">
                    <i className="bx bx-phone colo"></i>
                    +91 97635 00291
                  </a>
                </div>

                <div className="email">
                  <a
                    target="_blank"
                    href="https://maps.app.goo.gl/dt1XZtyTGwmAQ7kq7"
                  >
                    <i className="bx bx-location-plus colo"></i> Kolkata, West
                    Bengal
                  </a>
                </div>
              </div>

              {/* <div className="social">
                <a target='_blank' href="https://www.facebook.com/webappssoftwaresolutions">
                  <i className='bx bxl-facebook' ></i>
                </a>

                <a target='_blank' href="https://www.instagram.com/webappssoftware">

                  <i className='bx bxl-instagram' ></i>
                </a>

                <a target='_blank' href="https://twitter.com/webappsoftwares">

                  <i className='bx bxl-twitter'></i>
                </a>
              </div> */}
            </div>

            <div className="right">
              <div className="vox">
                {/* <h4>I'm instersted in...</h4> */}

                <div className="select">
                  {/* <ul>
                    <li onClick={() => setSelect1(false)} className={`${select1 == true && className}`}>UI / UX design</li>
                    <li onClick={() => selectedItem()} className={`${select2 == true && className}`}>Web design</li>
                    <li onClick={() => selectedItem()} className={`${select3 == true && className}`}>Graphic design</li>
                    <li onClick={() => selectedItem()} className={`${select4 == true && className}`}>Other</li>
                  </ul> */}
                </div>

                <form action="">
                  <input type="text" placeholder="Your name" />
                  <input type="email" placeholder="Your email" />
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="2"
                    placeholder="Your message"
                  ></textarea>

                  <button type="submit">
                    <i className="bx bx-send"></i> Send message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
