import React from "react";
import handHover from "../../assets/img/TechStack/newHover.png";
import "./CodeStackPage.css";
import { Helmet } from "react-helmet-async";

//  CODE STACK IMAGES
import react from "../../assets/img/Codestack/react-svgrepo-com.svg";
import node from "../../assets/img/Codestack/node-js-svgrepo-com.svg";
import next from "../../assets/img/Codestack/nextjs-svgrepo-com.svg";
import nest from "../../assets/img/Codestack/nest-service-js-svgrepo-com.svg";
import angular from "../../assets/img/Codestack/angular-svgrepo-com.svg";
import vue from "../../assets/img/Codestack/vue-svgrepo-com.svg";
import python from "../../assets/img/Codestack/python-svgrepo-com.svg";
import laraval from "../../assets/img/Codestack/laravel-svgrepo-com.svg";
import android from "../../assets/img/Codestack/android-color-svgrepo-com.svg";
import flutter from "../../assets/img/Codestack/flutter-svgrepo-com.svg";
import reactNative from "../../assets/img/Codestack/reactts-svgrepo-com.svg";
import Kotlin from "../../assets/img/Codestack/kotlin-svgrepo-com.svg";
import wpa from "../../assets/img/Codestack/laravel-svgrepo-com.svg";
import mySql from "../../assets/img/Codestack/mysql-svgrepo-com.svg";
import mongo from "../../assets/img/Codestack/mongo-svgrepo-com.svg";
import postsql from "../../assets/img/Codestack/postgresql-svgrepo-com.svg";

const CodeStackPage = () => {
  return (
    <div id="codeStackPage">
      <Helmet>
        <title>Code Stack Page | Webapps Software Solutions </title>
        <meta name="copyright" content="https://webappssoft.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webappssoft.com/" />
        <link rel="next" href="https://webappssoft.com/" />
        <meta
          property="og:title"
          content="Property Dealers in Jamshedpur | Property Dealers Near Me"
        />
        <meta property="og:url" content="https://webappssoft.com/" />
        <meta
          property="og:image"
          content="https://webappssoft.com/logo/sy-logo.jpg"
        />
        <meta
          property="og:description"
          content="Property Dealers in Jamshedpur: Find 150+ Reliable real estate agents and Brokers in Jamshedpur. Contact residential and Commercial property consultants online."
        />
        <meta
          name="description"
          content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata"
        />
        <meta
          name="keywords"
          content="website design company India,web designing India, best web design company,top web design company,top web design companies India, web design and development company in india"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata"
        />
        <meta property="og:site_name" content="Webapps Software Solutions" />
        <meta
          name="twitter:description"
          content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata"
        />
        <meta
          name="twitter:title"
          content="Website Design & Development Company In Kolkata, India"
        />
        <meta
          property="og:image"
          content="https://webappssoft.com/assets/images/logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://webappssoft.com/assets/images/logo.png"
        />
        <meta name="twitter:site" content="webapps software Solutions" />
        <meta name="twitter:creator" content="@webappsoftwares" />
        <meta name="theme-color" content="#0098DA" />
        <meta
          name="author"
          content="WASS Integrated Tech Enable It Solutions"
        />
        <meta
          name="google-site-verification"
          content="3qKB507bM-zleASA7ZJYxnvl9L30Wk2o4KA7dZksurY"
        />
      </Helmet>

      <div className="c0de_stack_boxes">
        <div className="c0de_stack_boxes_item c0de_stack_boxes_item1 ">
          <div className="flx-code">
            <img className="codeStackImage c0de-none" src={react} alt="img" />
            <div className="c0de-none">
              <h4>React</h4>
              <small>Web Application</small>
            </div>
          </div>

          <div className="handHover_image c0de-none">
            <img src={handHover} alt="" />
          </div>

          <div className="background_effect">
            <div className="flx-code">
              <img className="codeStackImage" src={react} alt="img" />
              <div className="">
                <h4>React</h4>
                <small>Web Application</small>
              </div>
            </div>

            <div className="handHover_image">
              <img src={handHover} alt="" />
            </div>
          </div>
        </div>

        <div className="c0de_stack_boxes_item">
          <small>
            React.js builds dynamic UIs with reusable components, using JSX and
            state management for interactive web apps.
          </small>
          <i className="bx bx-arrow-back tech_stack_arrow"></i>
        </div>
      </div>

      <div className="c0de_stack_boxes">
        <div className="c0de_stack_boxes_item c0de_stack_boxes_item2 ">
          <div className="flx-code">
            <img className="codeStackImage c0de-none" src={node} alt="img" />
            <div className="c0de-none">
              <h4>Node Js</h4>
              <small>Web Application</small>
            </div>
          </div>

          <div className="handHover_image c0de-none">
            <img src={handHover} alt="" />
          </div>

          <div className="background_effect">
            <div className="flx-code">
              <img className="codeStackImage" src={node} alt="img" />
              <div className="">
                <h4>Node Js</h4>
                <small>Web Application</small>
              </div>
            </div>

            <div className="handHover_image">
              <img src={handHover} alt="" />
            </div>
          </div>
        </div>

        <div className="c0de_stack_boxes_item">
          <small>
            Node.js unlocks JavaScript for servers, enabling real-time web apps.
            Its event-driven design efficiently handles many connections, making
            it ideal for scalable apps.
          </small>
          <i className="bx bx-arrow-back tech_stack_arrow"></i>
        </div>
      </div>

      <div className="c0de_stack_boxes">
        <div className="c0de_stack_boxes_item c0de_stack_boxes_item3 ">
          <div className="flx-code">
            <img className="codeStackImage c0de-none" src={next} alt="img" />
            <div className="c0de-none">
              <h4>Next Js</h4>
              <small>Web Application</small>
            </div>
          </div>

          <div className="handHover_image c0de-none">
            <img src={handHover} alt="" />
          </div>

          <div className="background_effect">
            <div className="flx-code">
              <img className="codeStackImage" src={next} alt="img" />
              <div className="">
                <h4>Next Js</h4>
                <small>Web Application</small>
              </div>
            </div>

            <div className="handHover_image">
              <img src={handHover} alt="" />
            </div>
          </div>
        </div>

        <div className="c0de_stack_boxes_item">
          <small>
            Next.js supercharges React with SEO-friendly rendering options
            (static, server-side) and simplifies building performant web apps.
          </small>
          <i className="bx bx-arrow-back tech_stack_arrow"></i>
        </div>
      </div>

      <div className="c0de_stack_boxes">
        <div className="c0de_stack_boxes_item c0de_stack_boxes_item4">
          <div className="flx-code">
            <img className="codeStackImage c0de-none" src={vue} alt="img" />
            <div className="c0de-none">
              <h4>Vue Js</h4>
              <small> Web Application</small>
            </div>
          </div>

          <div className="handHover_image c0de-none">
            <img src={handHover} alt="" />
          </div>

          <div className="background_effect">
            <div className="flx-code">
              <img className="codeStackImage" src={vue} alt="img" />
              <div className="">
                <h4>Vue Js</h4>
                <small>Web Application</small>
              </div>
            </div>

            <div className="handHover_image">
              <img src={handHover} alt="" />
            </div>
          </div>
        </div>

        <div className="c0de_stack_boxes_item">
          <small>
            Vue.js offers a sweet spot for web UIs. It's simple to learn
            (templates!), yet powerful with features like reactivity and
            components, making development efficient and flexible.
          </small>
          <i className="bx bx-arrow-back tech_stack_arrow"></i>
        </div>
      </div>

      <div className="c0de_stack_boxes">
        <div className="c0de_stack_boxes_item c0de_stack_boxes_item5">
          <div className="flx-code">
            <img className="codeStackImage c0de-none" src={angular} alt="img" />
            <div className="c0de-none">
              <h4>Angular Js</h4>
              <small>Web Development</small>
            </div>
          </div>

          <div className="handHover_image c0de-none">
            <img src={handHover} alt="" />
          </div>

          <div className="background_effect">
            <div className="flx-code">
              <img className="codeStackImage" src={angular} alt="img" />
              <div className="">
                <h4>Angular Js</h4>
                <small>Web Development</small>
              </div>
            </div>

            <div className="handHover_image">
              <img src={handHover} alt="" />
            </div>
          </div>
        </div>

        <div className="c0de_stack_boxes_item">
          <small>
            Angular, a framework developed by Google, facilitates dynamic web
            development through features like two-way data binding and
            dependency injection.
          </small>
          <i className="bx bx-arrow-back tech_stack_arrow"></i>
        </div>
      </div>

      <div className="c0de_stack_boxes">
        <div className="c0de_stack_boxes_item c0de_stack_boxes_item6">
          <div className="flx-code">
            <img className="codeStackImage c0de-none" src={python} alt="img" />
            <div className="c0de-none">
              <h4>Python</h4>
              <small>Web Application</small>
            </div>
          </div>

          <div className="handHover_image c0de-none">
            <img src={handHover} alt="" />
          </div>

          <div className="background_effect">
            <div className="flx-code">
              <img className="codeStackImage" src={python} alt="img" />
              <div className="">
                <h4>Python</h4>
                <small>Full Stack Application</small>
              </div>
            </div>

            <div className="handHover_image">
              <img src={handHover} alt="" />
            </div>
          </div>
        </div>

        <div className="c0de_stack_boxes_item">
          <small>
            Python is a versatile, high-level programming language known for its
            readability and simplicity. It's widely used for web development,
            data analysis, artificial intelligence, and automation tasks.
          </small>
          <i className="bx bx-arrow-back tech_stack_arrow"></i>
        </div>
      </div>

      {/* [----------------------] */}

      <div className="c0de_stack_boxes">
        <div className="c0de_stack_boxes_item c0de_stack_boxes_item1 ">
          <div className="flx-code">
            <img className="codeStackImage c0de-none" src={nest} alt="img" />
            <div className="c0de-none">
              <h4>Nest JS</h4>
              <small>Web Application</small>
            </div>
          </div>

          <div className="handHover_image c0de-none">
            <img src={handHover} alt="" />
          </div>

          <div className="background_effect">
            <div className="flx-code">
              <img className="codeStackImage" src={nest} alt="img" />
              <div className="">
                <h4>Nest JS</h4>
                <small>Web Application</small>
              </div>
            </div>

            <div className="handHover_image">
              <img src={handHover} alt="" />
            </div>
          </div>
        </div>

        <div className="c0de_stack_boxes_item">
          <small>
            NestJS is a progressive Node.js framework for building efficient,
            reliable, and scalable server-side applications, leveraging
            TypeScript and robust architecture patterns.
          </small>
          <i className="bx bx-arrow-back tech_stack_arrow"></i>
        </div>
      </div>

      <div className="c0de_stack_boxes">
        <div className="c0de_stack_boxes_item c0de_stack_boxes_item2 ">
          <div className="flx-code">
            <img className="codeStackImage c0de-none" src={laraval} alt="img" />
            <div className="c0de-none">
              <h4>Laravel</h4>
              <small>Web Application</small>
            </div>
          </div>

          <div className="handHover_image c0de-none">
            <img src={handHover} alt="" />
          </div>

          <div className="background_effect">
            <div className="flx-code">
              <img className="codeStackImage" src={laraval} alt="img" />
              <div className="">
                <h4>Laravel</h4>
                <small>Web Application</small>
              </div>
            </div>

            <div className="handHover_image">
              <img src={handHover} alt="" />
            </div>
          </div>
        </div>

        <div className="c0de_stack_boxes_item">
          <small>
            Laravel is a powerful PHP framework known for its elegant syntax and
            comprehensive features, making web development efficient and
            enjoyable.
          </small>
          <i className="bx bx-arrow-back tech_stack_arrow"></i>
        </div>
      </div>

      <div className="c0de_stack_boxes">
        <div className="c0de_stack_boxes_item c0de_stack_boxes_item3 ">
          <div className="flx-code">
            <img className="codeStackImage c0de-none" src={android} alt="img" />
            <div className="c0de-none">
              <h4>Android</h4>
              <small>Mobile Application</small>
            </div>
          </div>

          <div className="handHover_image c0de-none">
            <img src={handHover} alt="" />
          </div>

          <div className="background_effect">
            <div className="flx-code">
              <img className="codeStackImage" src={android} alt="img" />
              <div className="">
                <h4>Android</h4>
                <small>Mobile Application</small>
              </div>
            </div>

            <div className="handHover_image">
              <img src={handHover} alt="" />
            </div>
          </div>
        </div>

        <div className="c0de_stack_boxes_item">
          <small>
            Android is a mobile operating system developed by Google, based on
            the Linux kernel. It powers a wide range of smartphones and tablets.
          </small>
          <i className="bx bx-arrow-back tech_stack_arrow"></i>
        </div>
      </div>

      <div className="c0de_stack_boxes">
        <div className="c0de_stack_boxes_item c0de_stack_boxes_item4">
          <div className="flx-code">
            <img className="codeStackImage c0de-none" src={flutter} alt="img" />
            <div className="c0de-none">
              <h4>Flutter</h4>
              <small>Mobile Application</small>
            </div>
          </div>

          <div className="handHover_image c0de-none">
            <img src={handHover} alt="" />
          </div>

          <div className="background_effect">
            <div className="flx-code">
              <img className="codeStackImage" src={flutter} alt="img" />
              <div className="">
                <h4>Flutter</h4>
                <small>Mobile Application</small>
              </div>
            </div>

            <div className="handHover_image">
              <img src={handHover} alt="" />
            </div>
          </div>
        </div>

        <div className="c0de_stack_boxes_item">
          <small>
            Flutter is Google's UI toolkit for building natively compiled
            applications for mobile, web, and desktop from a single codebase,
            using Dart programming language.
          </small>
          <i className="bx bx-arrow-back tech_stack_arrow"></i>
        </div>
      </div>

      <div className="c0de_stack_boxes">
        <div className="c0de_stack_boxes_item c0de_stack_boxes_item5">
          <div className="flx-code">
            <img
              className="codeStackImage c0de-none"
              src={reactNative}
              alt="img"
            />
            <div className="c0de-none">
              <h4>React Native</h4>
              <small>Mobile Application</small>
            </div>
          </div>

          <div className="handHover_image c0de-none">
            <img src={handHover} alt="" />
          </div>

          <div className="background_effect">
            <div className="flx-code">
              <img className="codeStackImage" src={reactNative} alt="img" />
              <div className="">
                <h4>React Native</h4>
                <small>Mobile Application</small>
              </div>
            </div>

            <div className="handHover_image">
              <img src={handHover} alt="" />
            </div>
          </div>
        </div>

        <div className="c0de_stack_boxes_item">
          <small>
            React Native, a framework by Facebook, enables cross-platform mobile
            app development with JavaScript and React, offering native-like
            performance and efficiency.
          </small>
          <i className="bx bx-arrow-back tech_stack_arrow"></i>
        </div>
      </div>

      <div className="c0de_stack_boxes">
        <div className="c0de_stack_boxes_item c0de_stack_boxes_item6">
          <div className="flx-code">
            <img className="codeStackImage c0de-none" src={Kotlin} alt="img" />
            <div className="c0de-none">
              <h4>Kotlin</h4>
              <small>Mobile Application</small>
            </div>
          </div>

          <div className="handHover_image c0de-none">
            <img src={handHover} alt="" />
          </div>

          <div className="background_effect">
            <div className="flx-code">
              <img className="codeStackImage" src={Kotlin} alt="img" />
              <div className="">
                <h4>Kotlin</h4>
                <small>Mobile Application</small>
              </div>
            </div>

            <div className="handHover_image">
              <img src={handHover} alt="" />
            </div>
          </div>
        </div>

        <div className="c0de_stack_boxes_item">
          <small>
            Kotlin is a modern, statically typed programming language developed
            by JetBrains, widely used for Android app development alongside
            Java.
          </small>
          <i className="bx bx-arrow-back tech_stack_arrow"></i>
        </div>
      </div>

      {/* [----------------------] */}

      <div className="c0de_stack_boxes">
        <div className="c0de_stack_boxes_item c0de_stack_boxes_item1 ">
          <div className="flx-code">
            <img className="codeStackImage c0de-none" src={wpa} alt="img" />
            <div className="c0de-none">
              <h4>WPA</h4>
              <small>Security Protocol</small>
            </div>
          </div>

          <div className="handHover_image c0de-none">
            <img src={handHover} alt="" />
          </div>

          <div className="background_effect">
            <div className="flx-code">
              <img className="codeStackImage" src={wpa} alt="img" />
              <div className="">
                <h4>WPA</h4>
                <small>Security Protocol</small>
              </div>
            </div>

            <div className="handHover_image">
              <img src={handHover} alt="" />
            </div>
          </div>
        </div>

        <div className="c0de_stack_boxes_item">
          <small>
            WPA stands for Wi-Fi Protected Access, a security protocol used to
            secure wireless networks and prevent unauthorized access to data
            transmissions.
          </small>
          <i className="bx bx-arrow-back tech_stack_arrow"></i>
        </div>
      </div>

      <div className="c0de_stack_boxes">
        <div className="c0de_stack_boxes_item c0de_stack_boxes_item2 ">
          <div className="flx-code">
            <img className="codeStackImage c0de-none" src={mySql} alt="img" />
            <div className="c0de-none">
              <h4>MySql</h4>
              <small>RDBMS</small>
            </div>
          </div>

          <div className="handHover_image c0de-none">
            <img src={handHover} alt="" />
          </div>

          <div className="background_effect">
            <div className="flx-code">
              <img className="codeStackImage" src={mySql} alt="img" />
              <div className="">
                <h4>MySql</h4>
                <small>RDBMS</small>
              </div>
            </div>

            <div className="handHover_image">
              <img src={handHover} alt="" />
            </div>
          </div>
        </div>

        <div className="c0de_stack_boxes_item">
          <small>
            MySQL is an open-source relational RDBMS (RDBMS) that uses SQL
            (Structured Query Language) for managing and manipulating data
            efficiently.
          </small>
          <i className="bx bx-arrow-back tech_stack_arrow"></i>
        </div>
      </div>

      <div className="c0de_stack_boxes">
        <div className="c0de_stack_boxes_item c0de_stack_boxes_item3 ">
          <div className="flx-code">
            <img className="codeStackImage c0de-none" src={mongo} alt="img" />
            <div className="c0de-none">
              <h4>MongoDB</h4>
              <small>RDBMS</small>
            </div>
          </div>

          <div className="handHover_image c0de-none">
            <img src={handHover} alt="" />
          </div>

          <div className="background_effect">
            <div className="flx-code">
              <img className="codeStackImage" src={mongo} alt="img" />
              <div className="">
                <h4>MongoDB </h4>
                <small>RDBMS</small>
              </div>
            </div>

            <div className="handHover_image">
              <img src={handHover} alt="" />
            </div>
          </div>
        </div>

        <div className="c0de_stack_boxes_item">
          <small>
            MongoDB is a NoSQL RDBMS that stores data in flexible, JSON-like
            documents, making it highly scalable and suitable for modern
            applications.
          </small>
          <i className="bx bx-arrow-back tech_stack_arrow"></i>
        </div>
      </div>

      <div className="c0de_stack_boxes">
        <div className="c0de_stack_boxes_item c0de_stack_boxes_item4">
          <div className="flx-code">
            <img className="codeStackImage c0de-none" src={postsql} alt="img" />
            <div className="c0de-none">
              <h4>PostgreSQL</h4>
              <small>RDBMS</small>
            </div>
          </div>

          <div className="handHover_image c0de-none">
            <img src={handHover} alt="" />
          </div>

          <div className="background_effect">
            <div className="flx-code">
              <img className="codeStackImage" src={postsql} alt="img" />
              <div className="">
                <h4>PostgreSQL</h4>
                <small>RDBMS</small>
              </div>
            </div>

            <div className="handHover_image">
              <img src={handHover} alt="" />
            </div>
          </div>
        </div>

        <div className="c0de_stack_boxes_item">
          <small>
            PostgreSQL, often referred to as Postgres, is a powerful open-source
            relational RDBMS known for its advanced features, extensibility, and
            reliability.
          </small>
          <i className="bx bx-arrow-back tech_stack_arrow"></i>
        </div>
      </div>
    </div>
  );
};

export default CodeStackPage;
