import React from "react";
import "./PolicyPage.css";
import { Helmet } from "react-helmet-async";
const PolicyPage = () => {
  return (
    <div id="policy_page">

      <Helmet>
        <title>Policy Page | Webapps Software Solutions </title>
        <meta name="copyright" content="https://webappssoft.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webappssoft.com/" />
        <link rel="next" href="https://webappssoft.com/" />
        <meta property="og:title" content="Property Dealers in Jamshedpur | Property Dealers Near Me" />
        <meta property="og:url" content="https://webappssoft.com/" />
        <meta property="og:image" content="https://webappssoft.com/logo/sy-logo.jpg" />
        <meta property="og:description" content="Property Dealers in Jamshedpur: Find 150+ Reliable real estate agents and Brokers in Jamshedpur. Contact residential and Commercial property consultants online." />
        <meta name="description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="keywords" content="website design company India,web designing India, best web design company,top web design company,top web design companies India, web design and development company in india" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta property="og:site_name" content="Webapps Software Solutions" />
        <meta name="twitter:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="twitter:title" content="Website Design & Development Company In Kolkata, India" />
        <meta property="og:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:site" content="webapps software Solutions" />
        <meta name="twitter:creator" content="@webappsoftwares" />
        <meta name="theme-color" content="#0098DA" />
        <meta name="author" content="WASS Integrated Tech Enable It Solutions" />
        <meta name="google-site-verification" content="3qKB507bM-zleASA7ZJYxnvl9L30Wk2o4KA7dZksurY" />
      </Helmet>


      <div id="policy_page_navbar">
        <div className="none"></div>
        <h1>PRIVACY POLICY</h1>

        <div className="policy_bar_id">
          <div className="policy-bar_request_id">
            <small>Revise  ID : 474747474</small>
            <small>1st April 2024 11:00 AM</small>
          </div>
        </div>
      </div>

      <div id="policy-bar_content">

        <div className="item">
          <h2 className="company_title">Webapps Softwares Solution privacy policy</h2>
          <p className="company_descriptoin">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You. We use Your Personal data to provide and improve the
            Service. By using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>
        </div>

        <div className="item">
          <h1>Interpretation and Definitions</h1>
          <h3>Interpretation</h3>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h3>Definitions</h3>
          <p>For the purposes of this Privacy Policy:</p>
          <p>
            <strong>Account</strong> means a unique account created for You to
            access our Service or parts of our Service.
          </p>
          <strong>Company</strong> (referred to as either "the Company", "We",
          "Us" or "Our" in this Agreement) refers to Webapps Softwares Solution,
          128 Jodhpur Park Kolkata.
          <p>
            <strong>Cookies</strong> are small files that are placed on Your
            computer, mobile device or any other device by a website, containing
            the details of Your browsing history on that website among its many
            uses.
          </p>
          <p>
            <strong>Country</strong> refers to: West Bengal, India
          </p>
          <p>
            <strong>Device</strong> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </p>
          <p>
            <strong>Personal Data</strong> is any information that relates to an
            identified or identifiable individual.
          </p>
          <p>
            <strong>Service</strong> refers to the Website.
          </p>
          <p>
            <strong>Service Provider</strong> means any natural or legal person
            who processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used.
          </p>
          <p>
            <strong>Third-party Social Media Service</strong> refers to any
            website or any social network website through which a User can log in
            or create an account to use the Service.
          </p>
          <p>
            <strong>Usage Data</strong> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </p>
          <p>
            <strong>Website</strong> refers to Webapps Softwares Solution,
            accessible from
          </p>
          <a href="https://webappssoft.com/">https://webappssoft.com/</a>
          <p>
            <strong>You</strong> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which such
            individual is accessing or using the Service, as applicable.
          </p>
        </div>

        <div className="item">
          <h1>Collecting and Using Your Personal Data</h1>
          <h2>Types of Data Collected</h2>
          <h3>Personal Data</h3>
          <ul>
            <li>
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:
            </li>

            <li>Email address</li>

            <li>First name and last name</li>

            <li>Phone number</li>

            <li>Usage Data</li>
          </ul>
          <h3>Usage Data</h3>
          <p>
            Usage Data is collected automatically when using the Service. Usage
            Data may include information such as Your Device's Internet Protocol
            address (e.g. IP address), browser type, browser version, the pages of
            our Service that You visit, the time and date of Your visit, the time
            spent on those pages, unique device identifiers and other diagnostic
            data.
          </p>
          <p>
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not limited
            to, the type of mobile device You use, Your mobile device unique ID,
            the IP address of Your mobile device, Your mobile operating system,
            the type of mobile Internet browser You use, unique device identifiers
            and other diagnostic data.
          </p>
          <p>
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>
          <h3>Tracking Technologies and Cookies</h3>
          <p>
            We use Cookies and similar tracking technologies to track the activity
            on Our Service and store certain information. Tracking technologies
            used are beacons, tags, and scripts to collect and track information
            and to improve and analyze Our Service. The technologies We use may
            include:
          </p>
          <p>
            {" "}
            <strong>Cookies or Browser Cookies.</strong> A cookie is a small file
            placed on Your Device. You can instruct Your browser to refuse all
            Cookies or to indicate when a Cookie is being sent. However, if You do
            not accept Cookies, You may not be able to use some parts of our
            Service. Unless you have adjusted Your browser setting so that it will
            refuse Cookies, our Service may use Cookies.
          </p>
          <p>
            <strong>Flash Cookies.</strong> Certain features of our Service may
            use local stored objects (or Flash Cookies) to collect and store
            information about Your preferences or Your activity on our Service.
            Flash Cookies are not managed by the same browser settings as those
            used for Browser Cookies. For more information on how You can delete
            Flash Cookies, please read "Where can I change the settings for
            disabling, or deleting local shared objects?" available at
          </p>
          <p>
            <strong>Web Beacons.</strong> Certain sections of our Service and our
            emails may contain small electronic files known as web beacons (also
            referred to as clear gifs, pixel tags, and single-pixel gifs) that
            permit the Company, for example, to count users who have visited those
            pages or opened an email and for other related website statistics (for
            example, recording the popularity of a certain section and verifying
            system and server integrity).
          </p>
          <p>
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on Your personal computer or mobile device when You go offline,
            while Session Cookies are deleted as soon as You close Your web
            browser. You can learn more about cookies here:{" "}
            <span className="green"> All About Cookies by TermsFeed</span> .
          </p>
          <p>
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </p>
          <h3>Necessary / Essential Cookies</h3>
          <p>
            <strong>Type:</strong> Session Cookies
          </p>
          <p>
            <strong>Administered by:</strong> Us
          </p>
          <p>
            <strong>Purpose:</strong> These Cookies are essential to provide You
            with services available through the Website and to enable You to use
            some of its features. They help to authenticate users and prevent
            fraudulent use of user accounts. Without these Cookies, the services
            that You have asked for cannot be provided, and We only use these
            Cookies to provide You with those services.
          </p>
          <h3>Cookies Policy / Notice Acceptance Cookies</h3>
          <p>
            <strong>Type:</strong> Persistent Cookies
          </p>
          <p>
            <strong>Administered by:</strong> Us
          </p>
          <p>
            <strong>Purpose:</strong> These Cookies identify if users have
            accepted the use of cookies on the Website.
          </p>
          <h3>Functionality Cookies</h3>
          <p>
            <strong>Type:</strong> Persistent Cookies
          </p>
          <p>
            <strong>Administered by:</strong> Us
          </p>
          <p>
            <strong>Purpose:</strong> These Cookies allow us to remember choices
            You make when You use the Website, such as remembering your login
            details or language preference. The purpose of these Cookies is to
            provide You with a more personal experience and to avoid You having to
            re-enter your preferences every time You use the Website.
          </p>
          <p>
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.
          </p>
          <h2>Use of Your Personal Data</h2>
          <p>The Company may use Personal Data for the following purposes:</p>
          <p>
            <strong>To provide and maintain our Service,</strong> including to
            monitor the usage of our Service.
          </p>
          <p>
            <strong>To manage Your Account:</strong> to manage Your registration
            as a user of the Service. The Personal Data You provide can give You
            access to different functionalities of the Service that are available
            to You as a registered user.
          </p>
          <p>
            <strong>For the performance of a contract:</strong> the development,
            compliance and undertaking of the purchase contract for the products,
            items or services You have purchased or of any other contract with Us
            through the Service.
          </p>
          <p>
            <strong>To contact You:</strong>
            To contact You by email, telephone calls, SMS, or other equivalent
            forms of electronic communication, such as a mobile application's push
            notifications regarding updates or informative communications related
            to the functionalities, products or contracted services, including the
            security updates, when necessary or reasonable for their
            implementation.
          </p>
          <p>
            <strong>To provide You</strong> with news, special offers and general
            information about other goods, services and events which we offer that
            are similar to those that you have already purchased or enquired about
            unless You have opted not to receive such information.
          </p>
          <p>
            <strong>To manage Your requests:</strong>
            To attend and manage Your requests to Us.
          </p>
          <p>
            <strong>For business transfers:</strong> We may use Your information
            to evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or all
            of Our assets, whether as a going concern or as part of bankruptcy,
            liquidation, or similar proceeding, in which Personal Data held by Us
            about our Service users is among the assets transferred.
          </p>
          <p>
            <strong>For other purposes:</strong> We may use Your information for
            other purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Service, products, services, marketing and
            your experience.
          </p>
          <p>
            We may share Your personal information in the following situations:
          </p>
          <p>
            <strong>With Service Providers:</strong>
            We may share Your personal information with Service Providers to
            monitor and analyze the use of our Service, to contact You.
          </p>
          <p>
            <strong>For business transfers:</strong>
            We may share or transfer Your personal information in connection with,
            or during negotiations of, any merger, sale of Company assets,
            financing, or acquisition of all or a portion of Our business to
            another company.
          </p>
          <p>
            <strong>With Affiliates:</strong> We may share Your information with
            Our affiliates, in which case we will require those affiliates to
            honor this Privacy Policy. Affiliates include Our parent company and
            any other subsidiaries, joint venture partners or other companies that
            We control or that are under common control with Us.
          </p>
          <p>
            <strong>With business partners:</strong> We may share Your information
            with Our business partners to offer You certain products, services or
            promotions.
          </p>
          <p>
            <strong>With other users:</strong> when You share personal information
            or otherwise interact in the public areas with other users, such
            information may be viewed by all users and may be publicly distributed
            outside. If You interact with other users or register through a
            Third-Party Social Media Service, Your contacts on the Third-Party
            Social Media Service may see Your name, profile, pictures and
            description of Your activity. Similarly, other users will be able to
            view descriptions of Your activity, communicate with You and view Your
            profile.
          </p>
          <p>
            <strong>With Your consent:</strong> We may disclose Your personal
            information for any other purpose with Your consent.
          </p>
          <h2>Retention of Your Personal Data</h2>
          <p>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to retain
            your data to comply with applicable laws), resolve disputes, and
            enforce our legal agreements and policies.
          </p>
          <p>
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally obligated
            to retain this data for longer time periods.
          </p>
          <h2>Transfer of Your Personal Data</h2>
          <p>
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the parties
            involved in the processing are located. It means that this information
            may be transferred to — and maintained on — computers located outside
            of Your state, province, country or other governmental jurisdiction
            where the data protection laws may differ than those from Your
            jurisdiction.
          </p>
          <p>
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p>
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in place
            including the security of Your data and other personal information.
          </p>
          <h2>Disclosure of Your Personal Data</h2>
          <h3>Business Transactions</h3>
          <p>
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
          <h3>Law enforcement</h3>
          <p>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to valid
            requests by public authorities (e.g. a court or a government agency).
          </p>
          <h2>Other legal requirements</h2>
          <p>
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </li>
            <li>
              Protect the personal safety of Users of the Service or the public
            </li>
            <li>Protect against legal liability</li>
          </ul>
          <h2>Security of Your Personal Data</h2>
          <p>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use commercially
            acceptable means to protect Your Personal Data, We cannot guarantee
            its absolute security.
          </p>

        </div>

        <div className="item">
          <h1>Detailed Information on the Processing of Your Personal Data</h1>
          <p>
            The Service Providers We use may have access to Your Personal Data.
            These third-party vendors collect, store, use, process and transfer
            information about Your activity on Our Service in accordance with
            their Privacy Policies.
          </p>
          <h2>Usage, Performance and Miscellaneous</h2>
          <ul>
            <li>
              We may use third-party Service Providers to provide better
              improvement of our Service. <strong>Google Places</strong> Google
              Places is a service that returns information about places using HTTP
              requests. It is operated by Google Google Places service may collect
              information from You and from Your Device for security purposes. The
              information gathered by Google Places is held in accordance with the
              Privacy Policy of Google:
            </li>
            <li>
              <a href=" https://www.google.com/intl/en/policies/privacy/">
                google.com/intl/en/policies/
              </a>
            </li>
          </ul>
        </div>

        <div className="item">
          <h1>Children's Privacy</h1>
          <p>
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are aware
            that Your child has provided Us with Personal Data, please contact Us.
            If We become aware that We have collected Personal Data from anyone
            under the age of 13 without verification of parental consent, We take
            steps to remove that information from Our servers.
          </p>
          <p>
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </p>
          <h1>Links to Other Websites</h1>
          <p>
            Our Service may contain links to other websites that are not operated
            by Us. If You click on a third party link, You will be directed to
            that third party's site. We strongly advise You to review the Privacy
            Policy of every site You visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the content,
            privacy policies or practices of any third party sites or services.
          </p>
          <h1>Changes to this Privacy Policy</h1>
          <p>
            We may update Our Privacy Policy from time to time. We will notify You
            of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>

        </div>

        <div className="item">
          <h1>Contact Us</h1>
          <ul>
            <li>
              If you have any questions about this Privacy Policy, You can contact
              us:
            </li>

            <li>By email: sales@webappssoft.com</li>

            <li>
              By visiting this page on our website:{" "}
              <a href="www.webappssoft.com">www.webappssoft.com</a>
            </li>

            <li>By phone number: +91 03379647522</li>

            <li>By mail: 128 jodhpur Park kolkata 700045</li>

            <li className="flex_inline">
              <div className="">
                <i className="bx bx-calendar"></i>
                <strong>March 5, 2021</strong>
              </div>
              <div className="">
                <i className="bx bxs-user"></i>
                <strong>Legal Team</strong>
              </div>
            </li>
          </ul>
        </div>

      </div>

      <div className="query_related_items">
        <h2>Related Legal Docs</h2>

        <div className="relatedDoc-container">
          <div className="box">
            <img
              src="https://webappssoft.com/images/webapps-agreement/refund-policy.jpg"
              alt=""
            />

            <div className="des">
              <div className="date">
                <i className="bx bx-calendar"></i>
                <p>March 5, 2021</p>
              </div>
              <div className="date">
                <i className="bx bxs-user"></i>
                <p>Leagel Deoartment</p>
              </div>

              <h3>OUR REFUND POLICY</h3>
            </div>
          </div>

          <div className="box">
            <img
              src="https://webappssoft.com/images/webapps-agreement/webapps-precicypolicy.jpg"
              alt=""
            />

            <div className="des">
              <div className="date">
                <i className="bx bx-calendar"></i>
                <p>March 5, 2021</p>
              </div>
              <div className="date">
                <i className="bx bxs-user"></i>
                <p>Leagel Deoartment</p>
              </div>

              <h3>PRAVICY POLICY</h3>
            </div>
          </div>

          <div className="box">
            <img
              src="https://webappssoft.com/images/webapps-agreement/webapss-canclation-policy.jpg"
              alt=""
            />

            <div className="des">
              <div className="date">
                <i className="bx bx-calendar"></i>
                <p>March 5, 2021</p>
              </div>
              <div className="date">
                <i className="bx bxs-user"></i>
                <p>Leagel Deoartment</p>
              </div>

              <h3>OUR CANCELLATION POLICY</h3>
            </div>
          </div>

          <div className="box">
            <img
              src="https://webappssoft.com/images/webapps-agreement/master-service-agreement.png"
              alt=""
            />

            <div className="des">
              <div className="date">
                <i className="bx bx-calendar"></i>
                <p>March 5, 2021</p>
              </div>
              <div className="date">
                <i className="bx bxs-user"></i>
                <p>Leagel Deoartment</p>
              </div>

              <h3>MSA AGREEEMENT</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyPage;
