import React from 'react'
import marqDot from '../../assets/img/marqeDot.png'
import Marquee from 'react-fast-marquee'

const LargeMarqee = () => {
  return (
    <div className="marque_slider">
      <Marquee play>
        <h1 className="marketing">
          Branding
        </h1>
        <div className="star">
          <img src={marqDot} alt="" />
        </div>
        <h1 className="marque_text">Design</h1>
        <div className="star">
          <img src={marqDot} alt="" />
        </div>
        <h1 className="marketing">
          Branding
        </h1>
        <div className="star">
          <img src={marqDot} alt="" />
        </div>
        <h1 className="marque_text">Marketing</h1>
        <div className="star">
          <img src={marqDot} alt="" />
        </div>
        <h1 className="marketing">
          Branding
        </h1>
        <div className="star">
          <img src={marqDot} alt="" />
        </div>
        <h1 className="marque_text">Lets Create WASS</h1>
        <div className="star">
          <img src={marqDot} alt="" />
        </div>
      </Marquee>
    </div>
  )
}

export default LargeMarqee
