import React from "react";
import Marquee from "react-fast-marquee";
import ambuja from "../../assets/img/ClientsBrands/ambuja.png";
import futureCity from "../../assets/img/ClientsBrands/futureCity.png";
import kunalSarkar from "../../assets/img/ClientsBrands/kunalSarkar.png";
import polycab from "../../assets/img/ClientsBrands/polycab.png";
import prativa from "../../assets/img/ClientsBrands/prativa.png";
import way2stydy from "../../assets/img/ClientsBrands/way2Study.png";
import "./PartnershipMargee.css";

const PartnershipMarqee = () => {
  return (
    <div className="partnershipmarqee">
      <Marquee direction="right">
        <div className="brand_image">
          <img src={way2stydy} alt="" />
        </div>

        <div className="brand_image">
          <img src={ambuja} alt="" />
        </div>

        <div className="brand_image">
          <img src={futureCity} alt="" />
        </div>
        <div className="brand_image">
          <img src={kunalSarkar} alt="" />
        </div>

        <div className="brand_image">
          <img src={polycab} alt="" />
        </div>

        <div className="brand_image">
          <img src={prativa} alt="" />
        </div>

        <div className="brand_image">
          <img src={way2stydy} alt="" />
        </div>

        <div className="brand_image">
          <img src={ambuja} alt="" />
        </div>

        <div className="brand_image">
          <img src={futureCity} alt="" />
        </div>
        <div className="brand_image">
          <img src={kunalSarkar} alt="" />
        </div>

        <div className="brand_image">
          <img src={polycab} alt="" />
        </div>

        <div className="brand_image">
          <img src={prativa} alt="" />
        </div>
      </Marquee>

      <Marquee direction="left">
        <div className="brand_image">
          <img src={prativa} alt="" />
        </div>

        <div className="brand_image">
          <img src={polycab} alt="" />
        </div>

        <div className="brand_image">
          <img src={kunalSarkar} alt="" />
        </div>

        <div className="brand_image">
          <img src={futureCity} alt="" />
        </div>

        <div className="brand_image">
          <img src={ambuja} alt="" />
        </div>

        <div className="brand_image">
          <img src={way2stydy} alt="" />
        </div>

        <div className="brand_image">
          <img src={prativa} alt="" />
        </div>

        <div className="brand_image">
          <img src={polycab} alt="" />
        </div>

        <div className="brand_image">
          <img src={kunalSarkar} alt="" />
        </div>

        <div className="brand_image">
          <img src={futureCity} alt="" />
        </div>

        <div className="brand_image">
          <img src={ambuja} alt="" />
        </div>

        <div className="brand_image">
          <img src={way2stydy} alt="" />
        </div>
      </Marquee>
    </div>
  );
};

export default PartnershipMarqee;
