import "./ServicePage.css";
import fadeA from "../../assets/img/ServicesPage/servicePageA.png";
import women from "../../assets/img/ServicesPage/women.png";
import PartnershipMarqee from "../../components/Marqee/PartnershipMarqee";
import contentA from "../../assets/img/ServicesPage/contentA.png";
import contentA2 from "../../assets/img/ServicesPage/servicePageA2.png";
import LargeMarqee from "../../components/Marqee/LargeMarqee";
import rectangle from "../../assets/img/ServicesPage/Rectangle.png";
import baby from "../../assets/img/ServicesPage/baby.png";
import Banner from "./Banner";
import BusinessFaq from "../../components/BusinessFaq/BusinessFaq";
import Connect from "../../components/Connect/Connect";
import Marquee from 'react-fast-marquee'
import { Helmet } from "react-helmet-async";

const ServicePage = () => {
  return (
    <div id="service_page">

      <Helmet>
        <title>Service Page | Webapps Software Solutions </title>
        <meta name="copyright" content="https://webappssoft.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webappssoft.com/" />
        <link rel="next" href="https://webappssoft.com/" />
        <meta property="og:title" content="Property Dealers in Jamshedpur | Property Dealers Near Me" />
        <meta property="og:url" content="https://webappssoft.com/" />
        <meta property="og:image" content="https://webappssoft.com/logo/sy-logo.jpg" />
        <meta property="og:description" content="Property Dealers in Jamshedpur: Find 150+ Reliable real estate agents and Brokers in Jamshedpur. Contact residential and Commercial property consultants online." />
        <meta name="description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="keywords" content="website design company India,web designing India, best web design company,top web design company,top web design companies India, web design and development company in india" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta property="og:site_name" content="Webapps Software Solutions" />
        <meta name="twitter:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="twitter:title" content="Website Design & Development Company In Kolkata, India" />
        <meta property="og:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:site" content="webapps software Solutions" />
        <meta name="twitter:creator" content="@webappsoftwares" />
        <meta name="theme-color" content="#0098DA" />
        <meta name="author" content="WASS Integrated Tech Enable It Solutions" />
        <meta name="google-site-verification" content="3qKB507bM-zleASA7ZJYxnvl9L30Wk2o4KA7dZksurY" />
      </Helmet>
      {/* =========== SERVICE PAGE CAROSEL =========== */}

      <div className="service_carosel">
        <Banner />
      </div>
      {/* <Carousel/> */}

      {/* ================= SERVICE PAGE HOVER ITEMS ======================= */}

      <div className="servie_page-content">
        <div className="servie_page-content1">
          <h3>Digital Experience</h3>

          <div className="servie_page-content-overlay obob1">
            <div className="service_page-overlay--content">
              <h4 className="service_page-overlay--content_learn-more">
                Learn More &#x2192;
              </h4>

              <div className="servicePageA">
                <img src={fadeA} alt="img" />
              </div>
            </div>

            <div className="service_overlay--actual--content">
              <img src={contentA} alt="img" />

              <div className="service_overlay--actual--content--lists">
                <p className="hover-left">Lorem ipsum dolor sit, amet </p>
                <p className="hover-left"> consectetur adipisicing elit </p>
                <p className="hover-left"> consectetur adipisicing </p>
              </div>
            </div>
          </div>
        </div>

        <div className="servie_page-content1">
          <h3>Digital Experience</h3>

          <div className="servie_page-content-overlay obob2">
            <div className="service_page-overlay--content">
              <h4 className="service_page-overlay--content_learn-more">
                Learn More &#x2192;
              </h4>

              <div className="servicePageA">
                <img src={fadeA} alt="img" />
              </div>
            </div>

            <div className="service_overlay--actual--content">
              <img src={contentA} alt="img" />

              <div className="service_overlay--actual--content--lists">
                <p className="hover-left">Lorem ipsum dolor sit, amet </p>
                <p className="hover-left"> consectetur adipisicing elit </p>
                <p className="hover-left"> consectetur adipisicing </p>
              </div>
            </div>
          </div>
        </div>

        <div className="servie_page-content1 ">
          <h3>Digital Experience</h3>

          <div className="servie_page-content-overlay obob3">
            <div className="service_page-overlay--content">
              <h4 className="service_page-overlay--content_learn-more">
                Learn More &#x2192;
              </h4>

              <div className="servicePageA">
                <img src={fadeA} alt="img" />
              </div>
            </div>

            <div className="service_overlay--actual--content">
              <img src={contentA} alt="img" />

              <div className="service_overlay--actual--content--lists">
                <p className="hover-left">Lorem ipsum dolor sit, amet </p>
                <p className="hover-left"> consectetur adipisicing elit </p>
                <p className="hover-left"> consectetur adipisicing </p>
              </div>
            </div>
          </div>
        </div>

        <div className="servie_page-content1">
          <h3>Digital Experience</h3>

          <div className="servie_page-content-overlay obob4">
            <div className="service_page-overlay--content">
              <h4 className="service_page-overlay--content_learn-more">
                Learn More &#x2192;
              </h4>

              <div className="servicePageA">
                <img src={contentA2} alt="img" />
              </div>
            </div>

            <div className="service_overlay--actual--content">
              <img src={contentA} alt="img" />

              <div className="service_overlay--actual--content--lists">
                <p className="hover-left">Lorem ipsum dolor sit, amet </p>
                <p className="hover-left"> consectetur adipisicing elit </p>
                <p className="hover-left"> consectetur adipisicing </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================= MARQEE ======================= */}
      <div className="">
        <LargeMarqee />
      </div>

      {/* ================= SERVICE PAGE CONTENT PARAGRAPH ======================= */}

      <div className="service_page-content-paragraph">
        <h1>
          Sundew is a <span>digital transformation</span> and{" "}
          <span> consulting </span> company based in India. We leverage
          technology to promote sustainability and automation, enhance customer
          experiences, and drive data-driven decision-making.
        </h1>

        <h1>
          <span> Sundew specializes </span> in crafting tailored transformation
          solutions that empower global businesses, ensuring their continued
          competitiveness in today's dynamic business landscape.
        </h1>

        <br />

        <div className="service_page-learn-more-btn">
          <h3>
            We collaborate, accelerate and transform <br /> businesses for a
            sustainable future.
          </h3>
          <h2>design & user experience</h2>

          <button className="service-btn">
            Learn More
            <span>&#x2192;</span>
          </button>
        </div>

        <div className="service_design__user-experience">
          <img className="service-women-image" src={women} alt="img" />

          <div className="service_design_-experience-content">
            <h1 className="knowladge">Knowledge</h1>
            <h3>
              Our validated and distinctive approach significantly enhances the
              success rates of technology projects across various
              organizational, and functional domains.
            </h3>
          </div>
        </div>
      </div>

      <div className="service-relative-container">
        <img className="ractangle_image" src={rectangle} alt="img" />

        <div className="service_ractengal-items">
          <div className="service_ractengal-items-boxsz">
            <img className="baby_imageee" src={baby} alt="img" />
            <p className="ser_pragraph">
              Technical Solution <br /> & Software{" "}
            </p>
            <span className="serevice_arrrow">&#x2192;</span>
          </div>

          <div className="service_ractengal-items-boxsz">
            <img className="baby_imageee" src={baby} alt="img" />
            <p className="ser_pragraph">
              Technical Solution <br /> & Software{" "}
            </p>
            <span className="serevice_arrrow">&#x2192;</span>
          </div>

          <div className="service_ractengal-items-boxsz">
            <img className="baby_imageee" src={baby} alt="img" />
            <p className="ser_pragraph">
              Technical Solution <br /> & Software{" "}
            </p>
            <span className="serevice_arrrow">&#x2192;</span>
          </div>

          <div className="service_ractengal-items-boxsz">
            <img className="baby_imageee" src={baby} alt="img" />
            <p className="ser_pragraph">
              Technical Solution <br /> & Software{" "}
            </p>
            <span className="serevice_arrrow">&#x2192;</span>
          </div>

          <div className="service_ractengal-items-boxsz">
            <img className="baby_imageee" src={baby} alt="img" />
            <p className="ser_pragraph">
              Technical Solution <br /> & Software{" "}
            </p>
            <span className="serevice_arrrow">&#x2192;</span>
          </div>

          <div className="service_ractengal-items-boxsz">
            <img className="baby_imageee" src={baby} alt="img" />
            <p className="ser_pragraph">
              Technical Solution <br /> & Software{" "}
            </p>
            <span className="serevice_arrrow">&#x2192;</span>
          </div>

          <div className="service_ractengal-items-boxsz">
            <img className="baby_imageee" src={baby} alt="img" />
            <p className="ser_pragraph">
              Technical Solution <br /> & Software{" "}
            </p>
            <span className="serevice_arrrow">&#x2192;</span>
          </div>

          <div className="service_ractengal-items-boxsz">
            <img className="baby_imageee" src={baby} alt="img" />
            <p className="ser_pragraph">
              Technical Solution <br /> & Software{" "}
            </p>

            <span className="serevice_arrrow">&#x2192;</span>
          </div>
        </div>
      </div>

      <div className="service_page-texts nn">

        <p>Sundew is a <span>digital transformation and consulting company</span> based in India. We leverage technology to promote sustainability and automation, enhance customer experiences, and drive data-driven decision-making.</p>

        <p>Sundew specializes in crafting tailored transformation solutions that empower global businesses, ensuring their continued competitiveness in today's dynamic business landscape.</p>

        <p>We collaborate, accelerate and transform
          businesses for a sustainable future.</p>
      </div>

      {/* ================= MEN WALKING ======================= */}

      <div className="man_walking">
        <div className="man_walking_content">
          <div className="man_walking_content-title">
            <h3>
              Trusted by millions of users
              and 200+ teams globally.
            </h3>

            <p>
              Let's make your digital transformation journey smooth and
              straightforward with enterprise-grade technology.
            </p>

            <div className="man-contact_button">
              <button className="btn">
                Contect Us
                <span>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 448 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z"></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>

          <img
            src="https://sundewsolutions.com/public/frontend/assets/img/2023_v2/trust-hero.webp?v=2.563"
            alt="img"
          />
        </div>
      </div>

      <div className="service_page-texts mm">

        <p>Sundew is a <span>digital transformation and consulting company</span> based in India. We leverage technology to promote sustainability and automation, enhance customer experiences, and drive data-driven decision-making.</p>

        <p>Sundew specializes in crafting tailored transformation solutions that empower global businesses, ensuring their continued competitiveness in today's dynamic business landscape.</p>

        <p>We collaborate, accelerate and transform
          businesses for a sustainable future.</p>
      </div>



      {/* ================ =============== */}

      <div id="service_page3">

        <div className="yet_effect">
          <div className="title">
            <div className="heading">
              <h4>
                <div className="line"></div>
                Workflow
              </h4>
              <h1>A Simple Yet Effective Three Steps Process.</h1>
            </div>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas
              asperiores dicta, cumque neque id veritatis.
            </p>
          </div>

          <div className="yet_cards">
            <div className="card_line">
              <div className="fade_line">
                <div className="line_balls1">
                  <div className="f_line"></div>
                </div>
                <div className="line_balls2">
                  <div className="f_line"></div>
                </div>
                <div className="line_balls3">
                  <div className="f_line"></div>
                </div>
                <div className="line_balls4">
                  <div className="f_line"></div>
                </div>
              </div>
            </div>
            <div className="card_boxes">
              <div className="box">
                <h3 className="box_title">
                  Simple Creative Design
                  <div className="number">
                    <h3>01</h3>
                  </div>
                </h3>
                <p className="description">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellat odio nihil quibusdam ut aperiam praesentium?
                </p>
              </div>

              <div className="box">
                <h3 className="box_title">
                  Understanding Client's
                  <div className="number">
                    <h3>02</h3>
                  </div>
                </h3>
                <p className="description">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellat odio nihil quibusdam ut aperiam praesentium?
                </p>
              </div>

              <div className="box">
                <h3 className="box_title">
                  Creating A Project Plan
                  <div className="number">
                    <h3>03</h3>
                  </div>
                </h3>
                <p className="description">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellat odio nihil quibusdam ut aperiam praesentium?
                </p>
              </div>

              <div className="box">
                <h3 className="box_title">
                  Launching The Project
                  <div className="number">
                    <h3>04</h3>
                  </div>
                </h3>
                <p className="description">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellat odio nihil quibusdam ut aperiam praesentium?
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>


      {/* ================= servivce_page-summery ======================= */}

      <div className="service_page-summery">
        <div className="service_page-summery-boxesss">
          <div className="box_1 box-11">
            <h3 className="box-11-heading-1">
              Sumarise in a table
              <div className="box-11-heading-alt1 a11-heading-alt11">
                Sumarise in a table
              </div>
            </h3>

            <div className="box-11-heading-p1">
              Product Marketing
              <div className="box-11-heading-altp1 a11-heading-altp11">
                Product Marketing
              </div>
            </div>
          </div>

          <div className="box_2">
            <p>Sumarise in a table Technology</p>
            <div className="box_2-p2">
              Try this out
              <span className="box_2-arrow">&#x2192;</span>
            </div>
          </div>
        </div>

        <div className="service_page-summery-boxesss">
          <div className="box_1 box-11">
            <h3 className="box-11-heading-1">
              Sumarise in a table
              <div className="box-11-heading-alt1 a11-heading-alt11">
                Sumarise in a table
              </div>
            </h3>

            <div className="box-11-heading-p1">
              Product Marketing
              <div className="box-11-heading-altp1 a11-heading-altp11">
                Product Marketing
              </div>
            </div>
          </div>

          <div className="box_2">
            <p>Sumarise in a table Technology</p>
            <p className="box_2-p2">
              Try this out
              <span className="box_2-arrow">&#x2192;</span>
            </p>
          </div>
        </div>

        <div className="service_page-summery-boxesss">
          <div className="box_1 box-11">
            <h3 className="box-11-heading-1">
              Sumarise in a table
              <div className="box-11-heading-alt1 a11-heading-alt11">
                Sumarise in a table
              </div>
            </h3>

            <div className="box-11-heading-p1">
              Product Marketing
              <div className="box-11-heading-altp1 a11-heading-altp11">
                Product Marketing
              </div>
            </div>
          </div>

          <div className="box_2">
            <p>Sumarise in a table Technology</p>
            <p className="box_2-p2">
              Try this out
              <span className="box_2-arrow">&#x2192;</span>
            </p>
          </div>
        </div>

        <div className="service_page-summery-boxesss">
          <div className="box_1 box-11">
            <h3 className="box-11-heading-1">
              Sumarise in a table
              <div className="box-11-heading-alt1 a11-heading-alt11">
                Sumarise in a table
              </div>
            </h3>

            <div className="box-11-heading-p1">
              Product Marketing
              <div className="box-11-heading-altp1 a11-heading-altp11">
                Product Marketing
              </div>
            </div>
          </div>

          <div className="box_2">
            <p>Sumarise in a table Technology</p>
            <div className="box_2-p2">
              Try this out
              <span className="box_2-arrow">&#x2192;</span>
            </div>
          </div>
        </div>
      </div>

      {/* ================= CONTECT WITH YOUR CONSULTING PRACTICE ======================= */}

      <Connect />

      {/* ================= PATRNERSHIP STATUS ======================= */}

      <div className="" style={{ margin: "3rem 0" }}>
        <PartnershipMarqee />
      </div>

      {/* ================= KEEP YOUR BUSINESS SAFE ======================= */}

      <BusinessFaq />

      <div className="">
        <div id="review">
          <div className="titile">
            <h1>
              What Our <span>Clients</span> Are Saying <span>About WASS</span>
            </h1>
          </div>
          <div className="review_content_1">
            <Marquee direction="left" speed="30">
              <div className="review_content_boxes ">
                <img
                  className="review_image"
                  src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                  alt=""
                />

                <p>
                  Webapp software solutions has been a fantastic web development
                  company to work with. They are experts in their field and are
                  always able to meet an ever-changing deadline while maintaining
                  the highest level of quality.
                </p>

                <div className="peaple">
                  <img
                    src="https://rainbowit.net/html/histudy/assets/images/testimonial/client-03.png"
                    alt=""
                  />
                  <h4>Riddhi Driver Service Centre</h4>
                </div>
              </div>

              <div className="review_content_boxes bg-gradient">
                <img
                  className="absolute"
                  src="https://rainbowit.net/html/histudy/assets/images/icons/quote.svg"
                  alt=""
                />

                <img
                  className="review_image"
                  src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                  alt=""
                />

                <p>
                  Office situated in good location. Marketing team have good
                  response towards customers. All software engineers are well
                  trained, identified the customer needs and provide solutions in
                  very economic rate. I like their Sales after service.
                </p>

                <div className="peaple">
                  <img
                    src="https://rainbowit.net/html/histudy/assets/images/testimonial/client-03.png"
                    alt=""
                  />
                  <h4>Sakib Reza</h4>
                </div>
              </div>

              <div className="review_content_boxes ">
                <img
                  className="review_image"
                  src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                  alt=""
                />

                <p>
                  Webapps software offers an incredible work experience that I
                  highly recommend. The company provides a positive and engaging
                  environment for its employees, fostering growth and
                  collaboration
                </p>

                <div className="peaple">
                  <img
                    src="https://rainbowit.net/html/histudy/assets/images/testimonial/client-03.png"
                    alt=""
                  />
                  <h4>Parbati Das</h4>
                </div>
              </div>

              <div className="review_content_boxes bg-gradient">
                <img
                  className="absolute"
                  src="https://rainbowit.net/html/histudy/assets/images/icons/quote.svg"
                  alt=""
                />

                <img
                  className="review_image"
                  src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                  alt=""
                />

                <p>
                  It's a very good company for startup and chip and best price
                  solutions lords of ideas provided by this company and I am so
                  happy with Him thanks
                </p>

                <div className="peaple">
                  <img
                    src="https://rainbowit.net/html/histudy/assets/images/testimonial/client-03.png"
                    alt=""
                  />
                  <h4>Vijay Kumar Yadav</h4>
                </div>
              </div>

              <div className="review_content_boxes ">
                <img
                  className="review_image"
                  src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                  alt=""
                />

                <p>
                  Webapp software solutions has been a fantastic web development
                  company to work with. They are experts in their field and are
                  always able to meet an ever-changing deadline while maintaining
                  the highest level of quality.
                </p>

                <div className="peaple">
                  <img
                    src="https://rainbowit.net/html/histudy/assets/images/testimonial/client-03.png"
                    alt=""
                  />
                  <h4>Riddhi Driver Service Centre</h4>
                </div>
              </div>

              <div className="review_content_boxes bg-gradient">
                <img
                  className="absolute"
                  src="https://rainbowit.net/html/histudy/assets/images/icons/quote.svg"
                  alt=""
                />

                <img
                  className="review_image"
                  src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                  alt=""
                />

                <p>
                  Office situated in good location. Marketing team have good
                  response towards customers. All software engineers are well
                  trained, identified the customer needs and provide solutions in
                  very economic rate. I like their Sales after service.
                </p>

                <div className="peaple">
                  <img
                    src="https://rainbowit.net/html/histudy/assets/images/testimonial/client-03.png"
                    alt=""
                  />
                  <h4>Sakib Reza</h4>
                </div>
              </div>

              <div className="review_content_boxes ">
                <img
                  className="review_image"
                  src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                  alt=""
                />

                <p>
                  Webapps software offers an incredible work experience that I
                  highly recommend. The company provides a positive and engaging
                  environment for its employees, fostering growth and
                  collaboration
                </p>

                <div className="peaple">
                  <img
                    src="https://rainbowit.net/html/histudy/assets/images/testimonial/client-03.png"
                    alt=""
                  />
                  <h4>Parbati Das</h4>
                </div>
              </div>

              <div className="review_content_boxes bg-gradient">
                <img
                  className="absolute"
                  src="https://rainbowit.net/html/histudy/assets/images/icons/quote.svg"
                  alt=""
                />

                <img
                  className="review_image"
                  src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                  alt=""
                />

                <p>
                  It's a very good company for startup and chip and best price
                  solutions lords of ideas provided by this company and I am so
                  happy with Him thanks
                </p>

                <div className="peaple">
                  <img
                    src="https://rainbowit.net/html/histudy/assets/images/testimonial/client-03.png"
                    alt=""
                  />
                  <h4>Vijay Kumar Yadav</h4>
                </div>
              </div>
            </Marquee>
          </div>

          <div className="review_content_2">
            <Marquee direction="right" speed="30">
              <div className="review_content_boxes ">
                <img
                  className="review_image"
                  src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                  alt=""
                />

                <p>
                  They have a small, but very dedicated team. They are good with
                  their work. They always try to give their best for the service.
                  You can try them if you want.
                </p>

                <div className="peaple">
                  <img
                    src="https://rainbowit.net/html/histudy/assets/images/testimonial/client-03.png"
                    alt=""
                  />
                  <h4>Prince Kumar</h4>
                </div>
              </div>

              <div className="review_content_boxes bg-gradient">
                <img
                  className="absolute"
                  src="https://rainbowit.net/html/histudy/assets/images/icons/quote.svg"
                  alt=""
                />

                <img
                  className="review_image"
                  src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                  alt=""
                />

                <p>
                  Amazing Experience with all team member they all are skilled to
                  manage my project for exports & its software where there is lot
                  many thing to say about my Experience in single word its
                  fantastic
                </p>

                <div className="peaple">
                  <img
                    src="https://rainbowit.net/html/histudy/assets/images/testimonial/client-03.png"
                    alt=""
                  />
                  <h4>Soni Kaur</h4>
                </div>
              </div>

              <div className="review_content_boxes ">
                <img
                  className="review_image"
                  src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                  alt=""
                />

                <p>
                  I was looking for the web developer had past experience was
                  completely bad but with webapps i got my work done with there
                  best team from day one thy had good in communication and
                  commitment till ends.
                </p>

                <div className="peaple">
                  <img
                    src="https://rainbowit.net/html/histudy/assets/images/testimonial/client-03.png"
                    alt=""
                  />
                  <h4>A K Pandit</h4>
                </div>
              </div>

              <div className="review_content_boxes bg-gradient">
                <img
                  className="absolute"
                  src="https://rainbowit.net/html/histudy/assets/images/icons/quote.svg"
                  alt=""
                />

                <img
                  className="review_image"
                  src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                  alt=""
                />

                <p>
                  It's been excellent experience with webapps software solutions
                  for my app development it's amazing team and well delivered my
                  app on time with excellent uiux design.
                </p>

                <div className="peaple">
                  <img
                    src="https://rainbowit.net/html/histudy/assets/images/testimonial/client-03.png"
                    alt=""
                  />
                  <h4>Parma Pandit</h4>
                </div>
              </div>

              <div className="review_content_boxes ">
                <img
                  className="review_image"
                  src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                  alt=""
                />

                <p>
                  They have a small, but very dedicated team. They are good with
                  their work. They always try to give their best for the service.
                  You can try them if you want.
                </p>

                <div className="peaple">
                  <img
                    src="https://rainbowit.net/html/histudy/assets/images/testimonial/client-03.png"
                    alt=""
                  />
                  <h4>Prince Kumar</h4>
                </div>
              </div>

              <div className="review_content_boxes bg-gradient">
                <img
                  className="absolute"
                  src="https://rainbowit.net/html/histudy/assets/images/icons/quote.svg"
                  alt=""
                />

                <img
                  className="review_image"
                  src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                  alt=""
                />

                <p>
                  Amazing Experience with all team member they all are skilled to
                  manage my project for exports & its software where there is lot
                  many thing to say about my Experience in single word its
                  fantastic
                </p>

                <div className="peaple">
                  <img
                    src="https://rainbowit.net/html/histudy/assets/images/testimonial/client-03.png"
                    alt=""
                  />
                  <h4>Soni Kaur</h4>
                </div>
              </div>

              <div className="review_content_boxes ">
                <img
                  className="review_image"
                  src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                  alt=""
                />

                <p>
                  I was looking for the web developer had past experience was
                  completely bad but with webapps i got my work done with there
                  best team from day one thy had good in communication and
                  commitment till ends.
                </p>

                <div className="peaple">
                  <img
                    src="https://rainbowit.net/html/histudy/assets/images/testimonial/client-03.png"
                    alt=""
                  />
                  <h4>A K Pandit</h4>
                </div>
              </div>

              <div className="review_content_boxes bg-gradient">
                <img
                  className="absolute"
                  src="https://rainbowit.net/html/histudy/assets/images/icons/quote.svg"
                  alt=""
                />

                <img
                  className="review_image"
                  src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                  alt=""
                />

                <p>
                  It's been excellent experience with webapps software solutions
                  for my app development it's amazing team and well delivered my
                  app on time with excellent uiux design.
                </p>

                <div className="peaple">
                  <img
                    src="https://rainbowit.net/html/histudy/assets/images/testimonial/client-03.png"
                    alt=""
                  />
                  <h4>Parma Pandit</h4>
                </div>
              </div>
            </Marquee>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicePage;
