import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import AllRoutes from "../src/routes/AllRoutes";
import head from '../src/assets/videos/head2.mp4'
import BottomHeader from "./components/Header/BottomHeader";
const App = () => {
  return (
    <div className="app">
      <Header />
      <AllRoutes />
      <Footer />

      <div className="head_item">
        <video
          src={head}
          width="200"
          height="200"
          loop
          muted
          autoPlay
          playsInline
          poster="path_to_poster_image.jpg"
        >
          {" "}
        </video>
      </div>


      <BottomHeader/>


      
    </div>
  );
};

export default App;
