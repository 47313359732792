import React from "react";
import { useRef, useEffect } from "react";
import "./Banner.css";
const Banner = () => {
  const carouselRef = useRef(null);
  const timeRunning = 3000;
  const timeAutoNext = 7000;

  useEffect(() => {
    const carouselDom = carouselRef.current;
    const nextButton = carouselDom.querySelector("#next");
    const prevButton = carouselDom.querySelector("#prev");
    const sliderDom = carouselDom.querySelector(".list");
    const thumbnailBorderDom = carouselDom.querySelector(".thumbnail");

    let runTimeOut;
    let runNextAuto;

    const handleNext = () => {
      showSlider("next");
    };

    const handlePrev = () => {
      showSlider("prev");
    };

    const showSlider = (type) => {
      const sliderItems = sliderDom.querySelectorAll(".item");
      const thumbnailItems = thumbnailBorderDom.querySelectorAll(".item");

      if (type === "next") {
        sliderDom.appendChild(sliderItems[0]);
        thumbnailBorderDom.appendChild(thumbnailItems[0]);
        carouselDom.classList.add("next");
      } else {
        sliderDom.prepend(sliderItems[sliderItems.length - 1]);
        thumbnailBorderDom.prepend(thumbnailItems[thumbnailItems.length - 1]);
        carouselDom.classList.add("prev");
      }
      clearTimeout(runTimeOut);
      runTimeOut = setTimeout(() => {
        carouselDom.classList.remove("next");
        carouselDom.classList.remove("prev");
      }, timeRunning);

      clearTimeout(runNextAuto);
      runNextAuto = setTimeout(() => {
        nextButton.click();
      }, timeAutoNext);
    };

    nextButton.addEventListener("click", handleNext);
    prevButton.addEventListener("click", handlePrev);

    runNextAuto = setTimeout(() => {
      nextButton.click();
    }, timeAutoNext);

    return () => {
      clearTimeout(runNextAuto);
      nextButton.removeEventListener("click", handleNext);
      prevButton.removeEventListener("click", handlePrev);
    };
  }, []);

  return (
    <div>
      {/* <!-- carousel --> */}
      <div ref={carouselRef} className="carousel">
        {/* <!-- list item --> */}
        <div className="list">
          <div className="item">
            <img src="https://sundewsolutions.com/public/frontend/assets/img/2023_v2/home-hero/edu.webp?v=2.563" alt="img" />
            <div className="content">
              <div className="author">GiorgioGT</div>
              <div className="title">DESIGN SLIDER</div>
              <div className="topic">ANIMAL</div>
              <div className="des">
                {/* <!-- lorem 50 --> */}
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut
                sequi, rem magnam nesciunt minima placeat, itaque eum neque
                officiis unde, eaque optio ratione aliquid 
              </div>
            </div>
          </div>
          <div className="item">
            <img src="https://sundewsolutions.com/public/frontend/assets/img/2023_v2/home-hero/forbesIndia.webp?v=2.563" alt="img" />
            <div className="content">
              <div className="author">giorgioGT</div>
              <div className="title">DESIGN SLIDER</div>
              <div className="topic">ANIMAL</div>
              <div className="des">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut
                sequi, rem magnam nesciunt minima placeat, itaque eum neque
                officiis unde, eaque optio ratione aliquid
              
              </div>
            </div>
          </div>
          <div className="item">
            <img src="https://sundewsolutions.com/public/frontend/assets/img/2023_v2/home-hero/digitrans.webp?v=2.563" alt="img" />
            <div className="content">
              <div className="author">giorgioGT</div>
              <div className="title">DESIGN SLIDER</div>
              <div className="topic">ANIMAL</div>
              <div className="des">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut
                sequi, rem magnam nesciunt minima placeat, itaque eum neque
                officiis unde, eaque optio ratione aliquid
              
              </div>
            </div>
          </div>
          <div className="item">
            <img src="https://sundewsolutions.com/public/frontend/assets/img/2023_v2/home-hero/homew.webp?v=2.563" alt="img" />
            <div className="content">
              <div className="author">giorgioGT</div>
              <div className="title">DESIGN SLIDER</div>
              <div className="topic">ANIMAL</div>
              <div className="des">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut
                sequi, rem magnam nesciunt minima placeat, itaque eum neque
                officiis unde, eaque optio ratione aliquid 
                
              </div>
            </div>
          </div>
        </div>
        {/* <!-- list thumnail --> */}
        <div className="thumbnail">
          <div className="item">
            <img src="https://sundewsolutions.com/public/frontend/assets/img/2023_v2/home-hero/edu.webp?v=2.563" alt='img' />

            <div className="content">
              <h4 className="content-text-title">Technology</h4>
              <small className="content-text-paragraph">
                Lorem ipsum dolor sit amet consecter adipisicing elit.
              </small>
              <div className="content-btn">
                <button>View</button>
              </div>
            </div>
          </div>
          <div className="item">
            <img src="https://sundewsolutions.com/public/frontend/assets/img/2023_v2/home-hero/forbesIndia.webp?v=2.563" alt='img' />
            <div className="content">
              <h4 className="content-text-title">Technology</h4>
              <small className="content-text-paragraph">
                Lorem ipsum dolor sit amet consecter adipisicing elit.
              </small>
              <div className="content-btn">
                <button>View</button>
              </div>
            </div>
          </div>
          
          <div className="item">
            <img src="https://sundewsolutions.com/public/frontend/assets/img/2023_v2/home-hero/digitrans.webp?v=2.563" alt='img' />
            <div className="content">
              <h4 className="content-text-title">Technology</h4>
              <small className="content-text-paragraph">
                Lorem ipsum dolor sit amet consecter adipisicing elit.
              </small>
              <div className="content-btn">
                <button>View</button>
              </div>
            </div>
          </div>
          <div className="item">
            <img src="https://sundewsolutions.com/public/frontend/assets/img/2023_v2/home-hero/homew.webp?v=2.563" alt='img' />
            <div className="content">
              <h4 className="content-text-title">Technology</h4>
              <small className="content-text-paragraph">
                Lorem ipsum dolor sit amet consecter adipisicing elit.
              </small>
              <div className="content-btn">
                <button>View</button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- next prev --> */}

        <div className="arrows">
          <button id="prev">
            <i className="bx bx-left-arrow-alt"></i>
          </button>
          <button id="next">
            <i className="bx bx-right-arrow-alt"></i>
          </button>
        </div>
        {/* <!-- time running --> */}
        <div className="time"></div>
      </div>
    </div>
  );
};

export default Banner;
