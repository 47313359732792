import React, { useEffect } from "react";
import "./Header.css";
import Marquee from "react-fast-marquee";
import { motion, useScroll } from "framer-motion";
import svg from "../../assets/img/logo/logo.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import whatsapp from "../../assets/img/logo/whatsapp.svg";
import headerL from "../../assets/img/Header/download.jpeg";

const Header = () => {
  const { scrollYProgress } = useScroll();
  const navigate = useNavigate();
  useEffect(() => {
    const texts = document.querySelector(".text-animation");

    if (texts) {
      const children = texts.children;
      const textLength = children.length;
      let index = 0;

      const animate = () => {
        for (let i = 0; i < textLength; i++) {
          children[i].classList.remove("text-in");
        }

        children[index].classList.add("text-in");

        index = (index + 1) % textLength;
      };

      animate();
      const intervalId = setInterval(animate, 2000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, []);

  const viewModal = () => {
    navigate("/pending");
  };

  return (
    <>
      <nav className="desktop-navigation-menu">
        <div className="nav_item" style={{ backgroundColor: "rgb(32, 0, 27)" }}>
          <div className="logo">
            <NavLink to="/">
              <img
                src={svg}
                alt="webapps software solutions"
                style={{ height: "35px", width: "100px" }}
              />
            </NavLink>

            <div className="blink-dot">
              <span></span>
            </div>

            <p className="text-animation">
              <span>Tech</span>
              <span>Media</span>
              <span>Branding</span>
            </p>
          </div>

          <ul className="desktop-menu-category-list">
            <li className="menu-category">
              <a href="#" className="menu-title">
                Practices
              </a>

              <div className="dropdown-panel">
                <h5
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    backgroundColor: "rgb(255, 191, 49)",
                    marginBottom: "25px",
                    color: "#800080",
                  }}
                >
                  <Marquee play>
                    <span>
                      Warning : WASS never contacts you for part-time jobs or
                      request personal details like account no. or pay per task.
                      Report any suspicious messages or calls.
                    </span>
                  </Marquee>
                </h5>

                <ul className="downpannel_content">
                  <ul className="dropdown-panel-list office_banner_item">
                    <li className="office_banner">
                      <img src={headerL} alt="img" />
                    </li>
                  </ul>

                  <ul className="dropdown-panel-list">
                    <div className="">
                      <li className="dropdown_menu-title">
                        <a href="#">Application Engineering</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Managed Services</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Custom App Development</a>
                      </li>
                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Android App Engineering</a>
                      </li>
                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">IOS App Engineering</a>
                      </li>
                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Hybrid App Development</a>
                      </li>
                    </div>
                  </ul>

                  <ul className="dropdown-panel-list">
                    <div className="">
                      <li className="dropdown_menu-title">
                        <a href="#">Digital Marketing</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Website Development</a>
                      </li>

                      <li className="panel-list-item">
                        <a href="#">Custom Software Development</a>
                      </li>
                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">ERP Software Development</a>
                      </li>
                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Medical Software Development</a>
                      </li>
                    </div>
                  </ul>

                  <ul className="dropdown-panel-list">
                    <div className="">
                      <li className="dropdown_menu-title">
                        <a href="#">Brand Solutions</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Complete Strategy & Marketing</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">E-Business Growth Marketing</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Search Engine Marketing</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Social Media Ads & Campaign</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Brand Identity Transformation</a>
                      </li>
                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">E-commerce Website Development</a>
                      </li>
                    </div>
                  </ul>
                </ul>
              </div>
            </li>

            <li className="menu-category">
              <a href="#" className="menu-title">
                Industries
              </a>

              <div className="dropdown-panel">
                <h5
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    backgroundColor: "rgb(255, 191, 49)",
                    marginBottom: "25px",
                    color: "#321438",
                  }}
                >
                  <Marquee play>
                    <span>
                      Warning : WASS never contacts you for part-time jobs or
                      request personal details like account no. or pay per task.
                      Report any suspicious messages or calls.
                    </span>
                  </Marquee>
                </h5>

                <ul className="downpannel_content">
                  <ul className="dropdown-panel-list office_banner_item">
                    <li className="office_banner">
                      <img src={headerL} alt="img" />
                    </li>
                  </ul>

                  <ul className="dropdown-panel-list">
                    <div className="">
                      <li className="dropdown_menu-title">
                        <a href="#">HealthCare </a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Doctor Consultation Solutions</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Lab Software & Solutions</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">E-Consultation</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Hospital Management</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Clinic Management Software</a>
                      </li>
                    </div>
                  </ul>

                  <ul className="dropdown-panel-list">
                    <div className="">
                      <li className="dropdown_menu-title">
                        <a href="#">Food & Restaurants</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">QSR</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Food Ordering System</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Restaurant Management</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Warehouse Management</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">POS</a>
                      </li>
                    </div>
                  </ul>

                  <ul className="dropdown-panel-list">
                    <div className="">
                      <li className="dropdown_menu-title">
                        <a href="#">Educational services</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Lead Management</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Educational Website</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">School Software on demand</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Teacher Management</a>
                      </li>
                    </div>
                  </ul>
                </ul>
              </div>
            </li>

            <li className="menu-category">
              <a href="#" className="menu-title">
                WHY WASS
              </a>

              <div className="dropdown-panel">
                <h5
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    backgroundColor: "rgb(255, 191, 49)",
                    marginBottom: "25px",
                    color: "#321438",
                  }}
                >
                  <Marquee play>
                    <span>
                      Warning : WASS never contacts you for part-time jobs or
                      request personal details like account no. or pay per task.
                      Report any suspicious messages or calls.
                    </span>
                  </Marquee>
                </h5>

                <ul className="downpannel_content">
                  <ul className="dropdown-panel-list office_banner_item">
                    <li className="office_banner">
                      <img src={headerL} alt="img" />
                    </li>
                  </ul>

                  <ul className="dropdown-panel-list">
                    <div className="">
                      <li className="dropdown_menu-title">
                        <a href="#">About Us</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Company Profile</a>
                      </li>

                      <li className="panel-list-item">
                        <NavLink to="/team">
                          <a href="#">Team </a>
                        </NavLink>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Our Motive</a>
                      </li>
                    </div>
                  </ul>

                  <ul className="dropdown-panel-list">
                    <div className="">
                      <li
                        className="dropdown_menu-title"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Awards</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Testimonials</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Our Portfolio</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Awards</a>
                      </li>
                    </div>
                  </ul>

                  <ul className="dropdown-panel-list">
                    <div className="">
                      <li
                        className="dropdown_menu-title"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Resource</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Career @ WASS</a>
                      </li>

                      <li className="panel-list-item">
                        <Link to="/contect">
                          <a href="#">Contact Us</a>
                        </Link>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Get Quote</a>
                      </li>
                    </div>
                  </ul>
                </ul>
              </div>
            </li>

            <li className="menu-category">
              <a href="#" className="menu-title">
                Our Works
              </a>

              <div className="dropdown-panel">
                <h5
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    backgroundColor: "rgb(255, 191, 49)",
                    marginBottom: "25px",
                    color: "#321438",
                  }}
                >
                  <Marquee play>
                    <span>
                      Warning : WASS never contacts you for part-time jobs or
                      request personal details like account no. or pay per task.
                      Report any suspicious messages or calls.
                    </span>
                  </Marquee>
                </h5>

                <ul className="downpannel_content">
                  <ul className="dropdown-panel-list office_banner_item">
                    <li className="office_banner">
                      <img src={headerL} alt="img" />
                    </li>
                  </ul>

                  <ul className="dropdown-panel-list">
                    <div className="">
                      <li className="dropdown_menu-title">
                        <a href="#">App </a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Compnay profile </a>
                      </li>
                      <li className="panel-list-item">
                        <Link to="/team">
                          <a href="#">Team </a>
                        </Link>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Our Motive </a>
                      </li>
                    </div>
                  </ul>

                  <ul className="dropdown-panel-list">
                    <div className="">
                      <li className="dropdown_menu-title">
                        <a href="#">Website & Software</a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Testimonials </a>
                      </li>

                      <Link to="/mobileportfolio">
                        <li className="panel-list-item">
                          <a href="#">Our Portfolio</a>
                        </li>
                      </Link>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Awards</a>
                      </li>
                    </div>
                  </ul>

                  <ul className="dropdown-panel-list">
                    <div className="">
                      <li className="dropdown_menu-title">
                        <a href="#">Branding </a>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Caree @ WASS</a>
                      </li>

                      <li className="panel-list-item">
                        <Link to="/contect">
                          <a href="#">Contact us</a>
                        </Link>
                      </li>

                      <li
                        className="panel-list-item"
                        onClick={() => viewModal()}
                      >
                        <a href="#">Get Quote </a>
                      </li>
                    </div>
                  </ul>
                </ul>
              </div>
            </li>

            <li className="contact_button">
              <a target="_blank" href="https://wa.link/9sbw44">
                <div className="btn">
                  Connect
                  <span>&#x2192;</span>
                </div>
              </a>
            </li>
          </ul>

          <div className="mobile_button header-button">
            <li className="contact_button">
              <a target="_blank" href="https://wa.link/9sbw44">
                <div className="btn">
                  <img src={whatsapp} className="mobile_whatsapp" alt="img" />

                  <span>&#x2192;</span>
                </div>
              </a>
            </li>
          </div>
        </div>

        <motion.div
          style={{ scaleX: scrollYProgress }}
          className="progress-bar"
        ></motion.div>
      </nav>
    </>
  );
};

export default Header;
