import React from "react";
import "./ServicePage3.css";
import heroBanner from "../../assets/img/ServicePage3/background.png";
import cup from "../../assets/img/ServicePage3/cup.png";
import star from "../../assets/img/ServicePage3/star.png";
import smileGirl from "../../assets/img/ServicePage3/smileGirl.png";
import dots from "../../assets/img/ServicePage3/dots.png";
import rate from "../../assets/img/ServicePage3/rate.png";
import fadeLine from "../../assets/img/ServicePage3/fadeLine.png";
import smileGirl2 from "../../assets/img/ServicePage3/smileGirl2.png";
import communication from "../../assets/img/ServicePage3/group_conversation.png";
import signature from "../../assets/img/ServicePage3/signature.png";
import user from "../../assets/img/ServicePage3/user.png";
import blackBackground from "../../assets/img/ServicePage3/blackBackground.png";
import arrow from "../../assets/img/ServicePage3/arrow.png";
import Button from "../../components/Button/Button";
import squre from "../../assets/img/ServicePage3/squre.png";
import Cards from "../../components/PricingCards/PricingCards";
import BusinessFaq from "../../components/BusinessFaq/BusinessFaq";
import thinkingGirl from "../../assets/img/ServicePage3/thinkingGirl.png";
import card1 from "../../assets/img/ServicePage3/card1.png";
import card2 from "../../assets/img/ServicePage3/card2.png";
import card3 from "../../assets/img/ServicePage3/card3.png";
import EnqueryDetails from '../../components/EnqueryDetails/EnqueryDetails'
import { Helmet } from "react-helmet-async";

const ServicePage3 = () => {
  return (
    <div id="service_page3">

      <Helmet>
        <title>Service Page | Webapps Software Solutions </title>
        <meta name="copyright" content="https://webappssoft.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webappssoft.com/" />
        <link rel="next" href="https://webappssoft.com/" />
        <meta property="og:title" content="Property Dealers in Jamshedpur | Property Dealers Near Me" />
        <meta property="og:url" content="https://webappssoft.com/" />
        <meta property="og:image" content="https://webappssoft.com/logo/sy-logo.jpg" />
        <meta property="og:description" content="Property Dealers in Jamshedpur: Find 150+ Reliable real estate agents and Brokers in Jamshedpur. Contact residential and Commercial property consultants online." />
        <meta name="description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="keywords" content="website design company India,web designing India, best web design company,top web design company,top web design companies India, web design and development company in india" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta property="og:site_name" content="Webapps Software Solutions" />
        <meta name="twitter:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="twitter:title" content="Website Design & Development Company In Kolkata, India" />
        <meta property="og:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:site" content="webapps software Solutions" />
        <meta name="twitter:creator" content="@webappsoftwares" />
        <meta name="theme-color" content="#0098DA" />
        <meta name="author" content="WASS Integrated Tech Enable It Solutions" />
        <meta name="google-site-verification" content="3qKB507bM-zleASA7ZJYxnvl9L30Wk2o4KA7dZksurY" />
      </Helmet>

      <div className="hero">
        <img src={heroBanner} alt="alt" />

        <div className="details">
          <h6>👋 HI THERE? I’ M MORPHY</h6>
          <h2>CREATIVE & MEDIA SOLUTIONS </h2>
          <p>
            We will implement a marketing strategy for your brand. If you would
            like to achieve more but you do not know how – we will define new
            directions of
          </p>
          <div className="">
            <Button bgColor="#fff" color="#800080" title="Schedule Call" />
          </div>
        </div>
      </div>

      <div className="years_of_exprence">
        <div className="title">
          <h3>Years of Experience</h3>

          <h1>Innovative Business Solutions for Financial Company</h1>

          <div className="paragraph">
            <p>
              Morem ipsum dolor sit amet, consectetur adipiscing elita florai
              psum dolor sit amet, consecteture. Borem ipsum dolor sitamo amet,
              consectetur adipiscing elita florai psum.
            </p>
            <p>
              {" "}
              Morem ipsum dolor sit amet, consectetur adipiscing elita florai
              psum dolor sit amet, consecteture. Borem ipsum dolor sitamo amet,
              consectetur adipiscing elita florai psum
            </p>

            <p>
              {" "}
              Gorem ipsum dolor sit amet, consectetur adipiscing elita florai
              psum dolor sit amet, consecteture.Borem.
            </p>
          </div>

          <div className="status">
            <div className="box">
              <img src={cup} alt="alt" />
              <div className="line"></div>
              <div className="box_item">
                <h2>235+</h2>
                <small>Best Award</small>
              </div>
            </div>

            <div className="box">
              <img src={star} alt="alt" />
              <div className="line"></div>
              <div className="box_item">
                <h2>98K</h2>
                <small>Happy Clients</small>
              </div>
            </div>
          </div>
        </div>
        <div className="images">
          <img className="smile_girl" src={smileGirl} alt="alt" />

          <img src={dots} alt="alt" className="dot_images" />

          <img src={fadeLine} alt="alt" className="fadeLine" />

          <img src={rate} alt="alt" className="rate_images" />
        </div>
      </div>

      <div className="the_worlds_most_powerfull">
        <div className="titlee">
          <div className="">
            <div className="service_title">
              <div className="line"></div>
              <h3>Service</h3>
            </div>

            <h1>The World's Most Powerfull Design Tool</h1>
          </div>

          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. At quia
            quis odio deserunt beatae expedita! Repellendus totam et aliquid
            eos?
          </p>

          <div className=""></div>
        </div>

        <div className="more_services">
          <div className="boxes">
            <div className="box_cards">
              <div className="title">
                <div className="round">
                  <div className="mini_round"></div>
                </div>
                <h4>Digital Marketing</h4>
              </div>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Temporibus nulla, velit molestias libero unde totam! Lorem ipsum
                dolor sit amet.
              </p>
            </div>

            <div className="box_cards">
              <div className="title">
                <div className="squer">
                  <div className="mini_round"></div>
                </div>
                <h4>Branding Design</h4>
              </div>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Temporibus nulla, velit molestias libero unde totam! Lorem ipsum
                dolor sit amet.
              </p>
            </div>
          </div>

          <div className="oval">
            <div className="round_ball">
              <h3>More Services</h3>
              <div className="overlay_border"></div>
            </div>
          </div>

          <div className="boxes">
            <div className="box_cards">
              <div className="title">
                <div className="triangle">
                  <div className="mini_round"></div>
                </div>
                <h4>Website Development</h4>
              </div>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Temporibus nulla, velit molestias libero unde totam! Lorem ipsum
                dolor sit amet.
              </p>
            </div>

            <div className="box_cards">
              <div className="title">
                <div className="squer">
                  <div className="mini_round"></div>
                </div>
                <h4>SEO Marketing</h4>
              </div>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Temporibus nulla, velit molestias libero unde totam! Lorem ipsum
                dolor sit amet.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="invative_business-solution">
        <div className="images">
          <img src={smileGirl2} className="smile_girl2" alt="alt" />
          <img src={dots} alt="alt" className="dots" />
          <img src={communication} alt="alt" className="comunication" />
          <div className="blaclBackground">
            <img src={blackBackground} alt="alt" />
          </div>
          <img src={fadeLine} alt="alt" className="oval_line" />
        </div>

        <div className="content">
          <h6>Years of Experience</h6>

          <h1>Innovative Business Solutions for Financial Company</h1>

          <p>
            Morem ipsum dolor sit amet, consectetur adipiscing elita florai psum
            dolor sit amet, consecteture. Borem ipsum dolor sitamo amet,
            consectetur adipiscing elita florai psum.
          </p>

          <ul>
            <li>
              <img src={arrow} alt="alt" />
              What is WASS community ?
            </li>
            <li>
              <img src={arrow} alt="alt" />
              What is WASS community ?
            </li>

            <li>
              <img src={arrow} alt="alt" />
              What is WASS community ?
            </li>
            <li>
              <img src={arrow} alt="alt" />
              What is WASS community ?
            </li>
            <li>
              <img src={arrow} alt="alt" />
              What is WASS community ?
            </li>
          </ul>

          <p>
            Morem ipsum dolor sit amet, consectetur adipiscing elita florai psum
            dolor sit amet, consecteture.
          </p>

          <div className="user_content">
            <img src={user} className="user" alt="alt" />
            <div className="">
              <h5>Mark Stranger</h5>
              <small>CEO, Webapps Software Solutions</small>
            </div>
            <img src={signature} className="user" alt="alt" />
          </div>

          <img src={squre} className="squre" alt="alt" />
        </div>
      </div>

      <div className="yet_effect">
        <div className="title">
          <div className="heading">
            <h4>
              <div className="line"></div>
              Workflow
            </h4>
            <h1>A Simple Yet Effective Three Steps Process.</h1>
          </div>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas
            asperiores dicta, cumque neque id veritatis.
          </p>
        </div>

        <div className="yet_cards">
          <div className="card_line">
            <div className="fade_line">
              <div className="line_balls1">
                <div className="f_line"></div>
              </div>
              <div className="line_balls2">
                <div className="f_line"></div>
              </div>
              <div className="line_balls3">
                <div className="f_line"></div>
              </div>
              <div className="line_balls4">
                <div className="f_line"></div>
              </div>
            </div>
          </div>
          <div className="card_boxes">
            <div className="box">
              <h3 className="box_title">
                Simple Creative Design
                <div className="number">
                  <h3>01</h3>
                </div>
              </h3>
              <p className="description">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Repellat odio nihil quibusdam ut aperiam praesentium?
              </p>
            </div>

            <div className="box">
              <h3 className="box_title">
                Understanding Client's
                <div className="number">
                  <h3>02</h3>
                </div>
              </h3>
              <p className="description">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Repellat odio nihil quibusdam ut aperiam praesentium?
              </p>
            </div>

            <div className="box">
              <h3 className="box_title">
                Creating A Project Plan
                <div className="number">
                  <h3>03</h3>
                </div>
              </h3>
              <p className="description">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Repellat odio nihil quibusdam ut aperiam praesentium?
              </p>
            </div>

            <div className="box">
              <h3 className="box_title">
                Launching The Project
                <div className="number">
                  <h3>04</h3>
                </div>
              </h3>
              <p className="description">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Repellat odio nihil quibusdam ut aperiam praesentium?
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="enquery_details">
        <div className="title">
          <h1>Innovative Business Solutions for Financial Company</h1>
          <p>
            Morem ipsum dolor sit amet, consectetur adipiscing elita florai psum
            dolor sit amet, consecteture. Borem ipsum dolor sitamo amet,
            consectetur adipiscing elita florai psum.
          </p>
        </div>

        <div className="enquery_box">
          <EnqueryDetails />
        </div>
      </div>

      <div className="service_pricing">
        <div className="heading">
          <h1>Flexible Pricing.</h1>
          <p className="flexible_details">
            Flexible and affording plans tailored to your needs. Save up to %20
            for a limited time.
          </p>
        </div>
        <Cards />
      </div>

      <div className="request_call">
        <div className="call">
          <i className="bx bx-phone-call"></i>
          <div className="">
            <small>Call For More info</small>
            <h2>+91 97635 00291</h2>
          </div>
        </div>

        <div className="line"></div>

        <h2 className="title">
          Let,s Request a Schedule For <br /> Free Consultation
        </h2>

        <div className="line"></div>

        <a target="_blank" href="https://wa.link/9sbw44">
          <button>

            Contect US
            <i className="bx bx-chevron-right"></i>
          </button>

        </a>
      </div>

      {/* ========= WHAT WE ARE DOING ========== */}

      <div className="what_we_doing">
        <div className="what_we_are_doing">
          <div className="img">
            <img src={dots} alt="alt" className="dots" />
            <img src={thinkingGirl} alt="alt" />
          </div>

          <div className="text">
            <div className="" style={{ display: "flex", alignItems: "center", gap: '10px' }}>
              <span className="line"></span>
              <h5>WHAT WE ARE DOING</h5>
            </div>

            <h1>Changing The Way To Do Best Business Solutions</h1>

            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores
              est soluta mollitia sit dolorum, iste suscipit ex libero quae
              doloribus ea voluptate iure quibusdam repudiandae laboriosam
              molestias accusamus facilis eligendi?
            </p>
          </div>
        </div>

        <div className="cards">
          <div className="card">
            <div className="image">
              <img src={card1} alt="alt" />
              <h4>Quality Services</h4>
            </div>

            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Deserunt, nesciunt? Laudantium deserunt voluptatem ipsum totam.
            </p>
          </div>

          <div className="card">
            <div className="image">
              <img src={card2} alt="alt" />
              <h4>Innovation Ideas</h4>
            </div>

            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Deserunt, nesciunt? Laudantium deserunt voluptatem ipsum totam.
            </p>
          </div>

          <div className="card">
            <div className="image">
              <img src={card3} alt="alt" />
              <h4>Business Growth</h4>
            </div>

            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Deserunt, nesciunt? Laudantium deserunt voluptatem ipsum totam.
            </p>
          </div>
        </div>
      </div>

      {/* ====== FAQ ====== */}

      <div className="">
        <BusinessFaq />
      </div>
    </div>
  );
};

export default ServicePage3;
