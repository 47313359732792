import React from "react";
import Button from "../../components/Button/Button";
import flower from "../../assets/img/ProfileDetails/flower.png";
import honey from "../../assets/img/ProfileDetails/honey.png";
import quality from "../../assets/img/ProfileDetails/quality.png";
import gate from "../../assets/img/Places/gate_of_india.png";
import role1 from "../../assets/img/ProfileDetails/role1.png";
import role2 from "../../assets/img/ProfileDetails/role2.png";
import role3 from "../../assets/img/ProfileDetails/role3.png";
import role4 from "../../assets/img/ProfileDetails/role4.png";
import appStore from "../../assets/img/DownloadApp/appstore.png";
import googlepay from "../../assets/img/DownloadApp/googlepay.png";
import website from "../../assets/img/DownloadApp/website.png";
import phone from "../../assets/img/ProfileDetails/phone.png";
import iphone1 from "../../assets/img/ProfileDetails/iphone1.png";
import iphone2 from "../../assets/img/ProfileDetails/iphone2.png";
import PartnershipMarqee from "../../components/Marqee/PartnershipMarqee";
import "./PortfolioDetailPage.css";
import EnqueryDetails from "../../components/EnqueryDetails/EnqueryDetails";
import { Helmet } from "react-helmet-async";
const PortfolioDetailPage = () => {
  return (
    <div id="portfolio_detail_page">
      <Helmet>
        <title>Portfolio Details | Webapps Software Solutions </title>
        <meta name="copyright" content="https://webappssoft.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webappssoft.com/" />
        <link rel="next" href="https://webappssoft.com/" />
        <meta
          property="og:title"
          content="Property Dealers in Jamshedpur | Property Dealers Near Me"
        />
        <meta property="og:url" content="https://webappssoft.com/" />
        <meta
          property="og:image"
          content="https://webappssoft.com/logo/sy-logo.jpg"
        />
        <meta
          property="og:description"
          content="Property Dealers in Jamshedpur: Find 150+ Reliable real estate agents and Brokers in Jamshedpur. Contact residential and Commercial property consultants online."
        />
        <meta
          name="description"
          content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata"
        />
        <meta
          name="keywords"
          content="website design company India,web designing India, best web design company,top web design company,top web design companies India, web design and development company in india"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata"
        />
        <meta property="og:site_name" content="Webapps Software Solutions" />
        <meta
          name="twitter:description"
          content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata"
        />
        <meta
          name="twitter:title"
          content="Website Design & Development Company In Kolkata, India"
        />
        <meta
          property="og:image"
          content="https://webappssoft.com/assets/images/logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://webappssoft.com/assets/images/logo.png"
        />
        <meta name="twitter:site" content="webapps software Solutions" />
        <meta name="twitter:creator" content="@webappsoftwares" />
        <meta name="theme-color" content="#0098DA" />
        <meta
          name="author"
          content="WASS Integrated Tech Enable It Solutions"
        />
        <meta
          name="google-site-verification"
          content="3qKB507bM-zleASA7ZJYxnvl9L30Wk2o4KA7dZksurY"
        />
      </Helmet>
      {/* ======= HERO ========== */}

      <div className="hero">
        <div className="hero_item1">
          <img src={flower} alt="img" loading="lazy" className="flower_image" />

          <img src={honey} alt="img" loading="lazy" className="hunney_image" />
        </div>

        <div className="hero_item2">
          <img
            src={quality}
            alt="img"
            loading="lazy"
            className="quality_image"
          />

          <h1>Our Young Minds</h1>

          <p>
            Experience the Thrill of Fantasy Sports with an Application That
            Offers It All Experience the Thrill of Fantasy Sports with an
            Application That Offers It All! Experience the Thrill of Fantasy
            Sports with an Application That Offers It All{" "}
          </p>

          <div className="btnnn">
            <Button bgColor="#fff" color="#000" title="Schedule Call" />
          </div>
        </div>
      </div>

      {/* ======= ABOUT ========== */}

      <div className="about">
        <div className="relative_places">
          <div className="items">
            <img src={gate} alt="img" loading="lazy" />
            <div className="items_title">
              <h4>Business Type</h4>
              <small>Product & Manufacturing</small>
            </div>
          </div>

          <div className="items">
            <img src={gate} alt="img" loading="lazy" />
            <div className="items_title">
              <h4>Business Type</h4>
              <small>Product & Manufacturing</small>
            </div>
          </div>

          <div className="items">
            <img src={gate} alt="img" loading="lazy" />
            <div className="items_title">
              <h4>Business Type</h4>
              <small>Product & Manufacturing</small>
            </div>
          </div>

          <div className="items">
            <img src={gate} alt="img" loading="lazy" />
            <div className="items_title">
              <h4>Business Type</h4>
              <small>Product & Manufacturing</small>
            </div>
          </div>
        </div>

        <div className="about_company">
          <div className="title">
            <ul>
              <li>
                <h3>About ZEE 5</h3>
                <p>
                  Built for ‌entertainment and media enthusiasts, Zee5
                  application offers users a user-friendly platform to enjoy
                  video content seamlessly. The video streaming platform offers
                  TV shows, movies, and original web series in 11 Indian and a
                  few international languages.
                </p>
              </li>

              <li>
                <h3>Binge-Watching Shows from One of the Largest</h3>
                <p>
                  Managing the massive traffic of 600 million global users
                  weekly isn’t easy. One needs a robust tech stack to work with
                  it. Octal IT Solution helped the client with a reliable tech
                  stack, making managing heavy traffic easy.Managing the massive
                  traffic of 600 million global users weekly isn’t easy. One
                  needs a robust tech stack to work with it. Octal IT Solution
                  helped the client with a reliable tech stack, making managing
                  heavy traffic easy.
                </p>
              </li>

              <li>
                <h3>Major Challenge</h3>
                <p>
                  In the competitive market, it is difficult for the application
                  to compete with the established players. To survive the
                  competition, they needed to offer users a unique experience on
                  the application to retain them.
                </p>
              </li>
            </ul>
          </div>

          <div className="role">
            <h3>Our Role</h3>

            <div className="role_box">
              <div className="box">
                <img src={role1} alt="img" loading="lazy" />

                <div className="titlle">
                  <h1>1</h1>
                  <strong>UI/UX Deigner</strong>
                </div>
              </div>

              <div className="box">
                <img src={role2} alt="img" loading="lazy" />

                <div className="titlle">
                  <h1>2</h1>
                  <strong>Application Deigner</strong>
                </div>
              </div>

              <div className="box">
                <img src={role3} alt="img" loading="lazy" />

                <div className="titlle">
                  <h1>3</h1>
                  <strong>IOS Deigner</strong>
                </div>
              </div>

              <div className="box">
                <img src={role4} alt="img" loading="lazy" />

                <div className="titlle">
                  <h1>4</h1>
                  <strong>Graphics Deigner</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ========== What Lies Ahead ========= */}

      <div className="what_lies">
        <div className="title">
          <h1>What Lies Ahead</h1>
          <p>
            The project is live and available for users on Google Play Store and
            Apple App Store to download. It supports the latest versions of
            Android and iOS platforms and is free to use.
          </p>
        </div>

        <div className="download_app">
          <a href="#">
            <img src={googlepay} alt="img" loading="lazy" />
          </a>

          <a href="#">
            <img src={appStore} alt="img" loading="lazy" />
          </a>

          <a href="#">
            <img src={website} alt="img" loading="lazy" />
          </a>
        </div>
      </div>

      {/* ====== Tech Solutions ====== */}

      {/* ========= ENQUERY DETAILS ======== */}

      <div className="enquery_details">
        <EnqueryDetails />
      </div>

      {/* ========= PHONE DETAILS ======== */}

      <div className="phone_section">
        <div className="phone">
          <img src={phone} alt="img" loading="lazy" />
        </div>

        <div className="phone phone2">
          <img src={iphone2} alt="img" loading="lazy" />
          <img src={iphone1} alt="img" loading="lazy" />
        </div>
      </div>

      {/* ========= MARQEE ======== */}

      <div className="">
        <PartnershipMarqee />
      </div>
    </div>
  );
};

export default PortfolioDetailPage;
