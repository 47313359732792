import React from "react";
import LargeMarqee from "../../components/Marqee/LargeMarqee";
import PartnershipMarqee from '../../components/Marqee/PartnershipMarqee'
import { Helmet } from "react-helmet-async";
import NewsScroll from "../../components/NewsSlider/NewsSlider";
import EnqueryDetails from '../../components/EnqueryDetails/EnqueryDetails';
import rightArrow from '../../assets/img/MobilePortfolio/arrow-right.webp'


// app Screen Shot

import altair_logo from '../../assets/img/MobilePortfolio/Altair/log0.jpg';
import altair_item1 from '../../assets/img/MobilePortfolio/Altair/at_001.jpg'
import altair_item2 from '../../assets/img/MobilePortfolio/Altair/at_002.jpg'
import altair_item3 from '../../assets/img/MobilePortfolio/Altair/at_003.jpg'

import anuradha_logo from '../../assets/img/MobilePortfolio/Anuradha Art/log0.jpg';
import anuradha_001 from '../../assets/img/MobilePortfolio/Anuradha Art/aa_001.jpg';
import anuradha_002 from '../../assets/img/MobilePortfolio/Anuradha Art/aa_002.jpg';
import anuradha_003 from '../../assets/img/MobilePortfolio/Anuradha Art/aa_003.jpg';


import choicest_logo from '../../assets/img/MobilePortfolio/Choicest/log0.jpg';
import choiest_001 from '../../assets/img/MobilePortfolio/Choicest/c_001.jpg';
import choiest_002 from '../../assets/img/MobilePortfolio/Choicest/c_002.jpg';
import choiest_003 from '../../assets/img/MobilePortfolio/Choicest/c_003.jpg';

import viyay_logo from '../../assets/img/MobilePortfolio/D.Vijay Pharma/ll01.jpg';
import viyay_001 from '../../assets/img/MobilePortfolio/D.Vijay Pharma/ss_001.jpg';
import viyay_002 from '../../assets/img/MobilePortfolio/D.Vijay Pharma/ss_002.jpg';
import viyay_003 from '../../assets/img/MobilePortfolio/D.Vijay Pharma/ss_003.jpg';

import delco_logo from '../../assets/img/MobilePortfolio/Delco/log0.jpg';
import delco_001 from '../../assets/img/MobilePortfolio/Delco/gd_001.jpg';
import delco_002 from '../../assets/img/MobilePortfolio/Delco/gd_002.jpg';
import delco_003 from '../../assets/img/MobilePortfolio/Delco/gb_003.jpg';

import digo_logo from '../../assets/img/MobilePortfolio/Digo360/log0.jpg'
import digo_001 from '../../assets/img/MobilePortfolio/Digo360/dg_001.jpg'
import digo_002 from '../../assets/img/MobilePortfolio/Digo360/dg_002.jpg'
import digo_003 from '../../assets/img/MobilePortfolio/Digo360/dg_003.jpg';

import dmps_logo from '../../assets/img/MobilePortfolio/Dmps/log0.jpg';
import dmps_001 from '../../assets/img/MobilePortfolio/Dmps/dmps_001.jpg';
import dmps_002 from '../../assets/img/MobilePortfolio/Dmps/dmps_002.jpg';
import dmps_003 from '../../assets/img/MobilePortfolio/Dmps/dmps_003.jpg';

import guru_logo from '../../assets/img/MobilePortfolio/GURU888_LIVE/logo.jpg'
import guru_001 from '../../assets/img/MobilePortfolio/GURU888_LIVE/gl_001.png'
import guru_002 from '../../assets/img/MobilePortfolio/GURU888_LIVE/gl_002.jpg'
import guru_003 from '../../assets/img/MobilePortfolio/GURU888_LIVE/gl_003.png';

import rajBhandar_logo from '../../assets/img/MobilePortfolio/Raj Bhandar/rb_logo.jpg';
import rajBhandar_001 from '../../assets/img/MobilePortfolio/Raj Bhandar/rb_001.jpg';
import rajBhandar_002 from '../../assets/img/MobilePortfolio/Raj Bhandar/rb_002.jpg';
import rajBhandar_003 from '../../assets/img/MobilePortfolio/Raj Bhandar/rb_003.jpg';



// -------- CSS
import "./MobilePortfolio.css";
import { Link } from "react-router-dom";

const MobilePortfolio = () => {
  return (
    <div id="mobile_portfolio">

      <Helmet>
        <title>Mobile Portfolio | Webapps Software Solutions </title>
        <meta name="copyright" content="https://webappssoft.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webappssoft.com/" />
        <link rel="next" href="https://webappssoft.com/" />
        <meta property="og:title" content="Property Dealers in Jamshedpur | Property Dealers Near Me" />
        <meta property="og:url" content="https://webappssoft.com/" />
        <meta property="og:image" content="https://webappssoft.com/logo/sy-logo.jpg" />
        <meta property="og:description" content="Property Dealers in Jamshedpur: Find 150+ Reliable real estate agents and Brokers in Jamshedpur. Contact residential and Commercial property consultants online." />
        <meta name="description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="keywords" content="website design company India,web designing India, best web design company,top web design company,top web design companies India, web design and development company in india" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta property="og:site_name" content="Webapps Software Solutions" />
        <meta name="twitter:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="twitter:title" content="Website Design & Development Company In Kolkata, India" />
        <meta property="og:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:site" content="webapps software Solutions" />
        <meta name="twitter:creator" content="@webappsoftwares" />
        <meta name="theme-color" content="#0098DA" />
        <meta name="author" content="WASS Integrated Tech Enable It Solutions" />
        <meta name="google-site-verification" content="3qKB507bM-zleASA7ZJYxnvl9L30Wk2o4KA7dZksurY" />
      </Helmet>

      <div className="n-9">
        <LargeMarqee />
      </div>

      <div className="">

        <div className="container_box1">


          <div className='streaming_box-container box-1'>

            <div className="header-portfolio">
              <div className="">
                <h1>Altair</h1>
                <p>Membership</p>
              </div>
              <img width="80px" src={altair_logo} alt="img" />
            </div>

            <div className="portfolio-item">

              <div className="item item1">
                <div className="image-item">
                  <img src={altair_item2} alt="" />
                </div>
              </div>

              <div className="item2 hover-2">
                <div className="image-item">
                  <img src={altair_item1} alt="" />
                </div>
              </div>

              <div className="item item3">
                <div className="image-item">
                  <img src={altair_item3} alt="" />
                </div>
              </div>
            </div>

            <div className="portfoloio-btn">
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.AltAir.celmembercard">
                <button>View Case Study <img src={rightArrow} alt="img" /></button>
              </a>
            </div>

          </div>

          <div className='streaming_box-container box-2'>

            <div className="header-portfolio">
              <div className="">
                <h1>Anuradha Art</h1>
                <p>Jewelry</p>
              </div>

              <img width="80px" src={anuradha_logo} alt="img" />
            </div>

            <div className="portfolio-item">
              <div className="item item1">
                <div className="image-item">
                  <img src={anuradha_002} alt="" />
                </div>
              </div>

              <div className="item2">
                <div className="image-item">
                  <img src={anuradha_001} alt="" />
                </div>
              </div>

              <div className="item item3">
                <div className="image-item">
                  <img src={anuradha_003} alt="" />
                </div>
              </div>
            </div>

            <div className="portfoloio-btn">
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.aaj.anuradhaartjewellery">
                <button>View Case Study <img src={rightArrow} alt="img" /></button>
              </a>
            </div>

          </div>

          <div className='streaming_box-container box-3'>

            <div className="header-portfolio">
              <div className="">
                <h1>Choicest</h1>
                <p>Video Streaming</p>
              </div>

              <img width="80px" src={choicest_logo} alt="img" />
            </div>

            <div className="portfolio-item">
              <div className="item item1">
                <div className="image-item">
                  <img src={choiest_002} alt="" />
                </div>
              </div>

              <div className="item2">
                <div className="image-item">
                  <img src={choiest_001} alt="" />
                </div>
              </div>

              <div className="item item3">
                <div className="image-item">
                  <img src={choiest_003} alt="" />
                </div>
              </div>
            </div>

            <div className="portfoloio-btn">
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.choicest.kitchen">
                <button>View Case Study <img src={rightArrow} alt="img" /></button>
              </a>
            </div>

          </div>

          <div className='streaming_box-container box-4'>

            <div className="header-portfolio">
              <div className="">
                <h1>D.Vijay Pharma</h1>
                <p>Helth Care</p>
              </div>

              <img width="80px" src={viyay_logo} alt="img" />
            </div>

            <div className="portfolio-item">
              <div className="item item1">
                <div className="image-item">
                  <img src={viyay_002} alt="" />
                </div>
              </div>

              <div className="item2">
                <div className="image-item">
                  <img src={viyay_001} alt="" />
                </div>
              </div>

              <div className="item item3">
                <div className="image-item">
                  <img src={viyay_003} alt="" />
                </div>
              </div>
            </div>

            <div className="portfoloio-btn">
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.dvijaypharma.nashik">
                <button>View Case Study <img src={rightArrow} alt="img" /></button>
              </a>
            </div>

          </div>

          <div className='streaming_box-container box-1'>

            <div className="header-portfolio">
              <div className="">
                <h1>Delco</h1>
                <p>Grocery & food delivery</p>
              </div>

              <img width="80px" src={delco_logo} alt="img" />
            </div>

            <div className="portfolio-item">

              <div className="item item1">
                <div className="image-item">
                  <img src={delco_002} alt="" />
                </div>
              </div>

              <div className="item2 hover-2">
                <div className="image-item">
                  <img src={delco_001} alt="" />
                </div>
              </div>

              <div className="item item3">
                <div className="image-item">
                  <img src={delco_003} alt="" />
                </div>
              </div>
            </div>

            <div className="portfoloio-btn">
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.cosiigroup.delco">
                <button>View Case Study <img src={rightArrow} alt="img" /></button>
              </a>
            </div>

          </div>

          <div className='streaming_box-container box-2'>

            <div className="header-portfolio">
              <div className="">
                <h1>Digo 360</h1>
                <p>Helth Care</p>
              </div>

              <img width="80px" src={digo_logo} alt="img" />
            </div>

            <div className="portfolio-item">
              <div className="item item1">
                <div className="image-item">
                  <img src={digo_002} alt="" />
                </div>
              </div>

              <div className="item2">
                <div className="image-item">
                  <img src={digo_001} alt="" />
                </div>
              </div>

              <div className="item item3">
                <div className="image-item">
                  <img src={digo_003} alt="" />
                </div>
              </div>
            </div>

            <div className="portfoloio-btn">
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.wapss.digo360">
                <button>View Case Study <img src={rightArrow} alt="img" /></button>
              </a>
            </div>

          </div>

          <div className='streaming_box-container box-3'>

            <div className="header-portfolio">
              <div className="">
                <h1>DMPS</h1>
                <p>Education</p>
              </div>

              <img width="80px" src={dmps_logo} alt="img" />
            </div>

            <div className="portfolio-item">
              <div className="item item1">
                <div className="image-item">
                  <img src={dmps_002} alt="" />
                </div>
              </div>

              <div className="item2">
                <div className="image-item">
                  <img src={dmps_001} alt="" />
                </div>
              </div>

              <div className="item item3">
                <div className="image-item">
                  <img src={dmps_003} alt="" />
                </div>
              </div>
            </div>

            <div className="portfoloio-btn">
              <a target="_blank" href="https://play.google.com/store/apps/details?id=wass.rssoftware.dmps">
                <button>View Case Study <img src={rightArrow} alt="img" /></button>
              </a>
            </div>

          </div>

          <div className='streaming_box-container box-4'>

            <div className="header-portfolio">
              <div className="">
                <h1>GURU 888</h1>
                <p>Fantasy</p>
              </div>

              <img width="80px" src={guru_logo} alt="img" />
            </div>

            <div className="portfolio-item">
              <div className="item item1">
                <div className="image-item">
                  <img src={guru_002} alt="" />
                </div>
              </div>

              <div className="item2">
                <div className="image-item">
                  <img src={guru_001} alt="" />
                </div>
              </div>

              <div className="item item3">
                <div className="image-item">
                  <img src={guru_003} alt="" />
                </div>
              </div>
            </div>

            <div className="portfoloio-btn">
              <a href="https://play.google.com/store/apps/details?id=com.guru.lives" target="_blank">
                <button>View Case Study <img src={rightArrow} alt="img" /></button>
              </a>
            </div>

          </div>

          <div className='streaming_box-container box-1'>

            <div className="header-portfolio">
              <div className="">
                <h1>Raj Bhandar</h1>
                <p>Grocery</p>
              </div>

              <img width="80px" src={rajBhandar_logo} alt="img" />
            </div>

            <div className="portfolio-item">

              <div className="item item1">
                <div className="image-item">
                  <img src={rajBhandar_002} alt="" />
                </div>
              </div>

              <div className="item2 hover-2">
                <div className="image-item">
                  <img src={rajBhandar_001} alt="" />
                </div>
              </div>

              <div className="item item3">
                <div className="image-item">
                  <img src={rajBhandar_003} alt="" />
                </div>
              </div>
            </div>

            <div className="portfoloio-btn">
              <a href="https://play.google.com/store/apps/details?id=com.rajbhandar.shop" target="_blank">
                <button>View Case Study <img src={rightArrow} alt="img" /></button>
              </a>
            </div>

          </div>




        </div>

        <div className="middle">

          <h1>Lets Discuss Your Project</h1>

          <p>Let us know your project idea and Get free consultation to turn it into an amazing</p>

          <a target="_blank" href="https://wa.link/9sbw44">
            <button>Talk to our expert</button>
          </a>

        </div>

      </div>

      <div className="mews">

        <h1>On the Path to Success: Partnering with the Big Names That Are Ahead of the Curve</h1>

        <NewsScroll />
      </div>

      {/* <div className="portfoloio_form">

        <h1>
          Let’s build something great together!
        </h1>
        <p>
          Connect with us and discover new possibilities. 🤝
        </p>

        <EnqueryDetails />
      </div> */}

      <div className="partner-ship">

        <h1>Our Most Trustworthy Clients</h1>

        <PartnershipMarqee />
      </div>
    </div>
  );
};

export default MobilePortfolio;
