import React from "react";
import PartnershipMarqee from "../../components/Marqee/PartnershipMarqee";
import LargeMarqee from "../../components/Marqee/LargeMarqee";
import logoPortfolio from "../../assets/img/LogoPortfolio/logoPortfolio.png";

import "./LogoPortfolio.css";
import { Helmet } from "react-helmet-async";

const LogoPortfolio = () => {
  return (
    <div id="logo_portfolio">
      <Helmet>
      <title>Logo Portfolio | Webapps Software Solutions </title>
        <meta name="copyright" content="https://webappssoft.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webappssoft.com/" />
        <link rel="next" href="https://webappssoft.com/" />
        <meta property="og:title" content="Property Dealers in Jamshedpur | Property Dealers Near Me" />
        <meta property="og:url" content="https://webappssoft.com/" />
        <meta property="og:image" content="https://webappssoft.com/logo/sy-logo.jpg" />
        <meta property="og:description" content="Property Dealers in Jamshedpur: Find 150+ Reliable real estate agents and Brokers in Jamshedpur. Contact residential and Commercial property consultants online." />
        <meta name="description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="keywords" content="website design company India,web designing India, best web design company,top web design company,top web design companies India, web design and development company in india" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta property="og:site_name" content="Webapps Software Solutions" />
        <meta name="twitter:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="twitter:title" content="Website Design & Development Company In Kolkata, India" />
        <meta property="og:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:site" content="webapps software Solutions" />
        <meta name="twitter:creator" content="@webappsoftwares" />
        <meta name="theme-color" content="#0098DA" />
        <meta name="author" content="WASS Integrated Tech Enable It Solutions" />
        <meta name="google-site-verification" content="3qKB507bM-zleASA7ZJYxnvl9L30Wk2o4KA7dZksurY" />
      </Helmet>
      <div className="marqee">
        <LargeMarqee />
      </div>

      <div className="most_recent">
        <div className="title">
          <h1>MOST RECENT</h1>
          <h3>DESIGN & LOGO</h3>
        </div>

        <div className="logo_boxes">
          <div className="items">
            <img src={logoPortfolio} alt="" />
          </div>

          <div className="items">
            <img src={logoPortfolio} alt="" />
          </div>

          <div className="items">
            <img src={logoPortfolio} alt="" />
          </div>

          <div className="items">
            <img src={logoPortfolio} alt="" />
          </div>

          <div className="items">
            <img src={logoPortfolio} alt="" />
          </div>

          <div className="items">
            <img src={logoPortfolio} alt="" />
          </div>

          <div className="items">
            <img src={logoPortfolio} alt="" />
          </div>

          <div className="items">
            <img src={logoPortfolio} alt="" />
          </div>
        </div>
      </div>

      <div className="">
        <PartnershipMarqee />
      </div>

      <div className="insta_box">
        <div className="insta_cards">
            <img src="https://t4.ftcdn.net/jpg/06/45/98/65/360_F_645986509_0jLH4J9elWP0JR38APN46cxeKPH8uM2T.jpg" alt="" />
        </div>

        <div className="insta_cards">
            <img src="https://t4.ftcdn.net/jpg/06/45/98/65/360_F_645986509_0jLH4J9elWP0JR38APN46cxeKPH8uM2T.jpg" alt="" />
        </div>

        <div className="insta_cards">
            <img src="https://t4.ftcdn.net/jpg/06/45/98/65/360_F_645986509_0jLH4J9elWP0JR38APN46cxeKPH8uM2T.jpg" alt="" />
        </div>

        <div className="insta_cards">
            <img src="https://t4.ftcdn.net/jpg/06/45/98/65/360_F_645986509_0jLH4J9elWP0JR38APN46cxeKPH8uM2T.jpg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default LogoPortfolio;
