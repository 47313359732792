import React, { useState } from "react";
import { Helmet } from "react-helmet-async";

import anish from "../../assets/img/TeamPage/anish.png";
import uttamPatra from "../../assets/img/TeamPage/uttampatra1.jpeg";
import swadhin from "../../assets/img/TeamPage/swadhin.PNG";
import paban from "../../assets/img/TeamPage/Paban_Mahato.jpg";
import syandip from "../../assets/img/TeamPage/syandip.jpeg";
import manas from "../../assets/img/TeamPage/manas.jpeg";
import Abhijeet from "../../assets/img/TeamPage/abhijeet.jpeg";
import Mrittika from "../../assets/img/TeamPage/mritika.jpeg";
import sourav from "../../assets/img/TeamPage/sourav.jpeg";

import "./TeamPage.css";

export const TeamCard = ({
  modal,
  setModal,
  name,
  skill,
  des,
  button,
  image,
  bio,
}) => {
  return (
    <div
      className="profile_hilight"
      style={{ display: modal === true ? "flex" : "none" }}
    >
      <div className="view">
        <i className="cross bx bx-x" onClick={() => setModal(false)}></i>

        <div className="details">
          <h4>{bio}</h4>
          <h2>{name}</h2>
          <p className="skills">{skill}</p>
          <p>{des}</p>
          <button>
            {button} <span>&#x2192;</span>
          </button>
        </div>

        <div className="imgg">
          <img src={image} alt="img" />
        </div>
      </div>
    </div>
  );
};

const TeamPage = () => {
  const team = [
    {
      id: 1,
      name: "Anish Kumar",
      skill: "Sr. Full Stack Developer",
      bio: "Sr. Full Stack Developer",
      image: anish,
      button: "Hire Full Stack Developer",
      des: "WebApps Software Solution We propose a unique model of ‘Communication Co-creation.",
    },

    {
      id: 3,
      name: "Paban Mahato",
      skill: "Sr. FullStack Developer",
      bio: "Sr. FullStack Developer",
      image: paban,
      button: "Hire FullStack Developer",
      des: "WebApps Software Solution We propose a unique model of ‘Communication Co-creation.",
    },

    {
      id: 4,
      name: "Shayandip Chitrakar",
      skill: "Sr. Backend Developer",
      bio: "Highly Dedicated Backend Developer",
      image: syandip,
      button: "Hire Backend Developer",
      des: "WebApps Software Solution We propose a unique model of ‘Communication Co-creation.",
    },

    {
      id: 5,
      name: "Abhijeet Das",
      skill: "Sr. FullStack Developer",
      bio: "Sr. FullStack Developer",
      image: Abhijeet,
      button: "Hire FullStack Developer",
      des: "WebApps Software Solution We propose a unique model of ‘Communication Co-creation.",
    },

    // ---------------------------- SR

    {
      id: 10,
      name: "Manas Mourya",
      skill: "Sr. Flutter Developer",
      bio: "Sr. Flutter Developer",
      image: manas,
      button: "Hire Sr. Flutter Developer",
      des: "WebApps Software Solution We propose a unique model of ‘Communication Co-creation.",
    },

    {
      id: 132,
      name: "Sourav Yadav",
      skill: "Sr. Android Developer",
      bio: "Android Developer",
      image: sourav,
      button: "Hire Android Developer",
      des: "WebApps Software Solution We propose a unique model of ‘Communication Co-creation.",
    },

    {
      id: 9,
      name: "Swadhin Dhara",
      skill: "React Developer",
      bio: "React Developer",
      image: swadhin,
      button: "Hire React Developer",
      des: "WebApps Software Solution We propose a unique model of ‘Communication Co-creation.",
    },

    {
      id: 11,
      name: "Uttam Patra",
      skill: "Front End Developer",
      bio: "Front End Developer",
      image: uttamPatra,
      button: "Hire React Developer",
      des: "WebApps Software Solution We propose a unique model of ‘Communication Co-creation.",
    },

    {
      id: 12,
      name: "Mrittika Fadikar",
      skill: "Technical Content Writer",
      bio: "Technical Content Writer",
      image: Mrittika,
      button: "Technical Content Writer",
      des: "WebApps Software Solution We propose a unique model of ‘Communication Co-creation.",
    },
  ];

  const [modal, setModal] = useState(false);
  const [name, setName] = useState();
  const [skill, setSkill] = useState();
  const [des, setDes] = useState();
  const [button, setButton] = useState();
  const [bio, setBio] = useState();
  const [image, setImage] = useState();

  const showModal = (img, name, skills, button, bio, des) => {
    setModal(true);
    setImage(img);
    setName(name);
    setSkill(skills);
    setButton(button);
    setBio(bio);
    setDes(des);
  };

  return (
    <div id="team_page">
      <Helmet>
        <title>Team | Webapps Software Solutions </title>
        <meta name="copyright" content="https://webappssoft.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webappssoft.com/" />
        <link rel="next" href="https://webappssoft.com/" />
        <meta
          property="og:title"
          content="Property Dealers in Jamshedpur | Property Dealers Near Me"
        />
        <meta property="og:url" content="https://webappssoft.com/" />
        <meta
          property="og:image"
          content="https://webappssoft.com/logo/sy-logo.jpg"
        />
        <meta
          property="og:description"
          content="Property Dealers in Jamshedpur: Find 150+ Reliable real estate agents and Brokers in Jamshedpur. Contact residential and Commercial property consultants online."
        />
        <meta
          name="description"
          content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata"
        />
        <meta
          name="keywords"
          content="website design company India,web designing India, best web design company,top web design company,top web design companies India, web design and development company in india"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata"
        />
        <meta property="og:site_name" content="Webapps Software Solutions" />
        <meta
          name="twitter:description"
          content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata"
        />
        <meta
          name="twitter:title"
          content="Website Design & Development Company In Kolkata, India"
        />
        <meta
          property="og:image"
          content="https://webappssoft.com/assets/images/logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://webappssoft.com/assets/images/logo.png"
        />
        <meta name="twitter:site" content="webapps software Solutions" />
        <meta name="twitter:creator" content="@webappsoftwares" />
        <meta name="theme-color" content="#0098DA" />
        <meta
          name="author"
          content="WASS Integrated Tech Enable It Solutions"
        />
        <meta
          name="google-site-verification"
          content="3qKB507bM-zleASA7ZJYxnvl9L30Wk2o4KA7dZksurY"
        />
      </Helmet>

      {/* 

      <div className="hero">
        <img src={teams} alt="img" />
        <div className="overlay">
          <div className="containerr">
            <div className="young_minds">
              <h1>Our Young Minds</h1>
              <p>
                Experience the Thrill of Fantasy Sports with an Application That
                Offers It All!
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="teams_profiles">
        {team.map((x) => {
          return (
            <div key={x.id} className="profile">
              <img className="profileImage" src={x.image} alt="img" />
              <div
                className="name-details"
                onClick={() =>
                  showModal(x.image, x.name, x.skill, x.button, x.bio, x.des)
                }
              >
                <h4>{x.name}</h4>
                <p>{x.skill}</p>
              </div>
            </div>
          );
        })}
      </div>

      {
        <TeamCard
          modal={modal}
          setModal={setModal}
          name={name}
          skill={skill}
          des={des}
          button={button}
          bio={bio}
          image={image}
        />
      }
    </div>
  );
};

export default TeamPage;
