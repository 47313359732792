import React from "react";
import "./NewsScroll.css";
import Marquee from "react-fast-marquee";

// Image Import

import amhedabadMirror from "../../assets/img/News/ahmedabad_mirror.png";
import businessTimes from "../../assets/img/News/business-times.png";
import edTimes from "../../assets/img/News/ed-times.png";
import firstIndia from "../../assets/img/News/first-india.png";
import republic from "../../assets/img/News/republic.png";
import indian from "../../assets/img/News/the-indian.png";
import unitedNews from "../../assets/img/News/united_news.png";

const NewsScroll = () => {
  const newsImg = [
    {
      id: 1,
      img: amhedabadMirror,
      url: "https://www.ahmedabadmirror.com/webapps-software-solutions-is-indeed-making-technical-visions-a-reality/81848011.html#google_vignette",
      newsDes:
        "November 26: India is fortunate to have countless stories of skilled professionals pursuing their passion on the riskiest path. The tale of Webapps Software Solutions and its founder Princekumar Prasad.",
    },
    {
      id: 2,
      img: businessTimes,
      url: "https://inbusinesstimes.com/webapps-software-solutions-is-indeed-making-technical-visions-a-reality/",
      newsDes:
        "November 26: India is fortunate to have countless stories of skilled professionals pursuing their passion on the riskiest path. The tale of Webapps Software Solutions and its founder Princekumar Prasad.",
    },
    {
      id: 3,
      img: edTimes,
      url: "https://edtimes.in/webapps-software-solutions-is-indeed-making-technical-visions-a-reality/",
      newsDes:
        "November 26: India is fortunate to have countless stories of skilled professionals pursuing their passion on the riskiest path. The tale of Webapps Software Solutions and its founder Princekumar Prasad.",
    },
    {
      id: 4,
      img: firstIndia,
      url: "https://firstindia.co.in/news/india/webapps-software-solutions-is-indeed-making-technical-visions-a-reality",
      newsDes:
        "November 26: India is fortunate to have countless stories of skilled professionals pursuing their passion on the riskiest path. The tale of Webapps Software Solutions and its founder Princekumar Prasad.",
    },
    {
      id: 6,
      img: republic,
      url: "https://republicnewstoday.com/index.php/2022/11/26/webapps-software-solutions-is-indeed-making-technical-visions-a-reality/",
      newsDes:
        "November 26: India is fortunate to have countless stories of skilled professionals pursuing their passion on the riskiest path. The tale of Webapps Software Solutions and its founder Princekumar Prasad.",
    },
    {
      id: 7,
      img: indian,
      url: "https://theindianalert.com/webapps-software-solutions-is-indeed-making-technical-visions-a-reality/",
      newsDes:
        "November 26: India is fortunate to have countless stories of skilled professionals pursuing their passion on the riskiest path. The tale of Webapps Software Solutions and its founder Princekumar Prasad.",
    },
    {
      id: 8,
      img: unitedNews,
      url: "https://assianews.com/webapps-software-solutions-is-indeed-making-technical-visions-a-reality/18100/",
      newsDes:
        "November 26: India is fortunate to have countless stories of skilled professionals pursuing their passion on the riskiest path. The tale of Webapps Software Solutions and its founder Princekumar Prasad.",
    },
  ];

  return (
    <div id="news-scroll">
      <Marquee direction="left">
        {newsImg.map((item, index) => {
          return (
            <a
              href={item.url}
              target="_blank"
              key={index}
              className="news-scroll-item"
            >
              <img src={item.img} alt="news" />
              <p>{item.newsDes}</p>
            </a>
          );
        })}
      </Marquee>


      <Marquee direction="right">
        {newsImg.map((item, index) => {
          return (
            <a
              href={item.url}
              target="_blank"
              key={index}
              className="news-scroll-item"
            >
              <img src={item.img} alt="news" />
              <p>{item.newsDes}</p>
            </a>
          );
        })}
      </Marquee>
      
    </div>
  );
};

export default NewsScroll;
