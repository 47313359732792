import React from "react";
import Buttom from "../../components/Button/Button";
import turn from "../../assets/img/BlogPage2/image 196.png";
import BlogCard from "../../components/BlogCard/BlogCard";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import "./BlogPage2.css";
import { Helmet } from "react-helmet-async";

const BlogPage2 = () => {
  const data = useSelector((store) => store.value);
  const reverseArr = [...data].reverse();
  const paramId = useParams();
  const id = paramId.id;
  const filteredData = data.filter((x) => {
    return x.id == id;
  });
  const item = filteredData[0];

  return (
    <div id="blogPage2">

      <Helmet>
        <title>Blogs | Webapps Software Solutions </title>
        <meta name="copyright" content="https://webappssoft.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webappssoft.com/" />
        <link rel="next" href="https://webappssoft.com/" />
        <meta property="og:title" content="Property Dealers in Jamshedpur | Property Dealers Near Me" />
        <meta property="og:url" content="https://webappssoft.com/" />
        <meta property="og:image" content="https://webappssoft.com/logo/sy-logo.jpg" />
        <meta property="og:description" content="Property Dealers in Jamshedpur: Find 150+ Reliable real estate agents and Brokers in Jamshedpur. Contact residential and Commercial property consultants online." />
        <meta name="description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="keywords" content="website design company India,web designing India, best web design company,top web design company,top web design companies India, web design and development company in india" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta property="og:site_name" content="Webapps Software Solutions" />
        <meta name="twitter:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="twitter:title" content="Website Design & Development Company In Kolkata, India" />
        <meta property="og:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:site" content="webapps software Solutions" />
        <meta name="twitter:creator" content="@webappsoftwares" />
        <meta name="theme-color" content="#0098DA" />
        <meta name="author" content="WASS Integrated Tech Enable It Solutions" />
        <meta name="google-site-verification" content="3qKB507bM-zleASA7ZJYxnvl9L30Wk2o4KA7dZksurY" />
      </Helmet>

      <div className="hero-container">
        <div className="hero">
          <div className=""></div>

          <div className="content">
            <div className="title">
              <h1>{item.heading1}</h1>
              {/* <h1>movement</h1> */}
            </div>

            <p>{item.title}</p>

            <div className="connect">
              <div className="btnnn">
                <Buttom bgColor="#800080" color="#fff" title="Schedule Call" />
              </div>

              <div className="date">
                <h3>{item.date}</h3>
              </div>

              <div className="social_link">
                <a
                  target="_blank"
                  href="https://www.facebook.com/webappssoftwaresolutions"
                >
                  <i className="bx bxl-facebook"></i>
                </a>

                <a target="_blank" href="https://instagram.com/webappssoftware">
                  <i className="bx bxl-instagram"></i>
                </a>

                <a target="_blank" href="https://twitter.com/webappsoftwares">
                  <i className="bx bxl-twitter"></i>
                </a>

                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/webapps-softwares-solutions/"
                >
                  <i className="bx bxl-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="heading">
        <ul className="content_1">
          <li>
            <h2>{item.heading1}</h2>

            <p>{item.description}</p>
          </li>

          <li>
            <h3>{item.heading2}</h3>

            <p>{item.heading2Text}</p>
          </li>

          <li>
            <h4>{item.heading3}</h4>

            <div className="">
              <p>{item.heading2Text}</p>

              <img className="computer_image" src={item.desImage} alt="" />

              <p>{item.endText}</p>
            </div>
          </li>
        </ul>

        <ul className="content_2">
          <li>
            <img className="turn_image" src={turn} alt="" />
          </li>

          <li className="user">
            <div className="name">
              <img
                src="https://media.licdn.com/dms/image/D5603AQHT4QrSZlyKQg/profile-displayphoto-shrink_200_200/0/1707664304255?e=2147483647&v=beta&t=zhRUnNrpLCyuG7BB_QvsT5N511BPFjyOGEmLAuJWLuE"
                alt=""
              />

              <div className="title">
                <p>THE AUTHOR</p>
                <p>{item.author}</p>
                <p>Front End Engineer</p>
              </div>
            </div>

            <div className="des">
              <p>{item.description.slice(0, 450)}</p>
            </div>
          </li>

          <li className="story">
            <h4>Related Stories</h4>

            <div className="stories">
              {item.tags?.map((x) => {
                return <p>{x.tag}</p>;
              })}
            </div>
          </li>
        </ul>
      </div>

      <div className="cardyyy">
        {reverseArr.slice(0, 3).map((x) => {
          return (
            <BlogCard
              key={x.id}
              img={x.img}
              des={x.description.slice(0, 100)}
              date={x.date}
              id={x.id}
            />
          );
        })}
      </div>
    </div>
  );
};

export default BlogPage2;
