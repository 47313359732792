import React from 'react'
import education from '../../assets/img/CRS/education.png'
import traning from '../../assets/img/CRS/traning.png'
import './CRS.css'
import { Helmet } from 'react-helmet-async'
const CRS = () => {
    return (
        <div id='crs'>

<Helmet>
      <title>CRS | Webapps Software Solutions </title>
        <meta name="copyright" content="https://webappssoft.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webappssoft.com/" />
        <link rel="next" href="https://webappssoft.com/" />
        <meta property="og:title" content="Property Dealers in Jamshedpur | Property Dealers Near Me" />
        <meta property="og:url" content="https://webappssoft.com/" />
        <meta property="og:image" content="https://webappssoft.com/logo/sy-logo.jpg" />
        <meta property="og:description" content="Property Dealers in Jamshedpur: Find 150+ Reliable real estate agents and Brokers in Jamshedpur. Contact residential and Commercial property consultants online." />
        <meta name="description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="keywords" content="website design company India,web designing India, best web design company,top web design company,top web design companies India, web design and development company in india" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta property="og:site_name" content="Webapps Software Solutions" />
        <meta name="twitter:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="twitter:title" content="Website Design & Development Company In Kolkata, India" />
        <meta property="og:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:site" content="webapps software Solutions" />
        <meta name="twitter:creator" content="@webappsoftwares" />
        <meta name="theme-color" content="#0098DA" />
        <meta name="author" content="WASS Integrated Tech Enable It Solutions" />
        <meta name="google-site-verification" content="3qKB507bM-zleASA7ZJYxnvl9L30Wk2o4KA7dZksurY" />
      </Helmet>

            <div className="hero">
                <div className="title">
                    <h1>Corporate Social Responsibility</h1>
                </div>
            </div>

            <div className="content">

                <div className="education">
                    <img src={education} alt="" />
                </div>

                <div className="traning">
                    <img src={traning} alt="" />
                </div>


            </div>

            <div className="campgain">

                <div className="banner_item">
                </div>


                <div className="banner_item">
                    <p>WebApps Software Solution We propose a unique model of ‘Communication Co-creation. It is a form of market or business strategy that emphasizes the greater realization of the relationship between a brand & its customers. It treats markets as forums for brands and active customers to unite, share and renew each other's resources and capabilities to create value through new forms of interaction, service and learning mechanisms. This kind of communication intends to involve the consumers in brand communication. </p>

                    <div className="btnns">
                        <button>Know Our Policy</button>
                        <button>Members</button>
                        <button>Projects</button>
                    </div>
                </div>


                <div className="banner_item">
                </div>

            </div>

            <div className="service_team">




                <div className="box_1">

                </div>

                <div className="box_2">

                </div>


            </div>



        </div>
    )
}

export default CRS
