import React, { useEffect, useState } from "react";
import "./HeaderBottom.css";
import MobileNavMenu from "./MobileNavMenu";
import { Link, useNavigate } from "react-router-dom";

const BottomHeader = () => {
  const [active, setActive] = useState(0);
  const [transForm, setTransform] = useState("");
  const [display, setDisplay] = useState("");
  const [main, setMain] = useState('item1');
  const [sub, setSub] = useState('');
  const [none, setNone] = useState("")
  const navigate = useNavigate()
  const handleClick = (index) => {
    setActive(index);
  };

  const viewNavMenu = () => {
    setTransform("setTransform");
  }

  const close = () => {
    setNone("none");
    console.log("close");
    handleClick(0);
  }



  return (
    <div className="navigation">
      <ul>
        <li
          className={`list ${active === 0 ? "active" : ""}`}
          data-color="#dc143c"
          onClick={() => handleClick(0)}
        >
          <Link to="/">
            <div href="#">
              <span className="icon">
                <i className='bx bx-home-alt-2'></i>
              </span>
              <span className="title">Home</span>
            </div>
          </Link>
        </li>
        <li
          className={`list ${active === 1 ? "active" : ""}`}
          data-color="#3c40c6"
          onClick={() => handleClick(1)}
        >
          <Link to="/service">
            <a href="#">
              <span className="icon">
                <i className='bx bx-dots-horizontal-rounded' ></i>
              </span>
              <span className="title">Services</span>
            </a>
          </Link>
        </li>
        <li
          className={`list ${active === 2 ? "active" : ""}`}
          data-color="#05c46b"
          onClick={() => handleClick(2)}
        >

          <Link to="/mobileportfolio">
            <a href="#">
              <span className="icon"><i className='bx bx-message-square-dots' ></i></span>
              <span className="title">Portfolio</span>
            </a>
          </Link>
        </li>
        <li
          className={`list ${active === 3 ? "active" : ""}`}
          data-color="#0fbcf9"
          onClick={() => handleClick(3)}
        >
          <Link to="/contect">
            <a href="#">
              <span className="icon"><i className='bx bxs-contact' ></i></span>
              <span className="title">Contact</span>
            </a>
          </Link>
        </li>

        <li
          className={`list ${active === 4 ? "active" : ""} mobile-navItems`}
          data-color="#ffa801"
          onClick={() => {
            handleClick(4);
            viewNavMenu();
          }
          }
        >
          <a href="#">
            <span className="icon"><i className='bx bx-menu' ></i></span>
            <span className="title">Menu</span>
          </a>

          <div className={`absolute-bottom_nav ${transForm} ${none}`}>

            <div id='mobile_navmenu' >


              <div className="navbar_section">
                <i id='cross' className='bx bx-x' onClick={() =>{
                 setNone("none");
                 navigate("/");
                 window.location.reload();
                handleClick(1);
                }}></i>


                <div className='mobile-nav-list'>


                  <li className='main-mob-nav-list' onClick={() => setMain("item1")}>
                    <div className="sub-he">
                      {main === "item1" ? <i className='bx bx-chevron-down'></i> : <i className='bx bx-chevron-right' ></i>}
                      Practices
                    </div>
                    {
                      main === 'item1' && <ul className='sub-menu-list'>

                        <li onClick={() => setSub("item1")}>
                          <div className="submenu-list">
                            <i className='bx bx-chevron-right'></i> Application Engineering
                          </div>


                          {
                            sub === "item1" ? <div className='near-sub'>
                              <div>
                                <i className='bx bx-chevrons-right'></i> Managed Services
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Custome App Development
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Andoird App Enginerring
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> IOS App Enginering
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Hybrid App Development

                              </div>
                            </div> : null
                          }

                        </li>

                        <li onClick={() => setSub("item2")}>
                          <div className="submenu-list">
                            <i className='bx bx-chevron-right'></i> Digital Experience
                          </div>

                          {
                            sub === "item2" ? <div className='near-sub'>
                              <div>
                                <i className='bx bx-chevrons-right'></i> Website Development
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Custome Software Development
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i>ERP Software Development

                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Medical Software Development
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Ecommerece Website Development
                              </div>
                            </div> : null
                          }

                        </li>

                        <li onClick={() => setSub("item3")}>
                          <div className="submenu-list">
                            <i className='bx bx-chevron-right'></i> Brand Solutions
                          </div>

                          {
                            sub === "item3" ? <div className='near-sub'>
                              <div>
                                <i className='bx bx-chevrons-right'></i> Complete Stragegy & Marketing
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i>Ebusiness Growth Marketing
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i>Search Engine Marketring
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Socal Media Ads & Campaign
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i>Brand Idendity Tranformation
                              </div>
                            </div> : null
                          }

                        </li>

                      </ul>
                    }
                  </li>

                  <li className='main-mob-nav-list' onClick={() => setMain("item2")}>
                    <div className="sub-he">
                      {main === "item2" ? <i className='bx bx-chevron-down'></i> : <i className='bx bx-chevron-right' ></i>}
                      Industries
                    </div>

                    {
                      main === 'item2' && <ul className='sub-menu-list'>

                        <li onClick={() => setSub("item1")}>
                          <div className="submenu-list">
                            <i className='bx bx-chevron-right'></i> HealthCare
                          </div>

                          {
                            sub === "item1" ? <div className='near-sub'>
                              <div>
                                <i className='bx bx-chevrons-right'></i> Doctor Consultation Solutions
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Lab Software & Solutions
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i>E-Consulation
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i>Hospital Manamgent
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Clicnic Manamgent Software
                              </div>
                            </div> : null
                          }

                        </li>

                        <li onClick={() => setSub("item2")}>
                          <div className="submenu-list">
                            <i className='bx bx-chevron-right'></i> Food & Restaurants
                          </div>

                          {
                            sub === "item2" ? <div className='near-sub'>
                              <div>
                                <i className='bx bx-chevrons-right'></i> QSR
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Food Ordering System
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Resturenat Mangemnt
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Wearhouse Managment
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i>POS
                              </div>
                            </div> : null
                          }

                        </li>

                        <li onClick={() => setSub("item3")}>
                          <div className="submenu-list">
                            <i className='bx bx-chevron-right'></i> Education
                          </div>

                          {
                            sub === "item3" ? <div className='near-sub'>
                              <div>
                                <i className='bx bx-chevrons-right'></i> Lead Manager
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Education Website
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> School Software Ondemand
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Teacher Manamgent
                              </div>

                            </div> : null
                          }

                        </li>

                      </ul>
                    }


                  </li>

                  <li className='main-mob-nav-list' onClick={() => setMain("item3")}>
                    <div className="sub-he">
                      {main === "item3" ? <i className='bx bx-chevron-down'></i> : <i className='bx bx-chevron-right' ></i>}
                      Why WASS
                    </div>

                    {
                      main === 'item3' ? <ul className='sub-menu-list'>

                        <li onClick={() => setSub("item1")}>
                          <div className="submenu-list">
                            <i className='bx bx-chevron-right'></i> About Us
                          </div>

                          {
                            sub === "item1" ? <div className='near-sub'>
                              <div>
                                <i className='bx bx-chevrons-right'></i> Compnay Profile
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Team
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i>
                                Our Motive
                              </div>


                            </div> : null
                          }

                        </li>

                        <li onClick={() => setSub("item2")}>
                          <div className="submenu-list">
                            <i className='bx bx-chevron-right'></i>Awards
                          </div>

                          {
                            sub === "item2" ? <div className='near-sub'>
                              <div>
                                <i className='bx bx-chevrons-right'></i> Testimonials
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i>Our Portfolio
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Awards
                              </div>


                            </div> : null
                          }

                        </li>

                        <li onClick={() => setSub("item3")}>
                          <div className="submenu-list">
                            <i className='bx bx-chevron-right'></i> Resource
                          </div>

                          {
                            sub === "item3" ? <div className='near-sub'>
                              <div>
                                <i className='bx bx-chevrons-right'></i> Career @ WASS
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Contact Us
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Get Quote
                              </div>


                            </div> : null
                          }

                        </li>

                      </ul> : null
                    }

                  </li>

                  <li className='main-mob-nav-list' onClick={() => setMain("item4")}>
                    <div className="sub-he">
                      {main === "item4" ? <i className='bx bx-chevron-down'></i> : <i className='bx bx-chevron-right' ></i>}
                      Our Works
                    </div>

                    {
                      main === 'item4' ? <ul className='sub-menu-list'>

                        <li onClick={() => setSub("item1")}>
                          <div className="submenu-list">
                            <i className='bx bx-chevron-right'></i> App
                          </div>

                          {
                            sub === "item1" ? <div className='near-sub'>
                              <div>
                                <i className='bx bx-chevrons-right'></i> Compnay Profile
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Team
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i>
                                Our Motive
                              </div>


                            </div> : null
                          }

                        </li>

                        <li onClick={() => setSub("item2")}>
                          <div className="submenu-list">
                            <i className='bx bx-chevron-right'></i>Website & Software
                          </div>

                          {
                            sub === "item2" ? <div className='near-sub'>
                              <div>
                                <i className='bx bx-chevrons-right'></i> Testimonials
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i>Our Portfolio
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Awards
                              </div>


                            </div> : null
                          }

                        </li>

                        <li onClick={() => setSub("item3")}>
                          <div className="submenu-list">
                            <i className='bx bx-chevron-right'></i> Branding
                          </div>

                          {
                            sub === "item3" ? <div className='near-sub'>
                              <div>
                                <i className='bx bx-chevrons-right'></i> Career @ WASS
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Contact Us
                              </div>

                              <div>
                                <i className='bx bx-chevrons-right'></i> Get Quote
                              </div>


                            </div> : null
                          }

                        </li>

                      </ul> : null
                    }

                  </li>


                </div>
              </div>

            </div>

          </div>
        </li>

        <div className="indicator"></div>
      </ul>
    </div>
  );
};

export default BottomHeader;
