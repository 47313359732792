import React, { useEffect, useState } from "react";
import './ScrollCard.css'
const Scrollcard = () => {
  useEffect(() => {
    const allCards = document.querySelectorAll(".cards_boxes");
    const headerHeight = 20;

    if (allCards.length) {
      allCards.forEach((card, i) => {
        const incValue = i * headerHeight;
        const decValue = (allCards.length - i) * headerHeight;
        card.style.marginTop = `20px`;
        card.style.marginBottom = `20px`;
        card.style.top = `${incValue}px`;
        card.style.bottom = `calc(-100vh + ${decValue}px)`;
      });
    }
  }, []);

  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const currentScroll = window.scrollY;
      const newScrollPercentage = (currentScroll / scrollHeight) * 100;
      setScrollPercentage(newScrollPercentage);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="wass_benifits">

      <div id="sticky_scroll-card" className="card_scroll">
        <div className="cards_boxes">
          <div className="card_content">
            <div className="card_content_left">
              <div className="card_content_left_title">Copy Benefits</div>
              <p>
                WebApps Software Solution We propose a unique model of
                ‘Communication Co-creation. It is a form of market or business
                strategy that emphasizes the greater realization of the
                relationship between a brand & WebApps Software Solution We
                propose a unique model of ‘Communication Co-creation. It is a
                form of market or business strategy that emphasizes
                <br />
                the greater realization of the relationship between a brand &
                00x productivity without sacrificing an ounce of quality in your
                brand’s voice, style, and authority. Now, you can leverage
                Copy.ai automated workflows to streamline sales and marketing
                for growth like you’ve never experienced before.But don’t take
                our word for it. Take Copy.ai for a (free) test drive yourself.
              </p>
            </div>

            <div className="card_right_container">
              <div
                className="card_content_right"
                style={{ width: `${100 - scrollPercentage * 0.4}%` }}
              >
                <div className="card_content_right_content">
                  <img
                    src="https://assets-global.website-files.com/628288c5cd3e8411b90a36a4/63e2b56fdd78533bf89e99b6_cold-outreach-icon.svg"
                    alt=""
                  />

                  <h4>Personalized Card 1</h4>

                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Numquam nisi sit eos assumenda nesciunt, quam at. Lorem
                    ipsum dolor sit amet consectetur adipisicing elit.
                    Reiciendis, perferendis quidem repudiandae rerum recusandae
                    quasi sint nisi officiis, nihil iusto quae vel facere quo
                    mollitia cumque ipsum consectetur eos magnam! Lorem ipsum
                    dolor sit amet consectetur adipisicing elit. Fugit,
                    distinctio.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cards_boxes">
          <div className="card_content">
            <div className="card_content_left"></div>
            <div className="card_right_container">
              <div
                className="card_content_right"
                style={{ width: `${100 - scrollPercentage * 0.3}%` }}
              >
                <div className="card_content_right_content">
                  <img
                    src="https://assets-global.website-files.com/628288c5cd3e8411b90a36a4/63e32cb68bcb222d8272cbde_icon-seo.svg"
                    alt=""
                  />

                  <h4>Personalized Card 1</h4>

                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Numquam nisi sit eos assumenda nesciunt, quam at. Lorem
                    ipsum dolor sit amet consectetur adipisicing elit.
                    Reiciendis, perferendis quidem repudiandae rerum recusandae
                    quasi sint nisi officiis, nihil iusto quae vel facere quo
                    mollitia cumque ipsum consectetur eos magnam!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cards_boxes">
          <div className="card_content">
            <div className="card_content_left"></div>
            <div className="card_right_container">
              <div
                className="card_content_right"
                style={{ width: `${100 - scrollPercentage * 0.2}%` }}
              >
                <div className="card_content_right_content">
                  <img
                    src="https://assets-global.website-files.com/628288c5cd3e8411b90a36a4/63e32cb6f80ef16399696d50_product-icon.svg"
                    alt=""
                  />

                  <h4>Personalized Card 1</h4>

                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Numquam nisi sit eos assumenda nesciunt, quam at. Lorem
                    ipsum dolor sit amet consectetur adipisicing elit.
                    Reiciendis, perferendis quidem repudiandae rerum recusandae
                    quasi sint nisi officiis, nihil iusto quae vel facere quo
                    mollitia cumque ipsum consectetur eos magnam!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cards_boxes">
          <div className="card_content">
            <div className="card_content_left"></div>

            <div className="card_right_container">
              <div
                className="card_content_right"
                style={{ width: `${100 - scrollPercentage * 0.1}%` }}
              >
                <div className="card_content_right_content">
                  <img
                    src="https://assets-global.website-files.com/628288c5cd3e8411b90a36a4/63e32cb635c0cf50b0e32b3f_api.svg"
                    alt=""
                  />

                  <h4>Personalized Card 1</h4>

                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Numquam nisi sit eos assumenda nesciunt, quam at. Lorem
                    ipsum dolor sit amet consectetur adipisicing elit.
                    Reiciendis, perferendis quidem repudiandae rerum recusandae
                    quasi sint nisi officiis, nihil iusto quae vel facere quo
                    mollitia cumque ipsum consectetur eos magnam!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cards_boxes">
          <div className="card_content">
            <div className="card_content_left"></div>
            <div className="card_right_container">
              <div
                className="card_content_right"
                style={{ width: `${100 - scrollPercentage * 0.01}%` }}
              >
                <div className="card_content_right_content">
                  <img
                    src="https://assets-global.website-files.com/628288c5cd3e8411b90a36a4/63e32cb68bcb2283d272cbda_end%20to%20end.svg"
                    alt=""
                  />

                  <h4>Personalized Card 1</h4>

                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Numquam nisi sit eos assumenda nesciunt, quam at. Lorem
                    ipsum dolor sit amet consectetur adipisicing elit.
                    Reiciendis, perferendis quidem repudiandae rerum recusandae
                    quasi sint nisi officiis, nihil iusto quae vel facere quo
                    mollitia cumque ipsum consectetur eos magnam!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scrollcard;
