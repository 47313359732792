import React from "react";
import background from "../../assets/img/EnqueryPage/EnqueryPage.png";
import star from "../../assets/img/EnqueryPage/star.png";
import logo from "../../assets/img/logo/logo.svg";
import oval from "../../assets/img/EnqueryPage/oval.png";
import hand1 from "../../assets/img/EnqueryPage/hand1.png";
import hand2 from "../../assets/img/EnqueryPage/hand2.png";
import staroff from "../../assets/img/EnqueryPage/staroff.png";

import "./EnqueryPage.css";

const EnqueryPage = () => {
  return (
    <div id="enquery_page">
      <div className="enquery_background">
        <img src={background} alt="" className="" />
      </div>
      <img src={star} alt="" className="starImagw" />

      <div className="contect_page-title">
        <h4>REQUEST PLACED</h4>
        <h2>Brand Enquiry Acknowledgement</h2>
        <p>
          Thank you for filling the brand enquiry form!We look forward to
          working with you and assisting you with your needs.Our team shall
          understand your requirements and get back to you in 48 hours
        </p>
      </div>

      <div className="enquery_details_main">
        <div className="enquery_details">
          <div className="enquery_oval">
            <div className="enquery_page--oval_right">
              <img src={oval} alt="" className="enquery_oval_image1" />
              <img src={staroff} alt="" className="star_off" />
              <div className="both_hand">
                <img src={hand1} alt="" />
                <img src={hand2} alt="" />
              </div>
            </div>
          </div>

          <div className="center">
            <div className="enquery_details_nav">
              <div className="enquery_page_logo">
                <img src={logo} alt="" />
              </div>
              <h2> Enquiry Details </h2>
            </div>

            <div className="enquery_details-contents">
              <div className="enquery_details-contents1">
                <div className="details-contents1-item">
                  <small>Your Name</small>
                  <small>Ram Charan Teja</small>
                </div>

                <div className="details-contents1-item">
                  <small>Your business email</small>
                  <small>business@webappssoft.com</small>
                </div>

                <div className="details-contents1-item">
                  <small>Your Budget</small>
                  <small>Rs. 100k -200k</small>
                </div>
              </div>

              <div className="enquery_details-contents1">
                <div className="details-contents1-item">
                  <small>Your Company</small>
                  <small>+91 9763500291</small>
                </div>

                <div className="details-contents1-item">
                  <small>APEXA Solutions Private Limited </small>
                  <small>Recorded video</small>
                </div>

                <div className="details-contents1-item">
                  <small>Your mobile number</small>
                  <small>+91 9763500291</small>
                </div>
              </div>

              <div className="enquery_details-contents1">
                <div className="details-contents1-item">
                  <small>Your city/location</small>
                  <small>Mumbai</small>
                </div>

                <div className="details-contents1-item">
                  <small>Skyp ID</small>
                  <small>Monushaha_sksa</small>
                </div>

                <div className="details-contents1-item">
                  <small>File / Attachment</small>
                  <small>PPt. PSX</small>
                </div>
              </div>

              <div className="enquery_details-contents1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnqueryPage;
