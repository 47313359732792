import React, { useState } from "react";
import "./HelpPage.css";
import "./HelpPage.css";

const HelpPage = () => {
  const helpCenter = [
    {
      id: 1,
      title: "What is WebApps Software Solutions?",
      content: [
        { tag: "WebApps Software Solutions" },
        { tag: "WebApps Software Solutions" },
        { tag: "WebApps Software Solutions" },
      ],
    },
    {
      id: 2,
      title: "What is DSADAS Software Solutions?",
      content: [
        { tag: "WebApps Software Solutions" },
        { tag: "WebApps Software Solutions" },
        { tag: "WebApps Software Solutions" },
      ],
    },
    {
      id: 3,
      title: "What is REWsdfsS Software Solutions?",
      content: [
        { tag: "WebApps Software Solutions" },
        { tag: "WebApps Software Solutions" },
        { tag: "WebApps Software Solutions" },
      ],
    },
    {
      id: 4,
      title: "What is ADSSDDASS Software Solutions?",
      content: [
        { tag: "WebApps Software Solutions" },
        { tag: "WebApps Software Solutions" },
        { tag: "WebApps Software Solutions" },
      ],
    },
  
  ];

  // const getAnswers = [
  //   {
  //       id: 1,
  //       question : "What is WebApps Software Solutions?",
  //       ans : "WebApps Software Solutions is a software solution for web apps applications and web apps applications that provides web apps applications and web apps applications that provide web apps applications and web apps applications that provide web apps applications and web apps applications"
  //   },
  //    {
  //       id: 2,
  //       question : "What is DSADAS Software Solutions?",
  //       ans : "WebApps Software Solutions is a software solution for web apps applications and web apps applications that provides web apps applications and web apps applications that provides web apps applications and web apps applications that provides web apps applications and web apps applications"
  //   },
  //   {
  //       id: 3,
  //       question : "What is REWsdfsS Software Solutions?",
  //       ans : "WebApps Software Solutions is a software solution for web apps applications and web apps applications that provides web apps applications and web apps applications that provides web apps applications and web apps applications that provides web apps applications and web apps applications"
  //   },
  //   {
  //       id: 4,
  //       question : "What is ADSSDDASS Software Solutions?",
  //       ans : "WebApps Software Solutions is a software solution for web apps applications and web apps applications that provides web apps applications and web apps applications that provides web apps applications and web apps applications that provides web apps applications and web apps applications"
  //   },
  //   {
  //       id: 5,
  //       question : "What is WebApps Software Solutions?",
  //       ans : "WebApps Software Solutions is a software solution for web apps applications and web apps applications that provides web apps applications and web apps applications that provides web apps applications and web apps applications that provides web apps applications and web apps applications"
  //   }
  // ]

  const [helpQuestion, sethelpQuestion] = useState(helpCenter);

  // const [error, setError] = useState(null);


  const handelChabge = (e) => {
    const text = e.toLowerCase();
    sethelpQuestion(
      helpCenter.filter((item) => item.title.toLowerCase().includes(text))
    );

    const num = helpCenter.filter((item) => item.title.toLowerCase().includes(text));
    
    if (num.length < 0) {
        // setError(<Error/>)
    }


  };



  const [open, setOpen] = useState("item_1");
  const handelShow = (e) => {
    setOpen(e);
  };

  const handelID = (id) => {
    console.log(id);
  }

  

  return (
    <div id="help_page">
      <div id="policy_page_navbar">
        <div className=""></div>
        <h1>Help Center</h1>

        <div className="policy_bar_id">
          <div className="policy-bar_request_id">
            <small>Request ID : 474747474</small>
            <small>17Sept 2023 11:39 PM</small>
          </div>
        </div>
      </div>

      <div className="helpPage_content">
        <div className="helpPage_content_item1">
          <div className="help_search-box">
            <div className="help_search">
              <input
                onChange={(e) => handelChabge(e.target.value)}
                type="text"
                placeholder="Search for The Help"
              />
              <i className="bx bx-search"></i>
            </div>
          </div>

          <div className="help_search_contentss1">
            <div className="help_search_question_boxxx">
              {helpQuestion.map((x) => {
                return (
                  <div key={Math.random()} onClick={()=> handelID(x.id)} className="help_search_question_1">
                    <h5>{x.title}</h5>

                    <ul>
                      {x.content.map((x) => {
                        return (
                          <li key={Math.random()}>
                            <small> {x.tag} </small>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="helpPage_content_item1">
          <div className="help_center_items2">
            <div className="help_center_items2_nav">
              What is WebApps Software Solutions?
            </div>

            <div className="help_center_items2_nav_items">
              <ul>


                <li>
                  <div
                    onClick={() => handelShow("item_1")}
                    className="cross_answerws"
                  >
                    <h5 style={{ fontWeight: open === "item_1" ? 600 : 500 }}>
                      What is WebApps Software Solutions?
                    </h5>
                    <i
                      className={`bx bx-${
                        open === "item_1" ? "minus" : "plus"
                      }`}
                    ></i>
                  </div>

                  <div
                    className="cross_answerws_item"
                    style={{ display: open === "item_1" ? "flex" : "none" }}
                  >
                    <small>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Id dolore voluptate neque, deserunt repellat debitis
                      ducimus facilis! Repellendus corrupti vel deleniti nihil
                      ipsam quisquam, quidem ipsum culpa cum maiores temporibus?
                    </small>
                  </div>
                </li>

                <li>
                  <div
                    onClick={() => handelShow("item_2")}
                    className="cross_answerws"
                  >
                    <h5 style={{ fontWeight: open === "item_2" ? 600 : 500 }}>
                      What is WebApps Software Solutions?
                    </h5>
                    <i
                      className={`bx bx-${
                        open === "item_2" ? "minus" : "plus"
                      }`}
                    ></i>
                  </div>

                  <div
                    className="cross_answerws_item"
                    style={{ display: open === "item_2" ? "flex" : "none" }}
                  >
                    <small>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Id dolore voluptate neque, deserunt repellat debitis
                      ducimus facilis! Repellendus corrupti vel deleniti nihil
                      ipsam quisquam, quidem ipsum culpa cum maiores temporibus?
                    </small>
                  </div>
                </li>

                <li>
                  <div
                    onClick={() => handelShow("item_3")}
                    className="cross_answerws"
                  >
                    <h5 style={{ fontWeight: open === "item_3" ? 600 : 500 }}>
                      What is WebApps Software Solutions?
                    </h5>
                    <i
                      className={`bx bx-${
                        open === "item_3" ? "minus" : "plus"
                      }`}
                    ></i>
                  </div>

                  <div
                    className="cross_answerws_item"
                    style={{ display: open === "item_3" ? "flex" : "none" }}
                  >
                    <small>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Id dolore voluptate neque, deserunt repellat debitis
                      ducimus facilis! Repellendus corrupti vel deleniti nihil
                      ipsam quisquam, quidem ipsum culpa cum maiores temporibus?
                    </small>
                  </div>
                </li>

                <li>
                  <div
                    onClick={() => handelShow("item_4")}
                    className="cross_answerws"
                  >
                    <h5 style={{ fontWeight: open === "item_4" ? 600 : 500 }}>
                      What is WebApps Software Solutions?
                    </h5>
                    <i
                      className={`bx bx-${
                        open === "item_4" ? "minus" : "plus"
                      }`}
                    ></i>
                  </div>

                  <div
                    className="cross_answerws_item"
                    style={{ display: open === "item_4" ? "flex" : "none" }}
                  >
                    <small>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Id dolore voluptate neque, deserunt repellat debitis
                      ducimus facilis! Repellendus corrupti vel deleniti nihil
                      ipsam quisquam, quidem ipsum culpa cum maiores temporibus?
                    </small>
                  </div>
                </li>

                <li>
                  <div
                    onClick={() => handelShow("item_5")}
                    className="cross_answerws"
                  >
                    <h5 style={{ fontWeight: open === "item_5" ? 600 : 500 }}>
                      What is WebApps Software Solutions?
                    </h5>
                    <i
                      className={`bx bx-${
                        open === "item_5" ? "minus" : "plus"
                      }`}
                    ></i>
                  </div>

                  <div
                    className="cross_answerws_item"
                    style={{ display: open === "item_5" ? "flex" : "none" }}
                  >
                    <small>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Id dolore voluptate neque, deserunt repellat debitis
                      ducimus facilis! Repellendus corrupti vel deleniti nihil
                      ipsam quisquam, quidem ipsum culpa cum maiores temporibus?
                    </small>
                  </div>
                </li>


              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpPage;
