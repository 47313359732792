import React from "react";
import "./WebdevPage.css";

const WebdevPage = () => {
  return (
    <div id="webdev_page">
      


    </div>
  );
};

export default WebdevPage;
