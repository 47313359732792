import React from "react";
import { Helmet } from "react-helmet-async";
import "./ContectPage.css";
import { Link } from "react-router-dom";

const ContectPage = () => {
  return (
    <div id="contect_page">
      <Helmet>
        <title>Contact Page | Webapps Software Solutions </title>
        <meta name="copyright" content="https://webappssoft.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webappssoft.com/" />
        <link rel="next" href="https://webappssoft.com/" />
        <meta
          property="og:title"
          content="Property Dealers in Jamshedpur | Property Dealers Near Me"
        />
        <meta property="og:url" content="https://webappssoft.com/" />
        <meta
          property="og:image"
          content="https://webappssoft.com/logo/sy-logo.jpg"
        />
        <meta
          property="og:description"
          content="Property Dealers in Jamshedpur: Find 150+ Reliable real estate agents and Brokers in Jamshedpur. Contact residential and Commercial property consultants online."
        />
        <meta
          name="description"
          content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata"
        />
        <meta
          name="keywords"
          content="website design company India,web designing India, best web design company,top web design company,top web design companies India, web design and development company in india"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata"
        />
        <meta property="og:site_name" content="Webapps Software Solutions" />
        <meta
          name="twitter:description"
          content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata"
        />
        <meta
          name="twitter:title"
          content="Website Design & Development Company In Kolkata, India"
        />
        <meta
          property="og:image"
          content="https://webappssoft.com/assets/images/logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://webappssoft.com/assets/images/logo.png"
        />
        <meta name="twitter:site" content="webapps software Solutions" />
        <meta name="twitter:creator" content="@webappsoftwares" />
        <meta name="theme-color" content="#0098DA" />
        <meta
          name="author"
          content="WASS Integrated Tech Enable It Solutions"
        />
        <meta
          name="google-site-verification"
          content="3qKB507bM-zleASA7ZJYxnvl9L30Wk2o4KA7dZksurY"
        />
      </Helmet>

      <div className="contact-box">
        <div className="box-left">
          <h1>Contact Us</h1>

          <p className="title-p">
            Email, Call or complete the form to learn how WASS can solve your
            problem.
          </p>

          <div className="email">
            <a href="mailto:info@webappssoft.com" target="_blank">
              info@webappssoft.com{" "}
            </a>
            <a href="mailto:support@webappssoft.com" target="_blank">
              support@webappssoft.com
            </a>
          </div>

          <p className="phone_number">Customer Support</p>

          <a className="customer_support" href="tel:+9197635 00291">
            {" "}
            +91 97635 00291
          </a>

          {/* <ul className="cus-s-box">

            <li>
              <h4>Customer Support</h4>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt a totam ex fuga illum natus non fugiat repellat. </p>
            </li>

            <li>
              <h4>Feedback and Suggestions</h4>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt a totam ex fuga illum natus non fugiat repellat. </p>
            </li>

            <li>
              <h4>Media Inquiries</h4>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt a totam ex fuga illum natus non fugiat repellat. </p>
            </li>

          </ul> */}
        </div>

        <div className="box-right">
          <div className="right-cont">
            <h1>Get in Touch</h1>
            <p>You can reach us anytime</p>

            <form action="">
              <div className="fst-name">
                <input type="text" placeholder="First name" />
                <input type="text" placeholder="Last name" />
              </div>

              <div className="number">
                <span>+91</span>
                <input type="number" placeholder="Phone number" />
              </div>

              <textarea name="" id="" cols="30" rows="5"></textarea>

              <button>Submit</button>

              <div className="contact-privicy">
                <p className="">
                  By contecting us, you agree to our{" "}
                  <span>
                    {" "}
                    <Link to="/terms">Terms of service</Link>
                  </span>{" "}
                  and{" "}
                  <span>
                    {" "}
                    <Link to="/policy"> Privicy Policy</Link>
                  </span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="contact-map">
        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3686.008832201065!2d88.3603827!3d22.5038518!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a027184861c387f%3A0xeba8e7c56f4f6e26!2sWebapp%20software%20solutions!5e0!3m2!1sen!2sin!4v1711803171745!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

        {/* <div className="map-deatls">

          <h4>Our Location</h4>
          <h1>128, Jodhpur Gardens, Lake Gardens, Kolkata, West Bengal 700045</h1>

          <div className="contact-item">
            <div className="n9-box">

              <img src="https://t4.ftcdn.net/jpg/04/63/63/59/360_F_463635935_IweuYhCqZRtHp3SLguQL8svOVroVXvvZ.jpg" alt="img" />
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>

              <h5>Call Our Expert</h5>

              <button>Call us</button>
            </div>

            <div className="n9-box">

              <img src="https://cdn-icons-png.freepik.com/256/11879/11879223.png" alt="img" />
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>

              <h5>Call Our Expert</h5>

              <button>Visit Wass</button>
            </div>

            <div className="n9-box">

              <img src="https://www.pngitem.com/pimgs/m/379-3795781_send-a-message-send-message-icon-png-transparent.png" alt="img" />
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>

              <h5>Call Our Expert</h5>

              <button>Send Querry</button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ContectPage;
