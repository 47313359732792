import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home/Home";
import PolicyPage from "../pages/PolicyPage/PolicyPage";
import HelpPage from "../pages/HelpPage/HelpPage";
import ContectPage from "../pages/ContectPage/ContectPage";
import CodeStackPage from "../pages/CodeStackPage/CodeStackPage";
import EnqueryPage from "../pages/EnqueryPage/EnqueryPage";
import ServicePage from "../pages/ServicePage/ServicePage";
import FunnelPage from "../pages/FunnelPage/FunnelPage";
import FunnelPage2 from "../pages/FunnelPage2/FunnelPage2";
import ServicePage2 from "../pages/ServicePage2/ServicePage2";
import ServicePage3 from "../pages/ServicePage3/ServicePage3";
import LogoPortfolio from "../pages/LogoPortfolio/LogoPortfolio";
import MobilePortfolio from "../pages/MobilePortfolio/MobilePortfolio";
import PortfolioDetailPage from "../pages/PoetfolioDetailPage/PortfolioDetailPage";
import BlogPage from "../pages/BlogPage/BlogPage";
import BlogPage2 from "../pages/BlogPage2/BlogPage2";
import TeamPage from "../pages/TeamPage/TeamPage";
import News from "../pages/NewsPage/News";
import CRS from "../pages/CRSActivityPage/CRS";
import CarrerPage from "../pages/CareerPage/CarrerPage";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import EnqueryForm from "../pages/EnqueryPage/EnqueryForm";
import WebdevPage from "../pages/WebDevPage/WebdevPage";
import TermsConditions from "../pages/TermsConditions/TermsConditions";
import WeareWorking from "../pages/WeareWorking";
import Blank from "../pages/Blank";
const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/path" element={<Blank />} />
      <Route path="/" element={<Home />} />
      <Route path="/policy" element={<PolicyPage />} />
      <Route path="/help" element={<HelpPage />} />
      <Route path="/contect" element={<ContectPage />} />
      <Route path="/stack" element={<CodeStackPage />} />
      <Route path="/enquery" element={<EnqueryPage />} />
      <Route path="/enqueryform" element={<EnqueryForm />} />
      <Route path="/service" element={<ServicePage />} />
      <Route path="/service2" element={<ServicePage2 />} />
      <Route path="/service3" element={<ServicePage3 />} />
      <Route path="/funnel" element={<FunnelPage />} />
      <Route path="/funnel2" element={<FunnelPage2 />} />
      <Route path="/logoportfolio" element={<LogoPortfolio />} />
      <Route path="/mobileportfolio" element={<MobilePortfolio />} />
      <Route path="/portfoliodetails" element={<PortfolioDetailPage />} />
      <Route path="/blog" element={<BlogPage />} />
      <Route path="/blog2/:id" element={<BlogPage2 />} />
      <Route path="/team" element={<TeamPage />} />
      <Route path="/news" element={<News />} />
      <Route path="/crs" element={<CRS />} />
      <Route path="/careers" element={<CarrerPage />} />
      <Route path="/webdev" element={<WebdevPage />} />
      <Route path="/terms" element={<TermsConditions />} />
      <Route path="/pending" element={<WeareWorking />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default AllRoutes;
