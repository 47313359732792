import React from "react";
import { NavLink } from "react-router-dom";

const Blank = () => {
  return (
    <div id="blank">
      <ul style={{ display: " flex", gap: "40px", flexWrap : "wrap", margin:  '20px' }}>
        <NavLink to="/home">
          {" "}
          <li>Home</li>
        </NavLink>
        <NavLink to="/policy">
          {" "}
          <li>Policy</li>
        </NavLink>
        <NavLink to="/contect">
          {" "}
          <li>Contact</li>
        </NavLink>
        <NavLink to="/stack">
          {" "}
          <li>Code Stack</li>
        </NavLink>
        <NavLink to="/enquery">
          {" "}
          <li>Enquery</li>
        </NavLink>
        <NavLink to="/service">
          {" "}
          <li>Service</li>
        </NavLink>
        <NavLink to="/service2">
          {" "}
          <li>Service Page 2</li>
        </NavLink>
        <NavLink to="/service3">
          {" "}
          <li>Service Page 3</li>
        </NavLink>
        <NavLink to="/funnel">
          {" "}
          <li>Funnel</li>
        </NavLink>
        <NavLink to="/logoportfolio">
          {" "}
          <li>Logo Portfolio</li>
        </NavLink>
        <NavLink to="/mobileportfolio">
          {" "}
          <li>Mobile Portfolio</li>
        </NavLink>
        <NavLink to="/blog">
          {" "}
          <li>Blog</li>
        </NavLink>
        <NavLink to="/team">
          {" "}
          <li>Team</li>
        </NavLink>
        <NavLink to="/news">
          {" "}
          <li>News</li>
        </NavLink>
        <NavLink to="/crs">
          {" "}
          <li>Crs</li>
        </NavLink>
        <NavLink to="/careers">
          {" "}
          <li>Carrer</li>
        </NavLink>
        <NavLink to="/webdev">
          {" "}
          <li>WebDev</li>
        </NavLink>
        <NavLink to="/terms">
          {" "}
          <li>Terms</li>
        </NavLink>
      </ul>
    </div>
  );
};

export default Blank;
