import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import PartnershipMarqee from "../../components/Marqee/PartnershipMarqee";
import LargeMarqee from "../../components/Marqee/LargeMarqee";
import women from "../../assets/img/Home/smileBusinessWomen.png";
import Boost from "../../components/Boost/Boost";
import Macbook from "../../components/Macbook/Macbook";
import Connect from "../../components/Connect/Connect";
import Slider from "../../components/Slider/Slider";
import HomeVideo1 from "../../assets/videos/HomeVideo1.mp4";
import HomeVideo2 from "../../assets/videos/homeVideos2.mp4";
import HomeVideo3 from "../../assets/videos/homeVideos3.mp4";
import HomeVideo4 from "../../assets/videos/homeVideos4.mp4";
import { Helmet } from "react-helmet-async";
import Modal from "../../components/Modal/Modal";
import HomeFaq from "../../components/Faq/HomeFaq";
import user from "../../assets/img/logo/user-account.jpg";
import "./Home.css";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [pop, setPop] = useState("flase");
  const showPop = () => {
    setPop("true");
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          width="150px"
          src="https://i.pinimg.com/originals/c7/e1/b7/c7e1b7b5753737039e1bdbda578132b8.gif"
        />
      </div>
    );
  }

  return (
    <div id="home">
      {<Modal />}

      <Helmet>
        <title>Home Page | Webapps Software Solutions </title>
        <meta name="copyright" content="https://webappssoft.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webappssoft.com/" />
        <link rel="next" href="https://webappssoft.com/" />
        <meta
          property="og:title"
          content="Property Dealers in Jamshedpur | Property Dealers Near Me"
        />
        <meta property="og:url" content="https://webappssoft.com/" />
        <meta
          property="og:image"
          content="https://webappssoft.com/logo/sy-logo.jpg"
        />
        <meta
          property="og:description"
          content="Property Dealers in Jamshedpur: Find 150+ Reliable real estate agents and Brokers in Jamshedpur. Contact residential and Commercial property consultants online."
        />
        <meta
          name="description"
          content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata"
        />
        <meta
          name="keywords"
          content="website design company India,web designing India, best web design company,top web design company,top web design companies India, web design and development company in india"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata"
        />
        <meta property="og:site_name" content="Webapps Software Solutions" />
        <meta
          name="twitter:description"
          content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata"
        />
        <meta
          name="twitter:title"
          content="Website Design & Development Company In Kolkata, India"
        />
        <meta
          property="og:image"
          content="https://webappssoft.com/assets/images/logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://webappssoft.com/assets/images/logo.png"
        />
        <meta name="twitter:site" content="webapps software Solutions" />
        <meta name="twitter:creator" content="@webappsoftwares" />
        <meta name="theme-color" content="#0098DA" />
        <meta
          name="author"
          content="WASS Integrated Tech Enable It Solutions"
        />
        <meta
          name="google-site-verification"
          content="3qKB507bM-zleASA7ZJYxnvl9L30Wk2o4KA7dZksurY"
        />
      </Helmet>

      <div
        className="pendinG_modal"
        style={{ display: pop === "true" ? "flex" : "none" }}
      >
        <div className="model_view">
          <div className="modal">
            <i className="bx bx-x" onClick={() => setPop("flase")}></i>

            <h1>We are working on this site, stay tuned we will be back.</h1>
            <img
              src="https://www.pharmabiz.com/Images/loading_spinner.gif"
              alt="img"
            />
          </div>
        </div>
      </div>

      {/* ================ Rotated Slider ============== */}

      <Macbook />
      <Slider />

      {/* ================= MARQUEEE ============= */}

      <div className="home_largeMaeqee">
        <LargeMarqee />
      </div>

      {/* ================= Text ============= */}

      <div className="hopmee_text">
        <h1>WASS gives Your Business a New Shape.</h1>

        <p>
          WASS organise plans for your company, our team has expertise to bring
          your ideas to life and push the boundaries of your brand’s online
          presence on a global scale.
        </p>

        <p>
          WASS is a dedicated team of more than 25 specialists working together.
          We arrange and simplify your project through our innovation, and offer
          you creative solutions as per your needs to make the project
          successful.
        </p>

        <p>
          Give your users a perfect experience and attract them through your
          strong online presence. Allow your brand to stand out in the digital
          world, where creativity makes a difference.
        </p>
      </div>

      {/* ================= VIDEOS ============= */}

      <div className="home_videos">
        <div className="home_videos__contents">
          <video src={HomeVideo1} autoPlay muted loop></video>
          <div className="video_overlay">
            <p className="home_videos__contents_year"></p>
            <div className="">
              <h1 className="home_videos__contents_title">Website</h1>
              <h1 className="home_videos__contents_title2">Development</h1>
            </div>

            <div className="number">
              <div className="arrow">
                <a target="_blank" href="https://wa.link/9sbw44">
                  <i className="bx bx-arrow-back"></i>
                </a>
              </div>
              <h1>01</h1>
            </div>
          </div>
        </div>

        <div className="home_videos__contents">
          <video src={HomeVideo2} autoPlay muted loop></video>
          <div className="video_overlay">
            <p className="home_videos__contents_year"></p>
            <div className="">
              <h1 className="home_videos__contents_title">Marketing & </h1>
              <h1 className="home_videos__contents_title2">Branding</h1>
            </div>
            <div className="number">
              <div className="arrow">
                <a target="_blank" href="https://wa.link/9sbw44">
                  <i className="bx bx-arrow-back "></i>
                </a>
              </div>
              <h1>02</h1>
            </div>
          </div>
        </div>

        <div className="home_videos__contents">
          <video src={HomeVideo3} autoPlay muted loop></video>
          <div className="video_overlay">
            <p className="home_videos__contents_year"></p>
            <div className="">
              <h1 className="home_videos__contents_title">Application </h1>
              <h1 className="home_videos__contents_title2">& Ui / UX</h1>
            </div>
            <div className="number">
              <div className="arrow">
                <a target="_blank" href="https://wa.link/9sbw44">
                  <i className="bx bx-arrow-back "></i>
                </a>
              </div>
              <h1>03</h1>
            </div>
          </div>
        </div>

        <div className="home_videos__contents">
          <video src={HomeVideo4} autoPlay muted loop></video>
          <div className="video_overlay">
            <p className="home_videos__contents_year"></p>
            <div className="">
              <h1 className="home_videos__contents_title">Media</h1>
              <h1 className="home_videos__contents_title2"> Solutions</h1>
            </div>
            <div className="number">
              <div className="arrow">
                <a target="_blank" href="https://wa.link/9sbw44">
                  <i className="bx bx-arrow-back"></i>
                </a>
              </div>
              <h1>04</h1>
            </div>
          </div>
        </div>
      </div>

      {/* ======= DESIGN UASER EXPERIENCEC =========== */}

      <div className="user_experiencey">
        <div className="wooman">
          <img src={women} alt="img" />
          <div className="wooman_overlay"></div>
        </div>

        <div className="copjntent">
          <h1>
            Our Team has Young, Skilled and Technically Enthusiastic Members
          </h1>

          <p>
            We create the complete strategy for your business, we bring
            solutions for project development, we use suitable technology to
            help Startups & Businesses transform their digital journey with
            WASS.
          </p>

          <div className="bttnmm">
            <button onClick={showPop}>
              Learn More <span>&#x2192;</span>
            </button>
          </div>
        </div>
      </div>

      {/* ========= 100 ELEMENTS ============= */}

      <div className="hElements">
        <div className="titlee">
          <h1>What make us </h1>
          <h1>different</h1>
        </div>
        <div className="hEmements_paragraph">
          <p>
            Data Analytics, Cloud Migration, User Experience and Design for
            Brands, Ecommerce Solution,Building Webapps from concept to reality
          </p>
        </div>
        <div className="elements_itemss">
          <Marquee direction="left" speed={40}>
            <div className="elems">
              <i className="bx bx-exit"></i>
              Call ToAction
            </div>

            <div className="elems">
              <i className="bx bx-hash"></i>
              Counter
            </div>

            <div className="elems">
              <i className="bx bx-category"></i>
              Categories
            </div>

            <div className="elems">
              <i className="bx bx-envelope"></i>
              Newsletter
            </div>

            <div className="elems">
              <i className="bx bx-user"></i>
              Team
            </div>

            <div className="elems">
              <i className="bx bx-hash"></i>
              Call ToAction
            </div>

            <div className="elems">
              <i className="bx bx-hash"></i>
              Call ToAction
            </div>

            <div className="elems">
              <i className="bx bx-exit"></i>
              Call ToAction
            </div>

            <div className="elems">
              <i className="bx bx-hash"></i>
              Counter
            </div>

            <div className="elems">
              <i className="bx bx-category"></i>
              Categories
            </div>

            <div className="elems">
              <i className="bx bx-envelope"></i>
              Newsletter
            </div>

            <div className="elems">
              <i className="bx bx-user"></i>
              Team
            </div>

            <div className="elems">
              <i className="bx bx-hash"></i>
              Call ToAction
            </div>

            <div className="elems">
              <i className="bx bx-hash"></i>
              Call ToAction
            </div>
          </Marquee>

          <Marquee direction="right" speed={35}>
            <div className="elems">
              <i className="bx bx-circle"></i>
              Circle Badge
            </div>

            <div className="elems">
              <i className="bx bx-link"></i>
              Animated Button
            </div>

            <div className="elems">
              <i className="bx bx-world"></i>
              Language Switcher
            </div>

            <div className="elems">
              <i className="bx bx-hash"></i>
              Call ToAction
            </div>

            <div className="elems">
              <i className="bx bx-hash"></i>
              Call ToAction
            </div>

            <div className="elems">
              <i className="bx bx-hash"></i>
              Call ToAction
            </div>

            <div className="elems">
              <i className="bx bx-hash"></i>
              Call ToAction
            </div>

            <div className="elems">
              <i className="bx bx-circle"></i>
              Circle Badge
            </div>

            <div className="elems">
              <i className="bx bx-link"></i>
              Animated Button
            </div>

            <div className="elems">
              <i className="bx bx-world"></i>
              Language Switcher
            </div>

            <div className="elems">
              <i className="bx bx-hash"></i>
              Call ToAction
            </div>

            <div className="elems">
              <i className="bx bx-hash"></i>
              Call ToAction
            </div>

            <div className="elems">
              <i className="bx bx-hash"></i>
              Call ToAction
            </div>

            <div className="elems">
              <i className="bx bx-hash"></i>
              Call ToAction
            </div>
          </Marquee>
        </div>
      </div>

      {/* ================= Boost Your Brand's Presence  ============= */}
      <Boost />

      {/* ========= WORK BRAND ========== */}

      <div className="home_partnerMarqee">
        <LargeMarqee />
      </div>

      <div className="wassBrands">
        <h1 className="wassBrands_title">
          Elevate Your Brand and Impact the Market
        </h1>

        <div className="home-driven">
          <p>
            As a trusted organisation, our primary focus is on customers’
            business goals. Our prime motive is to build a beautiful and
            high-potential design, where things are managed efficiently based on
            the theme of WASS expert designers. We execute extensive R&D to
            establish a successful business for a customer who wants to disrupt
            the industry with his vision.
          </p>

          <p>
            Creating powerful business software that truly grows the client's
            business from offline mode to online. Through the journey, we
            encounter many hurdles but we end up at the conclusion that we
            require a winning scenario for the client's business.
          </p>
        </div>

        {/* //-----[ --------------faq ------------] ----- */}

        <HomeFaq />

        <PartnershipMarqee />
      </div>

      {/* =======================  CONNECT =================== */}

      <Connect />
      {/* ================= YOU DESIGN WE BUILD ============= */}

      <div className=""></div>

      {/* ================= REVIEW ============= */}

      <div id="review">
        <div className="titile">
          <h1>
            What Our <span>Clients</span> Are Saying <span>About WASS</span>
          </h1>
        </div>
        <div className="review_content_1">
          <Marquee direction="left" speed="30" pauseOnHover={true}>
            <div className="review_content_boxes ">
              <img
                className="review_image"
                src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                alt="img"
              />

              <p>
                Webapp software solutions has been a fantastic web development
                company to work with. They are experts in their field and are
                always able to meet an ever-changing deadline while maintaining
                the highest level of quality.
              </p>

              <div className="peaple">
                <img src={user} alt="img" />
                <h4>Riddhi Driver Service Centre</h4>
              </div>
            </div>

            <div className="review_content_boxes bg-gradient">
              <img
                className="absolute"
                src="https://rainbowit.net/html/histudy/assets/images/icons/quote.svg"
                alt="img"
              />

              <img
                className="review_image"
                src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                alt="img"
              />

              <p>
                Office situated in good location. Marketing team have good
                response towards customers. All software engineers are well
                trained, identified the customer needs and provide solutions in
                very economic rate. I like their Sales after service.
              </p>

              <div className="peaple">
                <img src={user} alt="img" />
                <h4>Sakib Reza</h4>
              </div>
            </div>

            <div className="review_content_boxes ">
              <img
                className="review_image"
                src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                alt="img"
              />

              <p>
                Webapps software offers an incredible work experience that I
                highly recommend. The company provides a positive and engaging
                environment for its employees, fostering growth and
                collaboration
              </p>

              <div className="peaple">
                <img src={user} alt="img" />
                <h4>Parbati Das</h4>
              </div>
            </div>

            <div className="review_content_boxes bg-gradient">
              <img
                className="absolute"
                src="https://rainbowit.net/html/histudy/assets/images/icons/quote.svg"
                alt="img"
              />

              <img
                className="review_image"
                src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                alt="img"
              />

              <p>
                It's a very good company for startup and chip and best price
                solutions lords of ideas provided by this company and I am so
                happy with Him thanks
              </p>

              <div className="peaple">
                <img src={user} alt="img" />
                <h4>Vijay Kumar Yadav</h4>
              </div>
            </div>

            <div className="review_content_boxes ">
              <img
                className="review_image"
                src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                alt="img"
              />

              <p>
                Webapp software solutions has been a fantastic web development
                company to work with. They are experts in their field and are
                always able to meet an ever-changing deadline while maintaining
                the highest level of quality.
              </p>

              <div className="peaple">
                <img src={user} alt="img" />
                <h4>Riddhi Driver Service Centre</h4>
              </div>
            </div>

            <div className="review_content_boxes bg-gradient">
              <img
                className="absolute"
                src="https://rainbowit.net/html/histudy/assets/images/icons/quote.svg"
                alt="img"
              />

              <img
                className="review_image"
                src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                alt="img"
              />

              <p>
                Office situated in good location. Marketing team have good
                response towards customers. All software engineers are well
                trained, identified the customer needs and provide solutions in
                very economic rate. I like their Sales after service.
              </p>

              <div className="peaple">
                <img src={user} alt="img" />
                <h4>Sakib Reza</h4>
              </div>
            </div>

            <div className="review_content_boxes ">
              <img
                className="review_image"
                src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                alt="img"
              />

              <p>
                Webapps software offers an incredible work experience that I
                highly recommend. The company provides a positive and engaging
                environment for its employees, fostering growth and
                collaboration
              </p>

              <div className="peaple">
                <img src={user} alt="img" />
                <h4>Parbati Das</h4>
              </div>
            </div>

            <div className="review_content_boxes bg-gradient">
              <img
                className="absolute"
                src="https://rainbowit.net/html/histudy/assets/images/icons/quote.svg"
                alt="img"
              />

              <img
                className="review_image"
                src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                alt="img"
              />

              <p>
                It's a very good company for startup and chip and best price
                solutions lords of ideas provided by this company and I am so
                happy with Him thanks
              </p>

              <div className="peaple">
                <img src={user} alt="img" />
                <h4>Vijay Kumar Yadav</h4>
              </div>
            </div>
          </Marquee>
        </div>

        <div className="review_content_2">
          <Marquee direction="right" speed="30" pauseOnHover={true}>
            <div className="review_content_boxes ">
              <img
                className="review_image"
                src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                alt="img"
                loading="lazy"
              />

              <p>
                They have a small, but very dedicated team. They are good with
                their work. They always try to give their best for the service.
                You can try them if you want.
              </p>

              <div className="peaple">
                <img src={user} alt="img" loading="lazy" />
                <h4>Prince Kumar</h4>
              </div>
            </div>

            <div className="review_content_boxes bg-gradient">
              <img
                className="absolute"
                src="https://rainbowit.net/html/histudy/assets/images/icons/quote.svg"
                alt="img"
                loading="lazy"
              />

              <img
                className="review_image"
                src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                alt="img"
                loading="lazy"
              />

              <p>
                Amazing Experience with all team member they all are skilled to
                manage my project for exports & its software where there is lot
                many thing to say about my Experience in single word its
                fantastic
              </p>

              <div className="peaple">
                <img src={user} alt="img" loading="lazy" />
                <h4>Soni Kaur</h4>
              </div>
            </div>

            <div className="review_content_boxes ">
              <img
                className="review_image"
                src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                alt="img"
                loading="lazy"
              />

              <p>
                I was looking for the web developer had past experience was
                completely bad but with webapps i got my work done with there
                best team from day one thy had good in communication and
                commitment till ends.
              </p>

              <div className="peaple">
                <img src={user} alt="img" loading="lazy" />
                <h4>A K Pandit</h4>
              </div>
            </div>

            <div className="review_content_boxes bg-gradient">
              <img
                className="absolute"
                src="https://rainbowit.net/html/histudy/assets/images/icons/quote.svg"
                alt="img"
                loading="lazy"
              />

              <img
                className="review_image"
                src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                alt="img"
                loading="lazy"
              />

              <p>
                It's been excellent experience with webapps software solutions
                for my app development it's amazing team and well delivered my
                app on time with excellent uiux design.
              </p>

              <div className="peaple">
                <img src={user} alt="img" loading="lazy" />
                <h4>Parma Pandit</h4>
              </div>
            </div>

            <div className="review_content_boxes ">
              <img
                className="review_image"
                src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                alt="img"
                loading="lazy"
              />

              <p>
                They have a small, but very dedicated team. They are good with
                their work. They always try to give their best for the service.
                You can try them if you want.
              </p>

              <div className="peaple">
                <img src={user} alt="img" loading="lazy" />
                <h4>Prince Kumar</h4>
              </div>
            </div>

            <div className="review_content_boxes bg-gradient">
              <img
                className="absolute"
                src="https://rainbowit.net/html/histudy/assets/images/icons/quote.svg"
                alt="img"
                loading="lazy"
              />

              <img
                className="review_image"
                src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                alt="img"
                loading="lazy"
              />

              <p>
                Amazing Experience with all team member they all are skilled to
                manage my project for exports & its software where there is lot
                many thing to say about my Experience in single word its
                fantastic
              </p>

              <div className="peaple">
                <img src={user} alt="img" loading="lazy" />
                <h4>Soni Kaur</h4>
              </div>
            </div>

            <div className="review_content_boxes ">
              <img
                className="review_image"
                src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                alt="img"
                loading="lazy"
              />

              <p>
                I was looking for the web developer had past experience was
                completely bad but with webapps i got my work done with there
                best team from day one thy had good in communication and
                commitment till ends.
              </p>

              <div className="peaple">
                <img src={user} alt="img" loading="lazy" />
                <h4>A K Pandit</h4>
              </div>
            </div>

            <div className="review_content_boxes bg-gradient">
              <img
                className="absolute"
                src="https://rainbowit.net/html/histudy/assets/images/icons/quote.svg"
                alt="img"
                loading="lazy"
              />

              <img
                className="review_image"
                src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                alt="img"
                loading="lazy"
              />

              <p>
                It's been excellent experience with webapps software solutions
                for my app development it's amazing team and well delivered my
                app on time with excellent uiux design.
              </p>

              <div className="peaple">
                <img src={user} alt="img" loading="lazy" />
                <h4>Parma Pandit</h4>
              </div>
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default Home;
