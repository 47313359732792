import { useState, useEffect } from "react";
import slider1 from '../../assets/img/Home/Mobile_Slider/wass_banner_mob (1).jpeg'
import slider2 from '../../assets/img/Home/Mobile_Slider/wass_banner_mob (2).jpeg'
import slider3 from '../../assets/img/Home/Mobile_Slider/wass_banner_mob (3).jpeg'
import slider4 from '../../assets/img/Home/Mobile_Slider/wass_banner_mob (4).jpeg'
import "./Slider.css";

const slides = [
  {
    imageUrl:slider1,
    header: "Transforming Your Idea Digitally",
    content:
      "You think the way you are, we organize in digitally, as you imagined. It is time to transform everything to a digital way.",
  },
  {
    imageUrl:slider4,
    header: "Optimize, grow, and disrupt.",
    content:
      "Nothing is possible without you, your product will come to you, and if your idea prevails, we will both enhance the digital space.",
  },
  {
    imageUrl: slider3,
    header: "Trusted by 100 established organizations",
    content:
      "Loved by Establish Brand, we not only bring in an idea but also an impact on your product, WASS.",
  },
  {
    imageUrl: slider2,
    header: "Making an enormous impact on healthcare.",
    content:
      "Creating an Automation for Healthcare using Our Invotion & Technology evolves the ERA.",
  },

];

const Slider = () => {
  const [active, setActive] = useState(0);

  const handleTimeout = () => {
    setTimeout(() => {
      setActive((active + 1) % slides.length);
    }, 5000);
  };

  const handlePrev = () => {
    setActive(0);
  };

  const handleNext = () => {
    setActive((active + 1) % slides.length);
  };

  useEffect(() => {
    handleTimeout();
  }, [active, handleTimeout]);

  return (
    <div id="home-slider">
      <div className={`slider_content`}>
        
        {slides.map((slide, index) => (
          <div
            key={index}
            className="slider_box"
            style={{ display: active === index ? "flex" : "none" }}
          >

            <div className="img">
              <img src={slide.imageUrl} alt={`Slide ${index + 1}`} />
            </div>

            <div className="contasnt">

              <div className="header">
                <h2>{slide.header}</h2>
              </div>

              <div className={`item-overlay ${active}`}>
                <h4>{slide.content}</h4>
                {/* <button>Learn More</button> */}
              </div>


            </div>



          </div>
        ))}


        <div className="slider_control">
          <i className="bx bx-left-arrow-alt" onClick={handlePrev}></i>
          <i className="bx bx-right-arrow-alt" onClick={handleNext}></i>
        </div>
      </div>
    </div>
  );
};

export default Slider;
