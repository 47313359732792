import React, { useState } from "react";
import "./HomeFaq.css";
const HomeFaq = () => {
  const [select, setSelect] = useState("item1");
  const [nav, setNav] = useState("item1");
  const [classs, setClass] = useState("activea");

  return (
    <div id="home-faqs">
      <div className="home-faq-overlay">
        <div className="faq-left">
          <div className="outer-faq-head">
            <h2>Common Queries, Clear Answers</h2>
            <p>
              We believe in being transparent and we strive to provide you all
              the necessary information to keep you well-informed about our
              services.
            </p>
          </div>

          <div className="faq-querry-box">
            <div className="question-icon">
              <i className="bx bx-question-mark"></i>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>

            <h4>Still have questions ?</h4>
            <p>
              If the question is not available on our FAQ section, Feel free to
              contact us personally.
            </p>

            <a
              target="_blank"
              href="https://wa.link/9sbw44"
              style={{ width: "100%" }}
            >
              <button>
                <i className="bx bx-rocket"></i> Ask a Question
              </button>
            </a>
          </div>
        </div>

        <div className="faq-right">
          <div className="faq-head">
            <ul className="faq-op-list">
              <li
                onClick={() => {
                  setNav("item1");
                }}
                className={`items ${nav === "item1" && classs} `}
                style={{ background: nav === "item1" ? "orangered" : null }}
              >
                Website
              </li>

              <li
                onClick={() => {
                  setNav("item2");
                }}
                className={`items ${nav === "item2" && classs}`}
                style={{
                  background: nav === "item2" ? "orangered" : null,
                  color: nav === "item2" ? "#fff" : "#000",
                }}
              >
                UX Design
              </li>

              <li
                onClick={() => setNav("item3")}
                className={`items ${nav === "item3" && classs}`}
                style={{
                  background: nav === "item3" ? "orangered" : null,
                  color: nav === "item3" ? "#fff" : "#000",
                }}
              >
                Branding
              </li>

              <li
                onClick={() => setNav("item4")}
                className={`items ${nav === "item4" && classs}`}
                style={{
                  background: nav === "item4" ? "orangered" : null,
                  color: nav === "item4" ? "#fff" : "#000",
                }}
              >
                Mobile App
              </li>

              <li
                onClick={() => setNav("item5")}
                className={`items ${nav === "item5" && classs}`}
                style={{
                  background: nav === "item5" ? "orangered" : null,
                  color: nav === "item5" ? "#fff" : "#000",
                }}
              >
                IOT & SASS
              </li>
            </ul>
          </div>

          <div className="faq-section">
            {nav === "item1" && (
              <ul>
                <li>
                  {select === "item1" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item1")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>
                      What coding standards do webapps use for development ?
                    </h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item1" ? "flex" : "none" }}
                  >
                    <p>
                      We use the Agile Methodology to do the coding. We set the
                      target and conduct R&D, as we believe it is the foundation
                      to make a project scalable and stronger and we never rush
                      when it comes to stack adjustments.
                    </p>

                    <p>
                      We use technologies such as React Js, Vue JS, NextJS,
                      NestJS, Mongodb, MYSQLI, Nodejs, Python, React Native,
                      Angular, Blockchain, Swagger, AWS, Git, CICD, and etc.
                    </p>
                  </div>
                </li>

                <li>
                  {select === "item2" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item2")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>Which industries does WASS work with?</h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item2" ? "flex" : "none" }}
                  >
                    <p>
                      We have provided our services to various domains, starting
                      from Healthcare,Tech Co., E-Commerce, Quick Commerce,
                      Banking, Real Estate, providing the services of CRM, POS,
                      MIS, QSR, Blockchain, Data Analysis and Mobile App
                      Development.
                    </p>
                  </div>
                </li>

                <li>
                  {select === "item3" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item3")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>How can WASS help my business?</h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item3" ? "flex" : "none" }}
                  >
                    <p>
                      We can get your idea into reality with technology, we will
                      envision the big picture for your online presence.
                      Remember the name of WASS for any of our Web Services. We
                      serve businesses of all capacity to grow with new
                      technology.
                    </p>
                  </div>
                </li>

                <li>
                  {select === "item4" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item4")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>
                      Why should I build a business webapp as a tech partner?
                    </h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item4" ? "flex" : "none" }}
                  >
                    <p>
                      WASS is a decade old team of 25 members, in our tenure, we
                      have improved the growth of various businesses, we are
                      dedicated to work for your requirements, aiming to keep
                      your digital presence in its rightful place.
                    </p>
                  </div>
                </li>
              </ul>
            )}

            {nav === "item2" && (
              <ul>
                <li>
                  {select === "item1" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item1")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>Why Choose WASS for Designing?</h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item1" ? "flex" : "none" }}
                  >
                    <p>
                      We offer a complete program where we examine your
                      requirements with R&D, with in-depth Colour Analysis,
                      Image Adjustment, Style Harmony, and Animation. Our
                      approach is to keep critical components in highlight,
                      according to clients' business requirements.
                    </p>
                  </div>
                </li>

                <li>
                  {select === "item2" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item2")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>What are webapps' design practices?</h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item2" ? "flex" : "none" }}
                  >
                    <p>
                      We adhere to the principles while illustrating for your
                      business, with concise architecture, structured content,
                      straightforward navigation, and ease of movement around
                      the product for quick and easy access.
                    </p>

                    <p>
                      We intend to keep the interface sophisticated and
                      minimalist, in order to eliminate unnecessary complexity
                      and interruptions from the product viewing method.
                    </p>
                  </div>
                </li>

                <li>
                  {select === "item3" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item3")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>
                      Do these webapps present sample designs before development
                      begins?
                    </h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item3" ? "flex" : "none" }}
                  >
                    <p>
                      We present an initial layout to illustrate the idea, as
                      well as a representation of what we are doing and
                      presenting with you. Once your idea is envisioned, we will
                      discuss the aesthetics, and will finalise a sketch before
                      development , ensuring your idea will remain safe with us.
                    </p>
                  </div>
                </li>

                <li>
                  {select === "item4" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item4")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>What tools do webapps use to design our product?</h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item4" ? "flex" : "none" }}
                  >
                    <p>
                      Ensuring your design has a better UI/UX, we use tools such
                      as FIGMA, Adobe XD, InVision, Sketch, Adobe Photoshop and
                      others.
                    </p>
                  </div>
                </li>
              </ul>
            )}

            {nav === "item3" && (
              <ul>
                <li>
                  {select === "item1" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item1")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>What is the difference between branding and logo?</h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item1" ? "flex" : "none" }}
                  >
                    <p>
                      For branding it is essential to be technologically
                      up-to-date, to help develop the brand’s digital presence
                      to get into the target market with us because we are
                      making the brand with your presence of different
                      communications and crowds that are your clients. Logo is a
                      symbol in which a significant amount of audacity recalls
                      your thoughts via a figurative means. It also represents
                      remembrance.
                    </p>
                  </div>
                </li>

                <li>
                  {select === "item2" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item2")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>
                      What types of logos are suitable for corporate branding?
                    </h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item2" ? "flex" : "none" }}
                  >
                    <p>
                      Logo depends on the nature of the company, we recommend
                      suitability, structured models, we create a range of logos
                      that reflect your brand's story, such as wordmarks,
                      logotypes, letter marks, combinations signs, and many
                      more.
                    </p>
                  </div>
                </li>

                <li>
                  {select === "item3" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item3")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>How can I maintain my Brand value with WASS?</h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item3" ? "flex" : "none" }}
                  >
                    <p>
                      To ensure your brand's long-term viability, branding is
                      the only way to stay trendy and catch the audience's eye.
                    </p>
                    <p>
                      Loyalty is important to your brand, and it can only be
                      achieved by being consistent with advertisement. We need
                      to keep ads posted on different social networks, which are
                      now trending and hot channels to make your brand narrative
                      viral utilising social media. Make these guidelines
                      obtainable by everyone involved in promoting your brand.
                    </p>
                  </div>
                </li>

                <li>
                  {select === "item4" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item4")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>How essential branding to my online business?</h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item4" ? "flex" : "none" }}
                  >
                    <p>
                      randing facilitates building client’s interest and
                      loyalty, advising them to pick up your products, it is
                      essential for online business. We examine how customers
                      focus and respond to the elements to structure your brand
                      story. You can hire us to make your brand go viral through
                      our creativity.
                    </p>
                  </div>
                </li>
              </ul>
            )}

            {nav === "item4" && (
              <ul>
                <li>
                  {select === "item1" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item1")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>What is the basic cost for an app Development ?</h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item1" ? "flex" : "none" }}
                  >
                    <p>
                      There is no set price for App Development, as it all
                      relies on the project and the quality of work that is
                      required, once you reach out to us, you will receive a
                      detailed proposal by our team.
                    </p>
                  </div>
                </li>

                <li>
                  {select === "item2" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item2")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>
                      What code & Technology webapp will develop the apps ?
                    </h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item2" ? "flex" : "none" }}
                  >
                    <p>
                      We use the most recent technology for app development,
                      like React Native, FLutter, Java Xcode, Swift, and Android
                      for high-end apps. Additionally, we ensure that all
                      application flows adhere to the complaint report standards
                      while testing our application.
                    </p>

                    <p>
                      Our skills are in developing apps which have high-end
                      users with a traffic source that allows our team to
                      complete the tasks efficiently.
                    </p>
                  </div>
                </li>

                <li>
                  {select === "item3" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item3")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>
                      How is your app safe & Secure with End to End Data Points
                      ?
                    </h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item3" ? "flex" : "none" }}
                  >
                    <p>
                      We care about the safety and security of your data and
                      privacy while building your project, as a part of our
                      safety measurements, we use high-end 256, the MDB5 has
                      protection to Application Endpoint Data to secure your
                      information.
                    </p>

                    <p>
                      We collect data in our app from the secure interaction
                      keeping all information encrypted with a Hashing Key to
                      maintain confidentiality.
                    </p>
                  </div>
                </li>

                <li>
                  {select === "item4" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item4")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>
                      What if my app becomes slow, how will the webapps team
                      ensure that it moves faster?
                    </h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item4" ? "flex" : "none" }}
                  >
                    <p>
                      We rely on the most effective means of data protection and
                      smooth transmission API calls to speed up the App's
                      operation. We apply the MVP and MVVM techniques to create
                      the Application from the beginning till the end, with data
                      accepting micro & milliseconds that represent the data in
                      the app, every other aspect depends on the server.
                    </p>
                  </div>
                </li>
              </ul>
            )}

            {nav === "item5" && (
              <ul>
                <li>
                  {select === "item1" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item1")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>Why do create IoT and SaaS solutions as Webapps ?</h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item1" ? "flex" : "none" }}
                  >
                    <p>
                      Cross-Platform Compatibility: Web apps function on a
                      variety of operating systems and devices, ranging from
                      desktops to mobile. Users can access online apps instantly
                      without installing or downloading anything. Centralized
                      Management: IT specialists can manage users, updates, and
                      settings from a single cloud dashboard.
                    </p>
                    <p>
                      Global Accessibility: Web apps offer secure remote access
                      from any location in the world with just a browser.
                    </p>
                  </div>
                </li>

                <li>
                  {select === "item2" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item2")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>Why Choose Web Apps for IoT and SaaS Solutions ?</h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item2" ? "flex" : "none" }}
                  >
                    <p></p>

                    <p>
                      Scalability: Cloud-based web programs may easily scale
                      resources up or down in response to demand without the
                      need for physical infrastructure.
                    </p>

                    <p>
                      Automatic Updates: The vendor continuously updates web
                      apps, ensuring that consumers have the most recent
                      features and security fixes.
                    </p>

                    <p>
                      Lower costs: With a SaaS model, clients only pay for what
                      they use, eliminating the infrastructure and maintenance
                      costs associated with on-premises software.
                    </p>
                  </div>
                </li>

                <li>
                  {select === "item3" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item3")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>What is Software as a Service (SaaS)?</h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item3" ? "flex" : "none" }}
                  >
                    <p>
                      SaaS is a model in which software is hosted in the cloud
                      and supplied via the internet as a service rather than
                      being installed locally. Users access SaaS applications
                      via web browsers.
                    </p>
                  </div>
                </li>

                <li>
                  {select === "item4" ? (
                    <i
                      className="bx bx-x"
                      id="faq-cross"
                      onClick={() => setSelect("item")}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-plus faq-pluse"
                      onClick={() => setSelect("item4")}
                    ></i>
                  )}

                  <div className="question">
                    <h3>What is the Internet of Things (IoT)?</h3>
                  </div>

                  <div
                    className="ans"
                    style={{ display: select === "item4" ? "flex" : "none" }}
                  >
                    <p>
                      Apex offers a range of services including design,
                      engineering, and project management. We specialise in user
                      experience design, Web Development, Mobile App
                      Development, Customised Software Development, Branding and
                      Identity, and more.
                    </p>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFaq;
