import React from 'react'
import './TermsConditions.css'
import { Helmet } from 'react-helmet-async'
const TermsConditions = () => {
    return (
        <div id='termsConditions'>

<Helmet>
      <title>Terms & Conditions | Webapps Software Solutions </title>
        <meta name="copyright" content="https://webappssoft.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webappssoft.com/" />
        <link rel="next" href="https://webappssoft.com/" />
        <meta property="og:title" content="Property Dealers in Jamshedpur | Property Dealers Near Me" />
        <meta property="og:url" content="https://webappssoft.com/" />
        <meta property="og:image" content="https://webappssoft.com/logo/sy-logo.jpg" />
        <meta property="og:description" content="Property Dealers in Jamshedpur: Find 150+ Reliable real estate agents and Brokers in Jamshedpur. Contact residential and Commercial property consultants online." />
        <meta name="description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="keywords" content="website design company India,web designing India, best web design company,top web design company,top web design companies India, web design and development company in india" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta property="og:site_name" content="Webapps Software Solutions" />
        <meta name="twitter:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="twitter:title" content="Website Design & Development Company In Kolkata, India" />
        <meta property="og:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:site" content="webapps software Solutions" />
        <meta name="twitter:creator" content="@webappsoftwares" />
        <meta name="theme-color" content="#0098DA" />
        <meta name="author" content="WASS Integrated Tech Enable It Solutions" />
        <meta name="google-site-verification" content="3qKB507bM-zleASA7ZJYxnvl9L30Wk2o4KA7dZksurY" />
      </Helmet>

            <h2>Terms & Condition
                Revised W.E.F 1st April  2024</h2>


            <div className="box">
                <h3>Overview of our terms & condition</h3>

                <strong>Dear Clinets,</strong>
                <p>
                    This is with reference to your requirement for Website Development, Digital marketing & Application Development. Before we proceed we would like to give you a short introduction about Webapps Softwares Solution and the work done by us in the Real Estate, Education, OTT, Hospital, eCommerce, App Development Sector. Webapps Softwares Solution was established in the year 2014 and we introduce ourselves as a Consulting company providing IT and WEB services with end-to-end online business solutions that leverage technology. During the last 5 years, we have facilitated online businesses for more than 240 establishments by registering and hosting more than 640+ domains, designing more than 300 websites and applications, and managing more than 6,000 email accounts so far. We provide a full range of services including e-commerce web applications, software development, web hosting, domain registrations, internet marketing, search engine optimization, online marketing and branding, mobile apps development and social media optimization. We are certified Google Partners and offer various Google services to our clients, along with this we are award winners of the best web designer & top designer of freelance, associate with Bing & Makedigital era.
                </p>

                <strong>
                    We are @ Webapps Softwares Solution</strong>
                <p>
                    What we do comes from who we are and what we believe. If you believe that branding, marketing and sales promotion is a scientific exercise to be carried out with an accomplished partner… you are interacting with the Right One.
                    We are a full-fledged Digital Branding and communication agency dedicated in service of a diversified clientele. Be it any sector you are in, we have a nimble and autonomous team to carry out research, understand your business and product profile, target groups and define path-breaking Communication and Marketing strategies for you. We offer end to end solutions with a plethora of media mix under one roof.
                    There are several trumpets we can blow, but we keep the music low at our end, pumping up the volume only for our valued clientele. If crazy is your cuppa, we whip up a great creative cappuccino, on the other hand, if class speaks, we can make whispers do the talking for your brand.
                    Leave the philosophy to the prophets, use our Fill-osophy to fill your coffers. By accessing this website of webapps softwares solution. and confirming order with us, you acknowledge your acceptance of all the below mentioned webapps softwares solutions.
                </p>
            </div>

            <div className="box">
                <h3>Terms and Conditions:</h3>
                <p>All quotations and estimates provided by webapps softwares solutions are valid for a period of 7 days from date of issue. Quotations not accepted within this timeframe must be re-issued. All quotations are required to be accepted using the supplied Quotation Acceptance Form and returned to webapps softwares solution within the 7 day period from date of issuance. All quoted prices, excluding where indicated, do not include Tax. Estimates may be provided by webapps softwares solutions. to offer the client a guide on the projected costing of a project prior to any discovery or research for said project. All estimates will be clearly marked as such and are not an indication of the exact final cost to develop the application. All estimates will need to be formalized to a quotation or invoice before acceptance by either party as the final cost of the application. webapps softwares solution reserves the right to suspend the services/quotation at any time, without any prior information.</p>
            </div>

            <div className="box">
                <h3>PAYMENT TERMS:</h3>
                <p>All quotations provided by webapps softwares solution, require a 50% deposit upon acceptance. Unless prior arrangement has been made, final payment is strictly net 10 days from the date of completion. Any cost arising from payment clearings or transaction charges are solely the responsibility of the client and will be charged as such. webapps softwares solution. will only commence work on the quoted application once any deposited funds have cleared. The customer will not be entitled for any service in case of delay in payment for more than 10 days from the final date of installation / date of project / module completion.
                    If opted for service beyond the 12 months of maintenance period or as agreed by webapps softwares solution , The Annual Maintenance Charges (AMC) will be normally applicable 80% (PERCENT) of original development cost of Project / Module; each year the development cost for new modules will be added to the initial development cost for the calculation of the AMC.</p>
                <p>
                    The AMC percentage shall be decided by the webapps softwares solution which depends upon the amount of effort and work required. This % may vary each year. The recurring / renewal amount of the domain and hosting are subjected to change as per the market rates. webapps softwares solution. disclaims all warranties or conditions, whether expressed or implied, (including without limitation implied, warranties or conditions of information and context). We consider ourselves and intend to be subject to the jurisdiction only of the courts of kolkata West Bengal India.
                </p>
            </div>

            <div className="box">
                <h3>The AMC Charges</h3>

                <p>
                    is not included with the service name “Digital Marketing & Brand Promotion” hance this terms & Condition will only apply for the Software Development, App Development, Website Development & its Maintenance of the work. We generally give all the access to the software where we can make all your requirements in one place for every digital needs.
                </p>
            </div>

            <div className="box">
                <h3>Digital Marketing & web App Development Terms</h3>

                <p>For digital marketing make it fix the target keyword which is comfortable with your business once we accept it. We can't change the keyword till the end of the Digital marketing because it is part of a domain already signed with the services so it will impact on the efforts & charges for marketing.</p>

                <p>For the web & App Development if you feel that my work is not completing on time in the phase 1st you can ask the executive for clarification about the delay, If the answer is not satisfactory that you can change your mind to get a refund from our end. wor refund policy is in the development phase that you can as in the 1st phase of the starting initiation of your quotation & request back for the refund. we assure you our supportive team will make your refund in the 45-60 days from the date of cancellation of work in your respective account, But if you & we have reached the mid of the application with running / working of any development of API / backend that time you can understand why you are canceling the project we can't give you any kind of refund for the application/Website/Software/ERP/CRM at the mid of development.</p>

                <p>hence we can’t process any kind of refund to your project because of any reason or any kind of uncertain situation between you & your project here we are clearing that in 1st phase of development you are satisfied with us but in the next development phase you are canceling the project that we cant process further we are really sorry for your all your aspects related to project. If we are not able / capable to do this project we directly make your amount refund in the next 7-10 days.</p>

            </div>

            <div className="box">
                <h3>Minimum requirements</h3>

                <p>All requirements must follow on mail only | we can discuss over the call from our development team end you just need to login the CRM and Cleck from the Portal of
                    {" "}<strong>CONTENT</strong>{" "}
                    Clients are required to ensure that the content of the application being quoted adheres to all the current Indian legislation regarding publication. The client shall further indemnify webapps softwares solution in respect of any claims, costs and expenses that may arise from any material included within the quoted application by webapps softwares solution at the client's request. webapps softwares solution reserves the right not to include any material supplied by the client within the quoted application if webapps softwares solution deems said material inappropriate or offensive.
                    webapps softwares solution will not populate the application with the final content unless said content is delivered to the webapps softwares solution in digital format prior to commencement of work. Said content, if available, will be used for testing purposes and may not be formatted how the client requires it. If content is not available mock placeholder content will be used. It is the client’s responsibility, in all cases, to ensure the applications content is displayed and formatted as they require. If the client cannot format the applications content, webapps softwares solution will offer this service at webapps softwares solution ‘ current hourly rate at the time of the request.</p>
            </div>

            <div className="box">
                <h3>PERMISSIONS AND COPYRIGHTS</h3>

                <p>The client will obtain all necessary permissions and authorities with respect to the use of all copy, graphics, logos, names and trademarks and any other material supplied by the client to webapps softwares solution. Supply of said material by the client to webapps softwares solution shall be regarded as a guarantee from the client that all such permissions and authorities have been sought and obtained for said material. No responsibility will be accepted by webapps softwares solution for damages or losses incurred by the client from the use of material for which permission or authority has not been obtained.</p>

                <p>ERRORS AND LIABILITIES webapps softwares solution will pursue due care to ensure applications created by webapps softwares solutions are free of errors. webapps softwares solution will correct any errors made by webapps softwares solution staff in the undertaking of the quoted application. webapps softwares solution does not accept responsibility for losses or damage arising from errors within any application. webapps softwares solution does not accept responsibility for errors, damages, losses or additional costs that relate to third party products that webapps softwares solution may require completing the quoted application.</p>

                <p>The client will obtain all necessary permissions and authorities with respect to the use of all copy, graphics, logos, names and trademarks and any other material supplied by the client to webapps softwares solution. Supply of said material by the client to webapps softwares solution shall be regarded as a guarantee from the client that all such permissions and authorities have been sought and obtained for said material. No responsibility will be accepted by webapps softwares solution for damages or losses incurred by the client from the use of material for which permission or authority has not been obtained.</p>

            </div>

            <div className="box">
                <h3>ALTERATIONS</h3>

                <p>
                    Any alterations requested by the client after development has begun will incur extra development and regression testing time. Dependent upon the alteration or change requested an average of 3 days extra development time per alteration should be allowed for. The 3 day average may not be indicative of the time required and can be extended commensurate of the time involved to implement said changes. webapps softwares solution will not accept responsibility for any alterations performed by the client or any third party which may cause or induce errors within the quoted application. If webapps softwares solution are required to correct said alterations or errors resulting from said alterations, induced, injected or otherwise caused by parties other than webapps softwares solution , the client will be charged at the hourly rate that is current for webapps softwares solution at the time said errors are to be fixed.
                </p>

                <p>
                    All timeframes offered by webapps softwares solutions to the client are estimates. The intrinsic nature of software development and its intricacies do not offer webapps softwares solutions the luxury of defining definite timeframes. webapps softwares solution will endeavor to complete all work within the estimated time frames discussed with the client in the quotation. However, webapps softwares solutions will not be liable for any penalties, monies or hardships otherwise incurred by the client if the application cannot be delivered within the estimated timeframe. webapps softwares solution will not release the quoted application unless all payments have been met under the obligations of the quotation or work agreement. If webapps softwares solution does not have control over the residence (see Residency Requirements) where the finished work will reside then full payment must be made prior to said work being released by webapps softwares solution.
                </p>
                <p>
                    The quoted application remains the property of webapps softwares solution until all obligations have been met for release of said application to the client. If a webapps softwares solution is working as a third party to another company, said company is responsible for meeting the obligations for release of the quote application to their client.
                </p>
            </div>

            <div className="box">
                <h3>CHANGES TO SITE AND THESE TERMS AND CONDITIONS</h3>

                <p>Any alterations requested by the client after development has begun will incur extra development and regression testing time. Dependent upon the alteration or change requested an average of 3 days extra development time per alteration should be allowed for. The 3 day average may not be indicative of the time required and can be extended commensurate of the time involved to implement said changes. webapps softwares solution will not accept responsibility for any alterations performed by the client or any third party which may cause or induce errors within the quoted application. If webapps softwares solution are required to correct said alterations or errors resulting from said alterations, induced, injected or otherwise caused by parties other than webapps softwares solution , the client will be charged at the hourly rate that is current for webapps softwares solution at the time said errors are to be fixed.</p>

                <p>
                    This Site and these webapps softwares solutions. Terms and Conditions may be amended, revised, changed, updated or modified by webapps softwares solution with or without notice.
                </p>

                <p>
                    Please review this link on a regular basis for changes. Continued use of this Site following any change to the webapps softwares solution Terms and Conditions constitute your acceptance of any such change to the webapps softwares solution Terms and Conditions.
                </p>

            </div>

            <div className="box">
                <p>

                    Please Do Make A Note Of It That We Are Using CRM To Solve Your Project Issue / Defect / Please Do Raise The Ticket And Get A Call Back From Our Executive Of The Related Project We Are Pleasure To Serve You Better With Our Teach Team. Our Calling Number Is Changed From Date 1st April 2021 Its

                </p>

                <div className="call">
                    <i className='bx bx-phone-call'></i>
                    <p>03379647522</p>
                </div>

                <div className="open">
                    <i className='bx bx-door-open'></i>
                    <p>Monday - Friday 11:00 AM - 6:00 PM</p>
                </div>


            </div>



        </div>
    )
}

export default TermsConditions
