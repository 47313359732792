import { createSlice } from "@reduxjs/toolkit";
import { blogContent } from "../db/data";

const root = createSlice({
  name: "root",
  initialState: {
    value: blogContent,
  },
  reducers: {},
});

export default root.reducer;
