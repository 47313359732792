import React from "react";
import "./PricingCard.css";
const Cards = () => {
  return (
    <section className="plans__container">
      <div className="plans">
        
        <div className="planItem__container">
          {/* <!--free plan starts --> */}
          <div className="planItem planItem--free">
            <div className="pricing_card">
              <div className="card__header">
                <h2>Free</h2>
              </div>
            </div>

            <div className="price">
              $0<span>/ month</span>
            </div>

            <ul className="featureList">
              <li>2 links</li>
              <li>Own analytics platform</li>
              <li className="disabled">Chat support</li>
              <li className="disabled">Mobile application</li>
              <li className="disabled">Unlimited users</li>
            </ul>

            <button className="button">Get Started</button>
          </div>
          {/* <!--free plan ends -->

      <!--pro plan starts --> */}
          <div className="planItem planItem--pro">
            <div className="pricing_card">
              <div className="card__header">

                <h2>Pro</h2>
               
              </div>
             
            </div>

            <div className="price">
              $18<span>/ month</span>
            </div>

            <ul className="featureList">
              <li>2 links</li>
              <li>Own analytics platform</li>
              <li>Chat support</li>
              <li className="disabled">Mobile application</li>
              <li className="disabled">Unlimited users</li>
            </ul>

            <button className="button button--pink">Get Started</button>
          </div>
          {/* <!--pro plan ends -->

      <!--entp plan starts --> */}
          <div className="planItem planItem--entp">
            <div className="pricing_card">
              <div className="card__header">
              
                <h2 className="enterprise">Enterprise</h2>
              </div>
              
            </div>

            <div className="price">Let's Talk</div>

            <ul className="featureList">
              <li>2 links</li>
              <li>Own analytics platform</li>
              <li>Chat support</li>
              <li>Mobile application</li>
              <li>Unlimited users</li>
             
            </ul>

            <button className="button button--white">Get Started</button>
          </div>
          {/* <!--entp plan ends --> */}
        </div>
      </div>
    </section>
  );
};

export default Cards;
