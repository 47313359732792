import React from "react";
import svg from "../../assets/img/logo/logo.svg";
import Bangalore from "../../assets/img/Footer/bang.png";
import pune from "../../assets/img/Footer/pune.png";
import Mumbai from "../../assets/img/Footer/mumbai.avif";
import Kolkata from "../../assets/img/Footer/kolk.avif";
import "./Footer.css";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div id="footer">
      <div className="footer_absolute__content">
        <div className="footer_absolute__items">
          <div className="footer_absolute__items__boxes">
            <div className="circle"></div>

            <img src={Mumbai} alt="img" />
            <div className="office">
              <h3>Mumbai</h3>
              <p>Office No 1015 Atharava Business  Park Goregaon Mumbai</p>
            </div>
          </div>

          <div className="footer_absolute__items__boxes">
            <div className="circle"></div>
            <img src={pune} alt="img" />
            <div className="office">
              <h3>Pune</h3>
              <p>office no 801, lane no 3, kharadi Pune, MH </p>
            </div>
          </div>

          <div className="footer_absolute__items__boxes">
            <div className="circle"></div>
            <img src={Kolkata} alt="img" />
            <div className="office">
              <h3>Kolkata</h3>
              <p>1st floor 128 Jodhpur Garden, Opt South City Mall, Kolkata</p>
            </div>
          </div>

          <div className="footer_absolute__items__boxes">
            <div className="circle"></div>
            <img src={Bangalore} alt="img" />
            <div className="office">
              <h3>Bangalore</h3>
              <p>3rd Floor 109 Radiant Heritage Rayasandra Villandra  Bangalore </p>
            </div>
          </div>
        </div>
      </div>

      <div className="wass__social__links">
        <div className="wass_social__links__items">
          <div className="footer_logo">
            <NavLink to='/'>
            <img style={{ width: "124px" }} src={svg} alt="" />
            </NavLink>
          </div>
        </div>

        <div className="wass_social__links__items">
            {" "}
            <a href="https://maps.app.goo.gl/AuxM7gpmrx8NNcT86" target="_blank">
            1st floor 128 Jodhpur Gardens, Opt South City Mall, Kolkata 700045{" "}
            </a>
          {/* <small>West bengal 700045</small> */}
        </div>

        <div className="wass_social__links__items">
          
        <a href="mailto:info@webappssoft.com" target="_blank">info@webappssoft.com </a>
        <a href="mailto:support@webappssoft.com" target="_blank">support@webappssoft.com</a>

  
        </div>

        <div className="wass_social__links__items">
          <small>
            <div className="link_icons">
              <a target="_blank" href="https://www.facebook.com/webappssoftwaresolutions">
                <i className="bx bxl-facebook"></i>
              </a>
            </div>
            <div className="link_icons">
              <a target="_blank" href="https://instagram.com/webappssoftware">
                <i className="bx bxl-instagram-alt"></i>
              </a>
            </div>
            <div className="link_icons">
              <a target="_blank" href="https://twitter.com/webappsoftwares">
                <i className="bx bxl-twitter"></i>
              </a>
            </div>
            <div className="link_icons">
              <a target="_blank" href="https://www.linkedin.com/company/webapps-softwares-solutions/">
                <i className="bx bxl-linkedin-square"></i>
              </a>
            </div>
            
            <div className="link_icons">
              <a href="#">
                <i className="bx bxl-dribbble"></i>
              </a>
            </div>
            <div className="link_icons">
              <a target="_blank" href="https://www.behance.net/TECHWAASS ">
                <i className="bx bxl-behance"></i>
              </a>
            </div>
          </small>
        </div>
      </div>

      <div className="footer_patragraph">
        {/* <p>
          WebApps Software Solution We propose a unique model of ‘Communication
          Co-creation. It is a form of market or business strategy that
          emphasizes the greater realization of the relationship between a brand
          & its customers. It treats markets as forums for brands and active
          customers to unite, share and renew each other's resources and
          capabilities to create value through new forms of interaction, service
          and learning mechanisms. This kind of communication intends to involve
          the consumers in brand communication. It discovers innovative ways to
          connect with the target groups and make them respond to the brand. The
          entire objective is to uplift the customers from the ‘PASSIVE’ mode
          and make them active participants in brand communication.
        </p> */}

        {/* <p>
          There are several trumpets we can blow, but we keep the music low at
          our end, pumping up the volume only for our valued clientele. If crazy
          is your cuppa, we whip up a great creative cappuccino, on the other
          hand, if class speaks, we can make whispers do the talking for your
          brand. Leave the philosophy to the prophets, use our Fill-osophy to
          fill your coffers.
        </p> */}

        {/* <p>
          Before we proceed we would like to give you a short introduction to
          Webapps Software Solutions and the work done by us in the Grocery &
          Delivery sector. Webapps Softwares Solution was established in the
          year 2015 and we introduce ourselves as a Consulting company providing
          IT and WEB services with end-to-end online business solutions that
          leverage technology. During the last 7 years, we have facilitated
          online businesses for more than 250 establishments by registering and
          hosting more than 1500 domains, designing more than 400 websites and
          applications, and managing more than 6,800 email accounts so far. We
          provide a full range of services including e-commerce web
          applications, software development, web hosting, domain registrations,
          internet marketing, search engine optimization, online marketing and
          branding, mobile apps development, and social media optimization. We
          are certified Google Partners and offer various Google services to our
          clients.
        </p> */}
      </div>

      {/* <div className="footer_services">
        <div className="footer_services__items">
          <h5>App Development</h5>

          <ul>
            <li>App Developer In Bangalore</li>
            <li>App Developer In Kolkata</li>
            <li>IOS App Developer In Bangalore</li>
            <li>Social Media App Developer In Kolkata</li>
          </ul>
        </div>
        <div className="footer_services__items">
          <h5>Web Development</h5>

          <ul>
            <li>Web Developer In Bangalore</li>
            <li>Web Development In Kolkata</li>
            <li>Web Designer in Bangalore</li>
            <li>Website Developer Bangalore</li>
          </ul>
        </div>
        <div className="footer_services__items">
          <h5>Digital Marketing</h5>

          <ul>
            <li>Digital Marketing Company in Bangalore</li>
            <li>Digital Marketing Company in Kolkata</li>
            <li>Best Advertising Agencies inKolkata</li>
            <li>SMM Agency In Kolkata</li>
          </ul>
        </div>
        <div className="footer_services__items">
          <h5>Custom Software</h5>

          <ul>
            <li>Recharge Software Developer In Kolkata</li>
            <li>Fitness App Developer In Kolkata</li>
            <li>Real-Estate App Developer in kolkata</li>
            <li>Tele Medicine App Developer in kolkata</li>
          </ul>
        </div>
      </div> */}

      <h5 className="quike_links">Quick Links</h5>

      <div className="quike_links__links">
        <p>
          <NavLink to="/policy">Privicy Policy</NavLink>
        </p>
        <p>
          <NavLink to="/stack">Code Stack</NavLink>
        </p>
 
        <p>
          <NavLink to="/mobileportfolio">Portfolio</NavLink>
        </p>
        <p>
          <NavLink to="/blog">Blog</NavLink>
        </p>
        <p>
          <NavLink to="/team">Team</NavLink>
        </p>
        <p>
          <NavLink to="/news">News</NavLink>
        </p>
        <p>
          <NavLink to="/contect">Contact</NavLink>
        </p>
        <p>
          <NavLink to="/terms">Terms & Condition</NavLink>
        </p>

      </div>

      <div className="copyright">
        © 2014-2024 <a href="">WASS</a> all wrong reserved.  Various trademarks held by their respective owners.
      </div>
    </div>
  );
};

export default Footer;
