import React, { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import "./Boost.css";

//Logo
import logo1 from "../../assets/img/Home/Boost/Slide1/icon.png";
import logo2 from "../../assets/img/Home/Boost/Slide2/logo.png";
import logo3 from "../../assets/img/Home/Boost/Slide3/logo.png";
import logo4 from "../../assets/img/Home/Boost/Slide4/logo.png";
import logo5 from "../../assets/img/Home/Boost/Slide5/logo.png";
import logo6 from "../../assets/img/Home/Boost/Slide6/logo.png";

//BG
import SliderBG1 from "../../assets/img/Home/Boost/Slide1/bg.png";
import SliderBG2 from "../../assets/img/Home/Boost/Slide2/bg.png";
import SliderBG3 from "../../assets/img/Home/Boost/Slide3/bg.png";
import SliderBG4 from "../../assets/img/Home/Boost/Slide4/bg.png";
import SliderBG5 from "../../assets/img/Home/Boost/Slide5/bg.png";
import SliderBG6 from "../../assets/img/Home/Boost/Slide4/bg.png";

// Slider Banner
import helthCare1 from "../../assets/img/Home/Boost/Slide1/nurse.png";
import helthCare2 from "../../assets/img/Home/Boost/Slide1/lab-glass.png";
import helthCare3 from "../../assets/img/Home/Boost/Slide1/scope.png";

import jobportal1 from "../../assets/img/Home/Boost/Slide2/hiring.png";
import jobportal2 from "../../assets/img/Home/Boost/Slide2/lab-glass.png";
import jobportal3 from "../../assets/img/Home/Boost/Slide2/scope.png";

import edTech1 from "../../assets/img/Home/Boost/Slide3/computer.png";
import edTech2 from "../../assets/img/Home/Boost/Slide3/ball.png";
import edTech3 from "../../assets/img/Home/Boost/Slide3/book.png";

import realEstd1 from "../../assets/img/Home/Boost/Slide4/men.png";
import realEstd2 from "../../assets/img/Home/Boost/Slide4/tree.png";

import helth1 from "../../assets/img/Home/Boost/Slide5/women.png";
import helth2 from "../../assets/img/Home/Boost/Slide5/top1.png";
import helth3 from "../../assets/img/Home/Boost/Slide5/top2.png";
import helth4 from "../../assets/img/Home/Boost/Slide5/mat.png";

const Boost = () => {
  const [active, setActive] = useState(1);
  const [bgColor, setBgColor] = useState(SliderBG1);

  useEffect(() => {
    const timer = setTimeout(() => {
      setActive((prevActive) => (prevActive === 6 ? 1 : prevActive + 1));
    }, 10000);

    switch (active) {
      case 1:
        setBgColor(SliderBG1);
        break;
      case 2:
        setBgColor(SliderBG2);
        break;
      case 3:
        setBgColor(SliderBG1);
        break;
      case 4:
        setBgColor(SliderBG4);
        break;
      case 5:
        setBgColor(SliderBG5);
        break;
      case 6:
        setBgColor(SliderBG6);
        break;
      default:
        setBgColor(SliderBG1);
    }

    return () => clearTimeout(timer);
  }, [active]);

  return (
    <div id="boost">
      <div className="boost_content">
        <div className="content-container">
          <div className="box" style={{ backgroundImage: `url(${bgColor})` }}>
            <div
              className="item_data"
              style={{ display: active === 1 ? "flex" : "none" }}
            >
              <div className="images item1">
                <img alt="img" loading="lazy" src={helthCare1} />
                <img alt="img" loading="lazy" src={helthCare2} />
                <img alt="img" loading="lazy" src={helthCare3} />
              </div>

              <div className="text">
                <h4>Healthcare Software</h4>
                <h1>
                  Healthcare Industry needs digital backing to grow faster.
                </h1>

                <p>
                  Hospitals and Clinics require easy tools for staff
                  allocations, ward management, shift and employee management to
                  automatically construct the best schedule and call on agencies
                  for additional support staff as needed.
                </p>

                <p className="no-ne">
                  Pharmacy and lab management software Monitor lab and pharmacy
                  inventory. Your employees may digitally sign out supplies as
                  they use them, and you receive real-time automated stock
                  updates. Connect with WASS to build an App for your
                  requirement.
                </p>

                <div className="boost_button">
                  <a target="_blank" href="https://wa.link/9sbw44">
                    <button>
                      Schedule a Call <span> &#x2192; </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="item_data "
              style={{ display: active === 2 ? "flex" : "none" }}
            >
              <div className="images item2">
                <img loading="lazy" alt="img" src={jobportal1} className="" />
                <img loading="lazy" alt="img" src={jobportal2} className="" />
                <img loading="lazy" alt="img" src={jobportal3} className="" />
              </div>

              <div className="text">
                <h4>Job Portal Apps & CMS</h4>
                <h1>Job Search Portals Development</h1>

                <p>
                  Searching and recruiting the best-suited and qualified
                  candidates may seem difficult, so as a result, many are
                  engaged in job portals for search, we would create a such
                  portal that would be useful by both employers and job seekers,
                  it’ll guide applicants through all aspects of job searching
                  and related practices.
                </p>

                <p className="no-ne">
                  The project will be intended for the employers and the
                  candidates, it has speedy, safe, and easy-to-use features,
                  where candidates can register easily.
                </p>

                <div className="boost_button">
                  {" "}
                  <a target="_blank" href="https://wa.link/9sbw44">
                    <button>
                      Schedule a Call <span> &#x2192; </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="item_data"
              style={{ display: active === 3 ? "flex" : "none" }}
            >
              <div className="images item3">
                <img loading="lazy" alt="img" src={edTech1} className="" />
                <img loading="lazy" alt="img" src={edTech2} className="" />
                <img loading="lazy" alt="img" src={edTech3} className="" />
              </div>

              <div className="text">
                <h4>Edtech Software Development </h4>

                <h1>Let's work together to create excellent LMS solutions!</h1>

                <p>
                  LMS is skilled at creating interactive interfaces using unique
                  concepts, innovations, and new approaches to provide the best
                  user experiences to clients.
                </p>

                <p className="no-ne">
                  Our QA professionals execute end-to-end testing, utilising
                  multiple Quality Assurance approaches to ensure that software
                  solutions are delivered with the highest level of quality,
                  security, and privacy.
                </p>

                <div className="boost_button">
                  {" "}
                  <a target="_blank" href="https://wa.link/9sbw44">
                    <button>
                      Schedule a Call <span> &#x2192; </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="item_data"
              style={{ display: active === 4 ? "flex" : "none" }}
            >
              <div className="images item4">
                <img loading="lazy" alt="img" src={realEstd1} className="" />
                <img loading="lazy" alt="img" src={realEstd2} className="" />
              </div>

              <div className="text">
                <h4>Real Estate</h4>

                <h1>Renewable generated software</h1>

                <p>
                  We plan and create applications for Real Estate Business and
                  Functions, Process, and Budget. Recent studies of consumer
                  behaviour show that residential app development has never been
                  in such great demand.
                </p>

                <p className="no-ne">
                  Question remains, how do you get a developed real estate app?
                  According to a report published by the Center for Real Estate
                  Technology & Innovation (CRETI) some of the most basic and
                  important solutions include eSignature, a local MLS app,
                  social networking sites, a lockbox, streaming video, CRM,
                  handling transactions, and show/open homes software.
                </p>

                <div className="boost_button">
                  {" "}
                  <a target="_blank" href="https://wa.link/9sbw44">
                    <button>
                      Schedule a Call <span> &#x2192; </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="item_data"
              style={{ display: active === 5 ? "flex" : "none" }}
            >
              <div className="images item5">
                <img loading="lazy" alt="img" src={helth1} className="" />
                <img loading="lazy" alt="img" src={helth2} className="" />
                <img loading="lazy" alt="img" src={helth3} className="" />
                <img loading="lazy" alt="img" src={helth4} className="" />
              </div>

              <div className="text">
                <h4>Fitness Club software</h4>

                <h1>Discover Numerous Digital features Fitness App.</h1>

                <p>
                  Staying healthy has become a priority in today’s time, people
                  are becoming more health-conscious, this change has caused a
                  hike in Fitness App demands, as people use them to maintain a
                  healthy lifestyle. Here WASS helps the fitness Industry to
                  improve their business according to the market requirements.
                </p>

                <p className="no-ne">
                  With our unique fitness apps for Fitbit, Strava, and
                  MyFitnessPal, you can reach millions of health-conscious
                  individuals. Transform your fitness app idea into a fully
                  functional and dynamic fitness solution.
                </p>

                <div className="boost_button">
                  {" "}
                  <a target="_blank" href="https://wa.link/9sbw44">
                    <button>
                      Schedule a Call <span> &#x2192; </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="boost_bottom">
        <Marquee direction="right" pauseOnHover={true}>
          <div
            className="boost_bottom-box"
            onClick={() => setActive(1)}
            style={{ opacity: active === 1 ? 1 : 0.4 }}
          >
            <img src={logo1} alt="img" />
            <h2>Healthcare Industries</h2>
          </div>

          <div
            className="boost_bottom-box"
            onClick={() => setActive(2)}
            style={{ opacity: active === 2 ? 1 : 0.4 }}
          >
            <img src={logo2} alt="img" />
            <h2>JOB PORTAL APP & CMS</h2>
          </div>

          <div
            className="boost_bottom-box"
            onClick={() => setActive(3)}
            style={{ opacity: active === 3 ? 1 : 0.4 }}
          >
            <img src={logo3} alt="img" />
            <h2>Ed-Tech Software</h2>
          </div>

          <div
            className="boost_bottom-box"
            onClick={() => setActive(4)}
            style={{ opacity: active === 4 ? 1 : 0.4 }}
          >
            <img src={logo4} alt="img" />
            <h2>Real Estate & Agents</h2>
          </div>

          <div
            className="boost_bottom-box"
            onClick={() => setActive(5)}
            style={{ opacity: active === 5 ? 1 : 0.4 }}
          >
            <img src={helth1} alt="img" />
            <h2>Fitness</h2>
          </div>

          <div
            className="boost_bottom-box"
            onClick={() => setActive(1)}
            style={{ opacity: active === 1 ? 1 : 0.4 }}
          >
            <img src={logo1} alt="img" />
            <h2>Healthcare Industries</h2>
          </div>

          <div
            className="boost_bottom-box"
            onClick={() => setActive(2)}
            style={{ opacity: active === 2 ? 1 : 0.4 }}
          >
            <img src={logo2} alt="img" />
            <h2>JOB PORTAL APP & CMS</h2>
          </div>

          <div
            className="boost_bottom-box"
            onClick={() => setActive(3)}
            style={{ opacity: active === 3 ? 1 : 0.4 }}
          >
            <img src={logo3} alt="img" />
            <h2>Ed-Tech Software</h2>
          </div>

          <div
            className="boost_bottom-box"
            onClick={() => setActive(4)}
            style={{ opacity: active === 4 ? 1 : 0.4 }}
          >
            <img src={logo4} alt="img" />
            <h2>Real Estate & Agents</h2>
          </div>

          <div
            className="boost_bottom-box"
            onClick={() => setActive(5)}
            style={{ opacity: active === 5 ? 1 : 0.4 }}
          >
            <img src={helth1} alt="img" />
            <h2>Fitness</h2>
          </div>
        </Marquee>
      </div>
    </div>
  );
};

export default Boost;
