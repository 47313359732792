import React from "react";
import './HomeVideos.css';
const HomeVIdeos = () => {
  return (
    <div>
      <div id="wass_glance">
        <div className="wass_glance_side_arrow_videos">
          <div className="items items1">
            <video
              src="https://cdn.cuberto.com/cb/projects/qvino/cover.mp4"
              autoPlay
              muted
              loop
            ></video>

            <div className="overlay arrow_overlay_1">
              <div className="overlay_title">
                <h1>Tech</h1>
                <h1>Solutions</h1>
              </div>

              <div className="overlay_paragraph">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Consequuntur amet enim libero molestiae sunt voluptatibus
                praesentium odio doloribus ex rem.
              </div>

              <img
                className="arrow_image"
                src="https://assets-global.website-files.com/64081b3f2fda69c80b5566e5/64d8ef1c098a351cea42b97b_screenshot-www.schbang.com-2023.08.13-20_21_34-removebg-preview.png"
                alt=""
              />
            </div>
          </div>

          <div className="items items2">
            <video
              src="https://cdn.cuberto.com/cb/projects/riyadh/cover.mp4"
              autoPlay
              muted
              loop
            ></video>

            <div className="overlay arrow_overlay_1">
              <div className="overlay_title">
                <h1>Tech</h1>
                <h1>Solutions</h1>
              </div>

              <div className="overlay_paragraph">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Consequuntur amet enim libero molestiae sunt voluptatibus
                praesentium odio doloribus ex rem.
              </div>

              <img
                className="arrow_image"
                src="https://assets-global.website-files.com/64081b3f2fda69c80b5566e5/64d8ef1c098a351cea42b97b_screenshot-www.schbang.com-2023.08.13-20_21_34-removebg-preview.png"
                alt=""
              />
            </div>
          </div>

          <div className="items items3">
            <video
              src="https://cdn.cuberto.com/cb/projects/cisco/cover.mp4"
              autoPlay
              muted
              loop
            ></video>

            <div className="overlay arrow_overlay_1">
              <div className="overlay_title">
                <h1>Tech</h1>
                <h1>Solutions</h1>
              </div>

              <div className="overlay_paragraph">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Consequuntur amet enim libero molestiae sunt voluptatibus
                praesentium odio doloribus ex rem.
              </div>

              <img
                className="arrow_image"
                src="https://assets-global.website-files.com/64081b3f2fda69c80b5566e5/64d8ef1c098a351cea42b97b_screenshot-www.schbang.com-2023.08.13-20_21_34-removebg-preview.png"
                alt=""
              />
            </div>
          </div>

          <div className="items items4">
            <video
              src="https://cdn.cuberto.com/cb/projects/ferrumpipe/cover.mp4"
              autoPlay
              muted
              loop
            ></video>

            <div className="overlay arrow_overlay_1">
              <div className="overlay_title">
                <h1>Tech</h1>
                <h1>Solutions</h1>
              </div>

              <div className="overlay_paragraph">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Consequuntur amet enim libero molestiae sunt voluptatibus
                praesentium odio doloribus ex rem.
              </div>

              <img
                className="arrow_image"
                src="https://assets-global.website-files.com/64081b3f2fda69c80b5566e5/64d8ef1c098a351cea42b97b_screenshot-www.schbang.com-2023.08.13-20_21_34-removebg-preview.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeVIdeos;
