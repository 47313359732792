import React from "react";
import hero from "../../assets/img/FunnelPage2/hero.png";
import hangingIMage1 from "../../assets/img/FunnelPage2/hangingImage1.png";
import hangingIMage2 from "../../assets/img/FunnelPage2/hangingImage2.png";
import hangingIMage3 from "../../assets/img/FunnelPage2/hangingImage3.png";
import elephant from "../../assets/img/FunnelPage2/elephant.png";
import flower from "../../assets/img/FunnelPage2/flower1.png";
import zigzag from "../../assets/img/FunnelPage2/zigZagLine 3.png";
import bookImage from "../../assets/img/FunnelPage2/book.png";
import shirtImage from "../../assets/img/FunnelPage2/shirt.png";
import blankUser from "../../assets/img/FunnelPage2/user.png";
import templteImage from "../../assets/img/FunnelPage2/temple.png";
import templeImage1 from "../../assets/img/FunnelPage2/templeImage1.png";
import diya from "../../assets/img/FunnelPage2/diya.png";
import HomeVideos from "../../components/HomeVideos/HomeVIdeos";
import freeAccountBanner from "../../assets/img/FunnelPage2/freeAccountBanner.png";

import "./FunnelPage2.css";
import { Helmet } from "react-helmet-async";

const FunnelPage2 = () => {
  return (
    <div id="funnel_page2">

      <Helmet>
        <title>Funnel Page | Webapps Software Solutions </title>
        <meta name="copyright" content="https://webappssoft.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webappssoft.com/" />
        <link rel="next" href="https://webappssoft.com/" />
        <meta property="og:title" content="Property Dealers in Jamshedpur | Property Dealers Near Me" />
        <meta property="og:url" content="https://webappssoft.com/" />
        <meta property="og:image" content="https://webappssoft.com/logo/sy-logo.jpg" />
        <meta property="og:description" content="Property Dealers in Jamshedpur: Find 150+ Reliable real estate agents and Brokers in Jamshedpur. Contact residential and Commercial property consultants online." />
        <meta name="description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="keywords" content="website design company India,web designing India, best web design company,top web design company,top web design companies India, web design and development company in india" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta property="og:site_name" content="Webapps Software Solutions" />
        <meta name="twitter:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="twitter:title" content="Website Design & Development Company In Kolkata, India" />
        <meta property="og:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:site" content="webapps software Solutions" />
        <meta name="twitter:creator" content="@webappsoftwares" />
        <meta name="theme-color" content="#0098DA" />
        <meta name="author" content="WASS Integrated Tech Enable It Solutions" />
        <meta name="google-site-verification" content="3qKB507bM-zleASA7ZJYxnvl9L30Wk2o4KA7dZksurY" />
      </Helmet>
      {/* =============== HERO =============== */}

      <div className="hero">
        <img src={hero} alt="" />
        <h2>Operational obstacles</h2>
      </div>

      {/* =============== OPPORTUNITY =============== */}

      <div id="opportunity">
        <div className="opportunity">
          <div className="opportunity_box-1">
            <div className="content-1">
              <h1>The Opportunity Imassive</h1>
            </div>

            <div className="button">
              <button>
                Shedule Call <span>&#x2192;</span>
              </button>
            </div>
          </div>
          <div className="opportunity_box-2">
            <img src={hangingIMage3} alt="" />
            <img src={hangingIMage2} alt="" />
            <img src={hangingIMage1} alt="" />
          </div>
        </div>

        <div className="elephant">
          <div className="">
            <img className="elephantImage" src={elephant} alt="" />
          </div>
          <div className="">
            <img className="flower_image" src={flower} alt="" />
          </div>
        </div>

        <div className="cont2">
          <img className="zig_zag" src={zigzag} alt="" />

          <div className="isheavier">
            <div className="box">
              <img src={bookImage} alt="" />
              <h3>Operational obstacles</h3>
              <p>
                Relying on a single courier won’t <br />
                suffice during order surges
              </p>
            </div>

            <div className="box">
              <img src={blankUser} alt="" />
              <h3>Operational obstacles</h3>
              <p>
                Relying on a single courier won’t <br /> suffice during order
                surges
              </p>
            </div>

            <div className="box">
              <img src={shirtImage} alt="" />
              <h3>Operational obstacles</h3>
              <p>
                Relying on a single courier won’t <br /> suffice during order
                surges
              </p>
            </div>

            <div className="box">
              <img src={bookImage} alt="" />
              <h3>Operational obstacles</h3>
              <p>
                Relying on a single courier won’t <br /> suffice during order
                surges
              </p>
            </div>

            <div className="box">
              <img src={blankUser} alt="" />
              <h3>Operational obstacles</h3>
              <p>
                Relying on a single courier won’t <br /> suffice during order
                surges
              </p>
            </div>

            <div className="box">
              <img src={shirtImage} alt="" />
              <h3>Operational obstacles</h3>
              <p>
                Relying on a single courier won’t <br />
                suffice during order surges
              </p>
            </div>
          </div>
        </div>

        <img className="zig_zag" src={zigzag} alt="" />

        <div className="temple_section">
          <img src={templteImage} alt="" />
          <div className="overlay">
            <div className="title">
              <h4>
                <img src={diya} alt="" />
                ARE YOU READY?
                <img src={diya} alt="" />
              </h4>
              <h1>We’ve got everything you need</h1>
            </div>

            <div className="box_items">
              <div className="boxes">
                <img src={templeImage1} alt="" />
                <h5>Operational obstacles</h5>
                <p>
                  Relying on a single courier won’t suffice during order surges
                </p>
                <button>KNOW MORE</button>
              </div>

              <div className="boxes">
                <img src={templeImage1} alt="" />
                <h5>Operational obstacles</h5>
                <p>
                  Relying on a single courier won’t suffice during order surges
                </p>
                <button>KNOW MORE</button>
              </div>

              <div className="boxes">
                <img src={templeImage1} alt="" />
                <h5>Operational obstacles</h5>
                <p>
                  Relying on a single courier won’t suffice during order surges
                </p>
                <button>KNOW MORE</button>
              </div>

              <div className="boxes">
                <img src={templeImage1} alt="" />
                <h5>Operational obstacles</h5>
                <p>
                  Relying on a single courier won’t suffice during order surges
                </p>
                <button>KNOW MORE</button>
              </div>

              <div className="boxes">
                <img src={templeImage1} alt="" />
                <h5>Operational obstacles</h5>
                <p>
                  Relying on a single courier won’t suffice during order surges
                </p>
                <button>KNOW MORE</button>
              </div>

              <div className="boxes">
                <img src={templeImage1} alt="" />
                <h5>Operational obstacles</h5>
                <p>
                  Relying on a single courier won’t suffice during order surges
                </p>
                <button>KNOW MORE</button>
              </div>
            </div>
          </div>
        </div>

        <img className="zig_zag" src={zigzag} alt="" />

        <div className="still_confused">
          <h1>STILL CONFUSED</h1>

          <div className="still_contused_videos">
            <HomeVideos />
          </div>
        </div>

        <div className="freeAccount">
          <div className="banner">
            <img src={freeAccountBanner} alt="" />
          </div>
          <div className="content">
            <div className=""></div>
            <div className="content_item">
              <h1>Get Started With A Free Account</h1>
              <div className="white"></div>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FunnelPage2;
