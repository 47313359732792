import React from "react";
import { useState } from "react";
import "./BusinessFaq.css";

const BusinessFaq = () => {
  const [faq, setFaq] = useState("item-1");

  return (
    <div>
      <div className="keep_your_business_safe">
        <div className="keep_your_business_safe_11">
          <div className="keep_your-business-safeimage1-box">
            <img
              className="keep_your-business-safeimage1"
              src="https://images.pexels.com/photos/1181690/pexels-photo-1181690.jpeg?cs=srgb&dl=pexels-christina-morillo-1181690.jpg&fm=jpg"
              alt="image"
            />

            <img
              src="https://images.pexels.com/photos/1181690/pexels-photo-1181690.jpeg?cs=srgb&dl=pexels-christina-morillo-1181690.jpg&fm=jpg"
              alt="image"
              className="keep_your-business-safeimage2"
            />
          </div>
          <div className="polygon"></div>
        </div>

        <div className="keep_your_business_safe_2">
          <h6>OUR SERVICE BENEFITS</h6>

          <h2>Keep Your Business Safe & Ensure High Availability.</h2>

          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus,
            unde! Lorem ipsum dolor, sit amet consectetur adipisicing
          </p>

          <div className="keep_your_business_safe_2-question">
            <div className="question_1">
              <h4 onClick={() => setFaq("item-1")}>
                Find some helpful answers to frequ asked question?{" "}
                <i
                  className={`bx bx-chevron-${
                    faq === "item-1" ? "up" : "down"
                  }`}
                ></i>
              </h4>

              <small
                className="answeerre"
                style={{ display: faq === "item-1" ? "block" : "none" }}
              >
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Voluptatem, quasi. Lorem ipsum dolor sit amet consectetur
                adipisicing elit. In eveniet aspernatur quasi ullam. Consectetur
                saepe, possimus voluptatem exercitationem tempore optio?
              </small>
            </div>

            <div className="question_1">
              <h4 onClick={() => setFaq("item-2")}>
                Find some helpful answers to frequ asked question?{" "}
                <i
                  className={`bx bx-chevron-${
                    faq === "item-2" ? "up" : "down"
                  }`}
                ></i>
              </h4>

              <small
                className="answeerre"
                style={{ display: faq === "item-2" ? "block" : "none" }}
              >
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Voluptatem, quasi. Lorem ipsum dolor sit amet consectetur
                adipisicing elit. In eveniet aspernatur quasi ullam. Consectetur
                saepe, possimus voluptatem exercitationem tempore optio?
              </small>
            </div>

            <div className="question_1">
              <h4 onClick={() => setFaq("item-3")}>
                Find some helpful answers to frequ asked question?{" "}
                <i
                  className={`bx bx-chevron-${
                    faq === "item-3" ? "up" : "down"
                  }`}
                ></i>
              </h4>

              <small
                className="answeerre"
                style={{ display: faq === "item-3" ? "block" : "none" }}
              >
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Voluptatem, quasi. Lorem ipsum dolor sit amet consectetur
                adipisicing elit. In eveniet aspernatur quasi ullam. Consectetur
                saepe, possimus voluptatem exercitationem tempore optio?
              </small>
            </div>

            <div className="question_1">
              <h4 onClick={() => setFaq("item-4")}>
                Find some helpful answers to frequ asked question?{" "}
                <i
                  className={`bx bx-chevron-${
                    faq === "item-4" ? "up" : "down"
                  }`}
                ></i>
              </h4>

              <small
                className="answeerre"
                style={{ display: faq === "item-4" ? "block" : "none" }}
              >
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Voluptatem, quasi. Lorem ipsum dolor sit amet consectetur
                adipisicing elit. In eveniet aspernatur quasi ullam. Consectetur
                saepe, possimus voluptatem exercitationem tempore optio?
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessFaq;
