import React, { useState } from "react";
import caroselBanner from "../../assets/img/FunnelPage/caroselBanner.png";
import ring from "../../assets/img/FunnelPage/ring.png";
import thumb from "../../assets/img/FunnelPage/thumb.png";
import HomeVIdeos from "../../components/HomeVideos/HomeVIdeos";
import manwithlaptop from "../../assets/img/FunnelPage/manwithlaptop.png";
import "./FunnelPage.css";
import PricingCards from "../../components/PricingCards/PricingCards";
import Marquee from "react-fast-marquee";
import { Helmet } from "react-helmet-async";

const FunnelPage = () => {
  const [faq, setFaq] = useState(false);

  return (
    <div id="funnel_page">

<Helmet>
      <title>Funnel Page | Webapps Software Solutions </title>
        <meta name="copyright" content="https://webappssoft.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webappssoft.com/" />
        <link rel="next" href="https://webappssoft.com/" />
        <meta property="og:title" content="Property Dealers in Jamshedpur | Property Dealers Near Me" />
        <meta property="og:url" content="https://webappssoft.com/" />
        <meta property="og:image" content="https://webappssoft.com/logo/sy-logo.jpg" />
        <meta property="og:description" content="Property Dealers in Jamshedpur: Find 150+ Reliable real estate agents and Brokers in Jamshedpur. Contact residential and Commercial property consultants online." />
        <meta name="description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="keywords" content="website design company India,web designing India, best web design company,top web design company,top web design companies India, web design and development company in india" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta property="og:site_name" content="Webapps Software Solutions" />
        <meta name="twitter:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="twitter:title" content="Website Design & Development Company In Kolkata, India" />
        <meta property="og:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:site" content="webapps software Solutions" />
        <meta name="twitter:creator" content="@webappsoftwares" />
        <meta name="theme-color" content="#0098DA" />
        <meta name="author" content="WASS Integrated Tech Enable It Solutions" />
        <meta name="google-site-verification" content="3qKB507bM-zleASA7ZJYxnvl9L30Wk2o4KA7dZksurY" />
      </Helmet>
      {/* ========  HERO ======== */}
      <div className="hero">
        <div className="hero_1">
          <button className="hero_btn">ALKING INTELLIGENCE 👌🏻</button>

          <h1>Talking Codes, </h1>
          <h1>Unlimited</h1>
          <h1>Chat</h1>

          <small className="hero_paragraph">
            Equipped with human-like intelligence, our chatbots establish fluent
            and interactive dialogues.Equipped with human-like intelligence, our
            chatbots establish{" "}
          </small>

          <div className="hero_btn-btn">
            <button>
              Schedule Call <span>&#8594;</span>
            </button>
          </div>
        </div>

        <div className="hero_2">
          <img className="caroselBanner" src={caroselBanner} alt="" />
          <img src={ring} alt="" className="ring" />
        </div>
      </div>

      {/* ========  CONTENT  ======== */}

      <div className="content">
        <div className="content_heading">
          <div className="content_heading-title">
            <button className="hero_btn">ALKING INTELLIGENCE 👌🏻</button>
            <h1>Talking Codes</h1>
            <h1>Unlimited Chat.</h1>
          </div>

          <div className="content_heading-paaragraph">
            Equipped with human-like intelligence, our chatbots establish fluent
            and interactive dialogues.Equipped with human-like intelligence, our
            chatbots establish{" "}
          </div>
        </div>

        <div className="content_box">
          <div className="content_box-cards">
            <img
              src="https://assets-global.website-files.com/628288c5cd3e8411b90a36a4/63e32cb68bcb222d8272cbde_icon-seo.svg"
              alt=""
            />

            <h2>Human-Like Intelligence</h2>

            <p>
              Thanks to their human-like intelligence, they can interact with
              users naturlly and fluently.
            </p>

            <button>
              Discover <i className="bx bx-chevron-right"></i>
            </button>
          </div>

          <div className="content_box-cards">
            <img
              src="https://assets-global.website-files.com/628288c5cd3e8411b90a36a4/63e32cb635c0cf50b0e32b3f_api.svg"
              alt=""
            />

            <h2>Human-Like Intelligence</h2>

            <p>
              Thanks to their human-like intelligence, they can interact with
              users naturlly and fluently.
            </p>

            <button>
              Discover <i className="bx bx-chevron-right"></i>
            </button>
          </div>

          <div className="content_box-cards">
            <img
              src="https://assets-global.website-files.com/628288c5cd3e8411b90a36a4/63e32cb68bcb2283d272cbda_end%20to%20end.svg"
              alt=""
            />

            <h2>Human-Like Intelligence</h2>

            <p>
              Thanks to their human-like intelligence, they can interact with
              users naturlly and fluently.
            </p>

            <button>
              Discover <i className="bx bx-chevron-right"></i>
            </button>
          </div>
        </div>

        <div className="content_heading">
          <div className="content_heading-title">
            <button className="hero_btn-2">ALKING INTELLIGENCE 👌🏻</button>
            <h1>Talking Codes</h1>
            <h1>Unlimited Chat.</h1>
          </div>

          <div className="content_heading-paaragraph">
            Equipped with human-like intelligence, our chatbots establish fluent
            and interactive dialogues.Equipped with human-like intelligence, our
            chatbots establish <img src={thumb} alt="" className="thumb" />
          </div>
        </div>

        <div className="hero_content_videos">
          <HomeVIdeos />
        </div>
      </div>

      {/* FAQ */}

      <div id="fuinnel_page-faq">
        <div className="faq_content1">
          <div className="faq_content-title">
            <button>FREQUENTLY ASKED QUESTION</button>
            <h1>
              We answer your questions
              <h1 className="question_heading">
                <div className="linne"></div>
              </h1>
            </h1>
          </div>

          <div className="faq_content-paragraph">
            <p>
              Explore the section to learn more about AI chathbots and
              find answers to your questions
            </p>

            <img src={thumb} alt="" />
          </div>
        </div>

        <div className="faq_content2">
          <div className="questions_boxs">
            <h5 onClick={() => setFaq("item1")}>
              How can I integrated AI chatbots ?{" "}
              <i className={`bx bx-${faq === "item1" ? "minus" : "plus"}`}></i>
            </h5>
            <p style={{ display: faq === "item1" ? "block" : "none" }}>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa
              architecto facilis fugitperspiciatis animi. Fugiat placeat
              voluptatum molestiae totam expedita.
            </p>
          </div>

          <div className="questions_boxs">
            <h5 onClick={() => setFaq("item2")}>
              How can I integrated AI chatbots ?{" "}
              <i className={`bx bx-${faq === "item2" ? "minus" : "plus"}`}></i>
            </h5>
            <p style={{ display: faq === "item2" ? "block" : "none" }}>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa
              architecto facilis fugitperspiciatis animi. Fugiat placeat
              voluptatum molestiae totam expedita.
            </p>
          </div>

          <div className="questions_boxs">
            <h5 onClick={() => setFaq("item3")}>
              How can I integrated AI chatbots ?{" "}
              <i className={`bx bx-${faq === "item3" ? "minus" : "plus"}`}></i>
            </h5>
            <p style={{ display: faq === "item3" ? "block" : "none" }}>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa
              architecto facilis fugitperspiciatis animi. Fugiat placeat
              voluptatum molestiae totam expedita.
            </p>
          </div>

          <div className="questions_boxs">
            <h5 onClick={() => setFaq("item4")}>
              How can I integrated AI chatbots ?{" "}
              <i className={`bx bx-${faq === "item4" ? "minus" : "plus"}`}></i>
            </h5>
            <p style={{ display: faq === "item4" ? "block" : "none" }}>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa
              architecto facilis fugitperspiciatis animi. Fugiat placeat
              voluptatum molestiae totam expedita.
            </p>
          </div>

          <div className="questions_boxs">
            <h5 onClick={() => setFaq("item5")}>
              How can I integrated AI chatbots ?{" "}
              <i className={`bx bx-${faq === "item5" ? "minus" : "plus"}`}></i>
            </h5>
            <p style={{ display: faq === "item5" ? "block" : "none" }}>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa
              architecto facilis fugitperspiciatis animi. Fugiat placeat
              voluptatum molestiae totam expedita.
            </p>
          </div>

          <div className="questions_boxs">
            <h5 onClick={() => setFaq("item6")}>
              How can I integrated AI chatbots ?
              <i className={`bx bx-${faq === "item6" ? "minus" : "plus"}`}></i>
            </h5>
            <p style={{ display: faq === "item6" ? "block" : "none" }}>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa
              architecto facilis fugitperspiciatis animi. Fugiat placeat
              voluptatum molestiae totam expedita.
            </p>
          </div>
        </div>
      </div>

      {/* MAN WITH LAPTOP */}

      <div className="manwith_laptop">
        <div className=""></div>
        <div className="">
          <img className="manwith_laptop-img" src={manwithlaptop} alt="" />
        </div>
      </div>

      {/* PRICING */}

      <div className="funnel_pticing">
        <h1>Flexible Pricing</h1>
        <p>
          Flexible and affording plans tailored to your needs. Save up to
          %20 for a limited time.
        </p>

        <div className="pricin_box_alt">
          <PricingCards />
        </div>
      </div>

      {/* ++=== MARQUE ====++ */}

      <div className="funnel_marqee-box">
        <Marquee>
          <div className="funnel_marqee">
            <img src="https://inkbotdesign.com/wp-content/uploads/2012/09/Ford-Logo-Design.png" alt="" />
          </div>

          <div className="funnel_marqee">
            <img src="https://logos-world.net/wp-content/uploads/2020/04/Samsung-Logo-2005-present.jpg" alt="" />
          </div>


          <div className="funnel_marqee">
            <img src="https://1000logos.net/wp-content/uploads/2021/10/Meta-Logo.png" alt="" />
          </div>

          <div className="funnel_marqee">
            <img src="https://inkbotdesign.com/wp-content/uploads/2012/09/Ford-Logo-Design.png" alt="" />
          </div>

          <div className="funnel_marqee">
            <img src="https://1000logos.net/wp-content/uploads/2021/10/Meta-Logo.png" alt="" />
          </div>

          <div className="funnel_marqee">
            <img src="https://inkbotdesign.com/wp-content/uploads/2012/09/Ford-Logo-Design.png" alt="" />
          </div>

          <div className="funnel_marqee">
            <img src="https://logos-world.net/wp-content/uploads/2020/04/Samsung-Logo-2005-present.jpg" alt="" />
          </div>


          <div className="funnel_marqee">
            <img src="https://1000logos.net/wp-content/uploads/2021/10/Meta-Logo.png" alt="" />
          </div>

          <div className="funnel_marqee">
            <img src="https://inkbotdesign.com/wp-content/uploads/2012/09/Ford-Logo-Design.png" alt="" />
          </div>

          <div className="funnel_marqee">
            <img src="https://1000logos.net/wp-content/uploads/2021/10/Meta-Logo.png" alt="" />
          </div>

          <div className="funnel_marqee">
            <img src="https://inkbotdesign.com/wp-content/uploads/2012/09/Ford-Logo-Design.png" alt="" />
          </div>

          <div className="funnel_marqee">
            <img src="https://logos-world.net/wp-content/uploads/2020/04/Samsung-Logo-2005-present.jpg" alt="" />
          </div>


          <div className="funnel_marqee">
            <img src="https://1000logos.net/wp-content/uploads/2021/10/Meta-Logo.png" alt="" />
          </div>

          <div className="funnel_marqee">
            <img src="https://inkbotdesign.com/wp-content/uploads/2012/09/Ford-Logo-Design.png" alt="" />
          </div>

          <div className="funnel_marqee">
            <img src="https://1000logos.net/wp-content/uploads/2021/10/Meta-Logo.png" alt="" />
          </div>

        </Marquee>
      </div>
    </div>
  );
};

export default FunnelPage;
