import React, { useState } from "react";
import './Faq.css';

const Faq = () => {

  // FAQ FUNCTION

  const [FaQq, setFaQ] = useState("item_1");
  const handelShow = (item) => {
    setFaQ(item);
  };
  return (
    <div>
      <div id="faq">
        <div className="faq_banner">
          <img
            src="https://calbizjournal.com/wp-content/uploads/2023/07/corporateemployeessharingstoriesandexperiencesduringlunchbreak2.jpg"
            alt=""
          />

          <div className="faq_banner__overlay">
            <div className="faq_banner__overlay_content">
              <div className="faq_banner__overlay_content_box">
                <h3>Creative AI APPS</h3>
                <small>
                  Lorem ipsum dolor sit, amet consectetur br adipisicing elit.
                  Quidem, voluptatum?
                </small>
              </div>
            </div>

            <div className="faq_banner__overlay_content">
              <div className="faq_banner__overlay_content_box">
                <h3>Creative AI APPS</h3>
                <small>
                  Lorem ipsum dolor sit, amet consectetur br adipisicing elit.
                  Quidem, voluptatum?
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="faq_content">
          <div className="faq_content__title">
            <span>OUR EXPERTS ANSWER</span>
          </div>
          <div className="faq_content__heading">
            <h1>Frequently Asked Questions</h1>
          </div>
          <div className="faq_content__data">
            <ul>
              <li>
                <div
                  className="api_queastion"
                  onClick={() => handelShow("item_1")}
                >
                  <h4>How Many Days it Take To Activate API?</h4>
                  <div className="faq_arrow">
                    <i className="bx bx-arrow-back"></i>
                  </div>
                </div>

                <div
                  className="faq_questions_answers"
                  style={{ display: FaQq === "item_1" ? "block" : "none" }}
                >
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Aliquam eveniet distinctio quidem tenetur laboriosam libero
                  natus nisi cum suscipit labore.
                </div>
              </li>

              <li>
                <div
                  className="api_queastion"
                  onClick={() => handelShow("item_2")}
                >
                  <h4>How Many Days it Take To Activate API?</h4>
                  <div className="faq_arrow">
                    <i className="bx bx-arrow-back"></i>
                  </div>
                </div>

                <div
                  className="faq_questions_answers"
                  style={{ display: FaQq === "item_2" ? "block" : "none" }}
                >
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Aliquam eveniet distinctio quidem tenetur laboriosam libero
                  natus nisi cum suscipit labore.
                </div>
              </li>

              <li>
                <div
                  className="api_queastion"
                  onClick={() => handelShow("item_3")}
                >
                  <h4>How Many Days it Take To Activate API?</h4>
                  <div className="faq_arrow">
                    <i className="bx bx-arrow-back"></i>
                  </div>
                </div>

                <div
                  className="faq_questions_answers"
                  style={{ display: FaQq === "item_3" ? "block" : "none" }}
                >
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Aliquam eveniet distinctio quidem tenetur laboriosam libero
                  natus nisi cum suscipit labore.
                </div>
              </li>

              <li>
                <div
                  className="api_queastion"
                  onClick={() => handelShow("item_4")}
                >
                  <h4>How Many Days it Take To Activate API?</h4>
                  <div className="faq_arrow">
                    <i className="bx bx-arrow-back"></i>
                  </div>
                </div>

                <div
                  className="faq_questions_answers"
                  style={{ display: FaQq === "item_4" ? "block" : "none" }}
                >
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Aliquam eveniet distinctio quidem tenetur laboriosam libero
                  natus nisi cum suscipit labore.
                </div>
              </li>

              <li>
                <div
                  className="api_queastion"
                  onClick={() => handelShow("item_5")}
                >
                  <h4>How Many Days it Take To Activate API?</h4>
                  <div className="faq_arrow">
                    <i className="bx bx-arrow-back"></i>
                  </div>
                </div>

                <div
                  className="faq_questions_answers"
                  style={{ display: FaQq === "item_5" ? "block" : "none" }}
                >
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Aliquam eveniet distinctio quidem tenetur laboriosam libero
                  natus nisi cum suscipit labore.
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
