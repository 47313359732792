import React from "react";
import "./EnqueryForm.css";
import star from "../../assets/img/EnqueryPage/star.png";
import EnqueryDetails from "../../components/EnqueryDetails/EnqueryDetails";
const EnqueryForm = () => {
  return (
    <div id="enqueryForm">
      <div className="enquery_head">
        <p>Request ID : 4747676756</p>
        <p>17 Sept 2023 11:39 PM</p>
      </div>

      <img src={star} alt="star" className="Enquerystar" />

      <div className="form_title">
        <h4>REQUEST FORM</h4>
        <h1>Brand Enquiry Acknowledgement</h1>
        <p>
          Thank you for filling the brand enquiry form!We look forward to
          working with you and assisting you with your needs.Our team shall
          understand your requirements and get back to you in 48 hours
        </p>
      </div>

      <div className="form_box">
        <EnqueryDetails  />
      </div>
    </div>
  );
};

export default EnqueryForm;
