import React from "react";
import LargeMarqee from "../../components/Marqee/LargeMarqee";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./BlogPage.css";

const BlogPage = () => {

  const data = useSelector((store) => store.value);
  const navigate = useNavigate();
  const naviFun = (id) => {
    console.log(id);
    navigate(`/blog2/${id}`)
  }

  return (
    <div id="blog_page">

<Helmet>
      <title>Blogs | Webapps Software Solutions </title>
        <meta name="copyright" content="https://webappssoft.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webappssoft.com/" />
        <link rel="next" href="https://webappssoft.com/" />
        <meta property="og:title" content="Property Dealers in Jamshedpur | Property Dealers Near Me" />
        <meta property="og:url" content="https://webappssoft.com/" />
        <meta property="og:image" content="https://webappssoft.com/logo/sy-logo.jpg" />
        <meta property="og:description" content="Property Dealers in Jamshedpur: Find 150+ Reliable real estate agents and Brokers in Jamshedpur. Contact residential and Commercial property consultants online." />
        <meta name="description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="keywords" content="website design company India,web designing India, best web design company,top web design company,top web design companies India, web design and development company in india" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta property="og:site_name" content="Webapps Software Solutions" />
        <meta name="twitter:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="twitter:title" content="Website Design & Development Company In Kolkata, India" />
        <meta property="og:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:site" content="webapps software Solutions" />
        <meta name="twitter:creator" content="@webappsoftwares" />
        <meta name="theme-color" content="#0098DA" />
        <meta name="author" content="WASS Integrated Tech Enable It Solutions" />
        <meta name="google-site-verification" content="3qKB507bM-zleASA7ZJYxnvl9L30Wk2o4KA7dZksurY" />
      </Helmet>

      <div className="">
        <LargeMarqee />
      </div>

      <div className="boxesss1">
        {
          data.map((x) => {
            return (
              <div onClick={() => naviFun(x.id)} key={x.id} className="box">
                <img src={x.img} alt="img" />

                <div className="date">{x.date}</div>

                <div className="text">
                  <p>
                    {x.description.slice(0, 100)} ...

                  </p>
                  <a href="#"
                    onClick={() => naviFun(x.id)}

                    className="link">
                    Read More <span className="arrow">&#x2192;</span>
                  </a>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

export default BlogPage;
