import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const WeareWorking = () => {
    const [pop, setPop] = useState("flase");
    const navigate = useNavigate();

  return (
    <div className='wssroll' style={{ overflow:"hidden", color: "#800080", textAlign : "center", fontSize:"20px"}}>
     
       <div

        className="pendinG_modal"
        style={{ display:"flex"}}
      >
        <div className="model_view">
          <div className="modal">
            <i className="bx bx-x" onClick={() => navigate("/")}></i>

            <h1>We are working on this site, stay tuned we will be back.</h1>
            <img
              src="https://www.pharmabiz.com/Images/loading_spinner.gif"
              alt="img"
            />
          </div>
        </div>
      </div>

    </div>
  )
}

export default WeareWorking
