import React from "react";
import "./News.css";
import newws1 from "../../assets/img/News/image 325.png";
import newws2 from "../../assets/img/News/image 326.png";
import newws3 from "../../assets/img/News/image 327.png";
import newws4 from "../../assets/img/News/image 328.png";
import newws5 from "../../assets/img/News/image 329.png";
import TagsCard from "../../components/TagsCard/TagsCard";
import NewsSlider from '../../components/NewsSlider/NewsSlider';
import { Helmet } from "react-helmet-async";

const News = () => {
  return (
    <div id="news">

      <Helmet>
        <title>News | Webapps Software Solutions </title>
        <meta name="copyright" content="https://webappssoft.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webappssoft.com/" />
        <link rel="next" href="https://webappssoft.com/" />
        <meta property="og:title" content="Property Dealers in Jamshedpur | Property Dealers Near Me" />
        <meta property="og:url" content="https://webappssoft.com/" />
        <meta property="og:image" content="https://webappssoft.com/logo/sy-logo.jpg" />
        <meta property="og:description" content="Property Dealers in Jamshedpur: Find 150+ Reliable real estate agents and Brokers in Jamshedpur. Contact residential and Commercial property consultants online." />
        <meta name="description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="keywords" content="website design company India,web designing India, best web design company,top web design company,top web design companies India, web design and development company in india" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta property="og:site_name" content="Webapps Software Solutions" />
        <meta name="twitter:description" content="Leading web design & web development company in Kolkata, INDIA. Digital marketing service provider company in Kolkata" />
        <meta name="twitter:title" content="Website Design & Development Company In Kolkata, India" />
        <meta property="og:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://webappssoft.com/assets/images/logo.png" />
        <meta name="twitter:site" content="webapps software Solutions" />
        <meta name="twitter:creator" content="@webappsoftwares" />
        <meta name="theme-color" content="#0098DA" />
        <meta name="author" content="WASS Integrated Tech Enable It Solutions" />
        <meta name="google-site-verification" content="3qKB507bM-zleASA7ZJYxnvl9L30Wk2o4KA7dZksurY" />
      </Helmet>

      <div className="heading">
        <h1>WASS In NEWS</h1>
        <p>
          Where traditional banking holds you back, RazorpayX moves you forward.
          Automate all your banking needs with a Current Account built for all{" "}
        </p>
      </div>

      <div className="content">
        <NewsSlider />
      </div>

      <div className="wass_awards">
        <div className="heading">
          <h1>WASS Awards</h1>

          <p>
            Where traditional banking holds you back, RazorpayX moves you
            forward. Automate all your banking needs with a Current Account
            built for all{" "}
          </p>
        </div>
        <div className="award_container">
          <img src={newws1} alt="" />
          <img src={newws2} alt="" />
          <img src={newws3} alt="" />
          <img src={newws4} alt="" />
          <img src={newws5} alt="" />
        </div>
      </div>

      <div className="wass_awards">
        <div className="heading">
          <h1>WASS Awards</h1>
          <p>
            Where traditional banking holds you back, RazorpayX moves you
            forward. Automate all your banking needs with a Current Account
            built for all{" "}
          </p>
        </div>

        <div className="carddds">
          <TagsCard />
        </div>
      </div>
    </div>
  );
};

export default News;
