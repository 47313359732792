import React, { useState } from "react";
import "./Macbook.css";

// Slider1
import oval1 from "../../assets/img/Macbook/Slider1/glassEffect.png";
import ovalImage1 from "../../assets/img/Macbook/Slider1/television.png";
import line from "../../assets/img/Macbook/Slider1/line.png";
import ring from "../../assets/img/Macbook/Slider1/ring.png";

// Slider2

import oval2 from "../../assets/img/Macbook/Slider2/oval.png";
import cosmatics from "../../assets/img/Macbook/Slider2/cosmatics.png";
import rocket from "../../assets/img/Macbook/Slider2/rocket.png";

// Slider3
import oval3 from "../../assets/img/Macbook/slider3/slider3oval.png";
import ovalImage3 from "../../assets/img/Macbook/slider3/kids.png";
import oval3bg1 from "../../assets/img/Macbook/slider3/bg1.png";
import oval3bg2 from "../../assets/img/Macbook/slider3/bg2.png";

// Slider4
import oval4 from "../../assets/img/Macbook/Slider4/slider4oval.png";
import ovalImage4 from "../../assets/img/Macbook/Slider4/slider4img.png";
import oval4bg1 from "../../assets/img/Macbook/Slider4/focus.png";
import oval4bg2 from "../../assets/img/Macbook/Slider4/pop.png";

// Slider5

import oval5 from "../../assets/img/Macbook/slider3/slider3oval.png";
import ovalImage5 from "../../assets/img/Macbook/slider5/slider4img.png";
import clap from "../../assets/img/Macbook/slider5/claps.png";
import handShack from "../../assets/img/Macbook/slider5/handshake.png";
const Macbook = () => {
  const [active, setActive] = useState("item1");
  const [bgColor, setBgcolor] = useState("#290535");
  const [ovalRotate, setRotate] = useState("deg");

  return (
    <>
      <div id="macbook" style={{ backgroundColor: bgColor }}>
        <div className="data">
          <div className="container-boxy1">
            <div
              className="round_content"
              style={{ opacity: active === "item2" ? "1" : "0" }}
            >
              <img src={rocket} alt="" className="rocket" />

              <h1>Build you Brand with detailed and analysed Marketing</h1>

              <p>
                In the world of advertisements you need strategic content to
                attract the users from your strong online presence. Allow your
                brand to stand out in the digital world, where creativity makes
                a difference.
              </p>
            </div>
          </div>

          <div className="container-boxy2">
            <div
              className="round_content"
              style={{ opacity: active === "item3" ? "1" : "0" }}
            >
              <img src={oval3bg1} alt="IMG" className="text_bg1" />
              <img src={oval3bg2} alt="IMG" className="clap" />

              <h1>Build your Brand with a Professional Website</h1>

              <p>
                Expose your users to innovative experiences and attract them
                through our ideas made for you. We help your brand to stand out
                in creative realms.
              </p>
            </div>
          </div>

          <div className="container-boxy3">
            <div
              className="round_content"
              style={{ opacity: active === "item1" ? "1" : "0" }}
            >
              <img src={line} alt="" className="line1" />
              <img src={ring} alt="" className="ring" />

              <h1>Build your Brand with a Professional Website</h1>

              <p>
                Let your users get attracted to them through your strong online
                presence. Allow your brand to stand out in the digital world,
                where creativity makes a difference.
              </p>
            </div>
          </div>

          <div className="container-boxy4">
            <div
              className="round_content"
              style={{ opacity: active === "item4" ? "1" : "0" }}
            >
              <img src={oval4bg1} alt="" className="focus" />
              <img src={oval4bg2} alt="" className="pop" />

              <h1>Design your App with WASS</h1>

              <p>
                Give your users unique and innovative experiences and attract
                them through your strong online presence. Allow your app to
                stand out in the digital world, where creativity makes a
                difference.
              </p>
            </div>
          </div>

          <div className="container-boxy5">
            <div
              className="round_content"
              style={{ opacity: active === "item5" ? "1" : "0" }}
            >
              <img src={clap} alt="" className="clap" />
              <img src={handShack} alt="" className="handShake" />

              <h1>Build your Brand with a Professional System</h1>

              <p>
                Help your brand with a strong and professional system that helps
                attract them through your brand presence. Grow your brand in the
                digital world, with an organised management.
              </p>
            </div>
          </div>
        </div>

        <div className="main-oval">
          <div className="oval">
            {active === "item1" ? (
              <div className="imggg">
                <div className="one">
                  <img src={oval1} alt="" className="glass" />
                  <img src={ovalImage1} alt="" className="oval_bg-img" />
                </div>

                <div className="two"></div>
              </div>
            ) : null}

            {active === "item2" ? (
              <div className="imggg">
                <div className="one">
                  <img src={oval2} alt="" className="glass" />
                  <img src={cosmatics} alt="" className="oval_bg-img" />
                </div>
                <div className="two"></div>
              </div>
            ) : null}

            {active === "item3" ? (
              <div className="imggg">
                <div className="one">
                  <img src={oval3} alt="" className="glass" />
                  <img src={ovalImage3} alt="" className="oval_bg-img" />
                </div>
                <div className="two"></div>
              </div>
            ) : null}

            {active === "item4" ? (
              <div className="imggg">
                <div className="one">
                  <img src={oval4} alt="" className="glass" />
                  <img src={ovalImage4} alt="" className="oval_bg-img" />
                </div>
                <div className="two"></div>
              </div>
            ) : null}

            {active === "item5" ? (
              <div className="imggg">
                <div className="one">
                  <img src={oval5} alt="" className="glass" />
                  <img src={ovalImage5} alt="" className="oval_bg-img" />
                </div>
                <div className="two"></div>
              </div>
            ) : null}

            <div className="oval_list-items">
              <ul>
                <li
                  className={`${active === "item2" && "boldClass"}`}
                  onClick={() => {
                    setActive("item2");
                    setBgcolor("#290535");
                    setRotate("-76deg");
                  }}
                >
                  Branding
                </li>
                <li
                  className={`${active === "item3" && "boldClass"}`}
                  onClick={() => {
                    setActive("item3");
                    setBgcolor("#4F2A91");
                    setRotate("-45deg");
                  }}
                >
                  Creatives
                </li>
                <li
                  className={`${active === "item1" && "boldClass"}`}
                  onClick={() => {
                    setActive("item1");
                    setBgcolor("#49125C");
                    setRotate("0deg");
                  }}
                >
                  Websites
                </li>
                <li
                  className={`${active === "item4" && "boldClass"}`}
                  onClick={() => {
                    setActive("item4");
                    setBgcolor("#5CC4DD");
                    setRotate("47deg");
                  }}
                >
                  App Dev
                </li>
                <li
                  className={`${active === "item5" && "boldClass"}`}
                  onClick={() => {
                    setActive("item5");
                    setBgcolor("#4F2A91");
                    setRotate("76deg");
                  }}
                >
                  ERP & CRM
                </li>
              </ul>
            </div>

            <div className="outer_oval" style={{ rotate: ovalRotate }}>
              <div className="outer_oval-dot">
                <div className="dot-rel">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Macbook;
