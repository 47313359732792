import React from 'react'
import './Button.css'
const Button = ({bgColor, color, title}) => {
  return (
    <div id='button' style={{ backgroundColor: `${bgColor}`, color:`${color}`  }}>{title} <span>&#x2192;</span>
    </div>
  )
}

export default Button
