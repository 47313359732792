import React from 'react'

import './BlogCard.css'
import { useNavigate } from 'react-router-dom'
const BlogCard = ({id , img , des, date}) => {
  const navigate = useNavigate();
  return (
    <div id='blog_card'>
       
        <div className="box">
          <img src={img} alt="" />

          <div className="date">{date}</div>

          <div className="text">
            <p>
              {des}...
            </p>
            <a onClick={() => navigate(`/blog2/${id}`)} href="#" className="link">
              Read More <span className="arrow">&#x2192;</span>
            </a>
          </div>
        

      </div>
    </div>
  )
}

export default BlogCard
