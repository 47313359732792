import React from "react";

const TagsCard = () => {
  return (
    <div>
      <div className="service_page-summery">
        <div className="service_page-summery-boxesss">
          <div className="box_1 box-11">
            <h3 className="box-11-heading-1">
              Sumarise in a table
              <h3 className="box-11-heading-alt1 a11-heading-alt11">
                Sumarise in a table
              </h3>
            </h3>

            <p className="box-11-heading-p1">
              Product Marketing
              <p className="box-11-heading-altp1 a11-heading-altp11">
                Product Marketing
              </p>
            </p>
          </div>

          <div className="box_2">
            <p>Sumarise in a table Technology</p>
            <p className="box_2-p2">
              Try this out
              <span className="box_2-arrow">&#x2192;</span>
            </p>
          </div>
        </div>

        <div className="service_page-summery-boxesss">
          <div className="box_1 box-11">
            <h3 className="box-11-heading-1">
              Sumarise in a table
              <h3 className="box-11-heading-alt1 a11-heading-alt11">
                Sumarise in a table
              </h3>
            </h3>

            <p className="box-11-heading-p1">
              Product Marketing
              <p className="box-11-heading-altp1 a11-heading-altp11">
                Product Marketing
              </p>
            </p>
          </div>

          <div className="box_2">
            <p>Sumarise in a table Technology</p>
            <p className="box_2-p2">
              Try this out
              <span className="box_2-arrow">&#x2192;</span>
            </p>
          </div>
        </div>

        <div className="service_page-summery-boxesss">
          <div className="box_1 box-11">
            <h3 className="box-11-heading-1">
              Sumarise in a table
              <h3 className="box-11-heading-alt1 a11-heading-alt11">
                Sumarise in a table
              </h3>
            </h3>

            <p className="box-11-heading-p1">
              Product Marketing
              <p className="box-11-heading-altp1 a11-heading-altp11">
                Product Marketing
              </p>
            </p>
          </div>

          <div className="box_2">
            <p>Sumarise in a table Technology</p>
            <p className="box_2-p2">
              Try this out
              <span className="box_2-arrow">&#x2192;</span>
            </p>
          </div>
        </div>

        <div className="service_page-summery-boxesss">
          <div className="box_1 box-11">
            <h3 className="box-11-heading-1">
              Sumarise in a table
              <h3 className="box-11-heading-alt1 a11-heading-alt11">
                Sumarise in a table
              </h3>
            </h3>

            <p className="box-11-heading-p1">
              Product Marketing
              <p className="box-11-heading-altp1 a11-heading-altp11">
                Product Marketing
              </p>
            </p>
          </div>

          <div className="box_2">
            <p>Sumarise in a table Technology</p>
            <p className="box_2-p2">
              Try this out
              <span className="box_2-arrow">&#x2192;</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagsCard;
