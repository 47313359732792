import blog1 from "../assets/img/Blog/Blog1.jpg";
import blog2 from "../assets/img/Blog/blog2.jpg";
import blog3 from "../assets/img/Blog/blog3.jpg";
import blog4 from "../assets/img/Blog/blog4.jpg";
import blog5 from "../assets/img/Blog/blog5.jpg";
import blog6 from "../assets/img/Blog/blog6.jpg";
import blog7 from "../assets/img/Blog/blog7.jpg";
import blog8 from "../assets/img/Blog/blog8.jpg";
import blog9 from "../assets/img/Blog/blog9.jpg";
import blog10 from "../assets/img/Blog/blog10.jpg";
import blog11 from "../assets/img/Blog/blog11.jpg";
import blog12 from "../assets/img/Blog/blog12.jpg";

// ALT IMAGE
import altImage1 from "../assets/img/Blog/altImg1.jpg";
import altImage2 from "../assets/img/Blog/altImg2.jpg";
import altImage3 from "../assets/img/Blog/altImg3.jpg";
import altImage4 from "../assets/img/Blog/altImg4.jpg";
import altImage5 from "../assets/img/Blog/altImg5.jpg";
import altImage6 from "../assets/img/Blog/altImg6.jpg";

// ---------
export const blogContent = [
  {
    id: 1,
    banner: "",
    img: blog1,
    desImage: altImage1,
    heading1: "Branding",
    heading2: "Target audience is it really matters ?",
    heading3: "How you can market yourself branding ?",
    date: "April 05, 2022",
    author: "Pawan Kumar",
    title: "Brand Marketing for Business",
    tags: [
      { tag: "Business Plans" },
      { tag: "Competitive Research" },
      { tag: "Economics" },
      { tag: "Finance & Accounting" },
      { tag: "Funding Trends" },
      { tag: "Innovation" },
    ],

    description:
      "We all know that what is a power of brand everybody is thinking about recall value because they know the brand position as well as how they are giving them service to take one step ahead from the crowd.Brand building is based on the name which we suggest or we spotlight to the public we all knows that most of the consumers are preparing the same brand with service then like at very most getting brand awareness is second portion where we can find the first brand is already available in the same market so question is why do you want to send your brand?. Spotlight most of the leads calls enquirys coming through your brand awareness and most of the people trust your brand just because of your brand awareness if audience a public don't know about your brand like who are you at the same time they are not going to believe you are into your brand because you are the new one the reason is very simple to spotlight of your brand to get maximum customer retention. When you want to grab attention of public you need to set goal plan and act it accordingly accordingly at the same time you need to think the customer behaviour what exactly the customers are facing the issue what are the twinkling words where you can directly trigger them to target your audience this is just to keep your product and services in client or users mind that they can remember you and will give you a call back.",

    heading2Text:
      "Yes when you brand is into the specific area or its not in a specific area you have to target the client with your brand name and create good impression in front of a clients with they can be your loyal customer.Getting more attention from your audience at the same time the magic happens in your business this will give you good impression in front of the public if your brand campaign is really good or it will give you if for the customers.If your campaigning brand awareness and product really feels good to the user many users come to you with with positive Vibe about your brand they will choose you against your competitors for that you should know that how I can target my audience first. Afternoon this you need to target your customer where you will get excellent approach from the large number of audience.",

    heading3Text:
      "Always keep in your mind that you have perfect unique selling proposition for your business if you have it will be at the very win situation. Always try to consult about your product and services don't go to convince them.Promote your message articles recent blog or business related any pictures and try to give good suggestion which can help you to get your audience with you just analyse what your competitor is doing how they are working with their product and services. You need to  think about how they are active to get the most customers this however won't help you to get number of customer but here they will get it what customer your targetting.",

    endText:
      "Social media where you will get the entire free time audience was pending that time on social media you need to find more visitors of your website so here don't spend much time on the social media spend your time where your brand gets involved and increases traffic. If you really feel you want to promote your brand into spotlight picture you can connect us we can help you to get increase your market up to 70% with the help of brand marketing awareness program or you can directly visit brand management company in Jadavpur.",
  },

  {
    id: 2,
    img: blog2,
    desImage: altImage2,
    banner: "",
    heading1: " 5 TIPS TO SECURE A WEBSITE ",
    heading2: "  Why do you need to keep your website secure?",
    heading3: "Are you technically skilled in securing your website?",
    date: "September 05, 2021",
    author: "Rakesh Sharma",
    title: "Why you should start cab  online booking services",
    tags: [
      { tag: "Business Plans" },
      { tag: "Competitive Research" },
      { tag: "Economics" },
      { tag: "Finance & Accounting" },
      { tag: "Funding Trends" },
      { tag: "Innovation" },
    ],

    description:
      "With every business going online, the number of websites has increased, and so has the chances of getting them hacked. is here to give you some tips on how you can secure your website from swindling activities. You might think that your website has nothing important that a hacker would be interested in, but a website is compromised. The hacking is not only done to mess up with your data or change the website layout but the server can be used as an email relay for spam or to set up a temporary web server and conduct illegal activities. Data breaches are common practice. According to a study, it is believed that every 39 seconds, a website is hacked, and around 2200 websites are hacked every day. And hence, this makes securing a website essential. It helps you avoid bugs, but you can also save your data from hackers.",

    heading2Text:
      "Even when a website is essential for a business, people tend to neglect the security needed to save it from hackers. They think that if they are not making money through their website, they do not need protection. But the truth is quite different. It is not just about losing money; hacking a website can cost you the traffic you receive to your website. The efforts you have put into its SEO till now can all go in vain. Your site might get suspended or crash, and most importantly, your data and the data of your visitors might be at risk. For example, when customers use their credit card or debit card for online transactions, they feed the data because they are sure that the data is safe with your website. No one wants their data to fall into the wrong hands. Whether you own a small business or a big enterprise, providing a safe online experience to your users is a must.",

    heading3Text:
      "Well, you don't have to be tech-savvy to secure your website. You need to take care or make some changes to your website, and you are good to go. Social media where you will get the entire free time audience was pending that time on social media you need to find more visitors of your website so here don't spend much time on the social media spend your time where your brand gets involved and increases traffic. If you really feel you want to promote your brand into spotlight picture you can connect us we can help you to get increase your market up to 70% with the help of brand marketing awareness program or you can directly visit brand management company in Jadavpur.",

    endText:
      "Don't take your site security for granted. Even if you follow all the above steps, there is a slight chance of your website getting hacked. It is always good to have a backup. Creating backups of your website ensures that even if the worst happens, you will still have a recent version of your site stored in your database, and you can relaunch it easily A backup is a copy of your website data and contains files, content, media, databases, and all the other important elements of a website. You can also opt for regular automatic backup services that make sure that your data is backed up regularly. And in case things go wrong, you can always use the backup to recover your data easily.",
  },

  {
    id: 3,
    img: blog3,
    desImage: altImage3,
    banner: "",
    heading1: "What is web designing?",
    heading2: "Factors of website design ?",
    heading3: " What is the right design for your website ? ",
    date: "April 18, 2022",
    author: "Shubha Agrawal",
    title: "Creative Web Designer in Kolkata",
    tags: [
      { tag: "Business Plans" },
      { tag: "Competitive Research" },
      { tag: "Economics" },
      { tag: "Finance & Accounting" },
      { tag: "Funding Trends" },
      { tag: "Innovation" },
    ],

    description:
      "Today, no matter which industry you belong to, having a website is a must. The website helps you discover new clients and spread brand awareness, thus increasing the number of sales and leads. It becomes important for businesses to adapt to the new technology and marketing tactics and give their users an experience that an offline store can't provide. Today, before visiting your offline store, your customers visit your website first to get an idea of the products and services you offer. And hence your website should be creative and must have the ability to hold your them take your desired CTA. web application designer in Kolkata, have helped various businesses create their online presence and run it smoothly, giving their users the experience they want. As IT technology changes very often your website has to be designed in a way that can beat every update. Having a strong online presence that is appealing to your visitors can yield effective results and help your business to accomplish its goals. Web designing is the creation of websites and pages which focus on factors like layout, user interface, and other visual imagery to ensure a user-friendly experience that is appealing to the visitors. Your website is the primary face of your brand when a visitor visits your website the homepage has to tell the story of what your brand is all about, that's the work of a creative web designer . With the involvement of mobile and tablet browsers since 2010, web design has become really important. A web designer works on the appearance, layout, relates to the colour, font, graphics, and images to be used, and makes sure that the design tells about the brand and is pleasing to the visitors.",

    heading2Text:
      "Nowadays brands focus more on creating a simple website than on innumerable graphics affecting the loading speed, mobile-friendliness, and much more. Even the smallest mistake in your web design can ruin the experience of your customer and they will end up never visiting your website again Designing a website depends on the following factors: A visitor to your website will fall for your brand and its products and services if your website is creative and appealing. It's advisable to keep your website simple and clean. Too much text and graphics can distract the users and make them confused about navigating your website. This will throw them away from your Key-Value Proposition (KPI). It's better to show them via images and graphics what your brand offers and your unique selling points (USPs).  is one of the most important strategies that every business has to follow whether big or small. The placement and design of your brand's logo are what a customer sees first when they visit your website. The logo of your brand tells very much about your brand and has to be eye-catching. Select a location on your website to place your logo and the name of your brand so that it could be visible to the visitors. A good logo can help your visitors to memorise your brand.",

    heading3Text:
      "A design that is easy to navigate and communicate with your visitors easily is always the right design for every website. A unique and cool experience can attract your customers but if it starts lagging, it will cost you the loss of a few potential customers. Your website design  should be creative and depend on the niche of your brand. With the help of a website designer in Kolkata , you can easily get a website that fulfills all the factors. Your website needs to tell the story of what your business does, why should your visitors care about your brand, and ultimately make a sale. Inserting a CTA button on every page can make your visitors take action and not be confused. Get in touch with the UI/UX designer in Kolkata  to know more. ",

    endText:
      "If your visitors need to wait a few minutes so that your website can load completely, they will leave your site. The website users today are too impatient. Nearly half of the website visitors expect a website to load within 2 to 3 seconds. Your load time must be 2 seconds or less to make your visitors stay. Image optimization can help your website load faster. If you are looking for a creative web designer in Kolkata , Webapps Software Solutions is the best option. Check out their amazing works and book your appointment today!",
  },

  {
    id: 4,
    img: blog4,
    desImage: altImage4,
    banner: "",
    heading1: "Do you really need a website designer ?",
    heading2: "How to choose the right web designing company  for your brand ?",
    heading3: " What is the right design for your website ? ",
    date: "April 18, 2022",
    author: "Shubha Agrawal",
    title: "How to choose the web designing company in Kolkata",

    tags: [
      { tag: "Business Plans" },
      { tag: "Competitive Research" },
      { tag: "Economics" },
      { tag: "Finance & Accounting" },
      { tag: "Funding Trends" },
      { tag: "Innovation" },
    ],

    description:
      " With the rise in digitalization, it is mandatory for every business to have its website. Not having one can lower down your chances to attract new customers and compete with your competitors if you have a website but having a website is not enough. You should have a website that's designed to catch people's attention; a website that is a replica of your brand. Your website works as a sales team for your business. Today, before visiting a store, users have a habit of checking the website of that store to get an idea of what product they offer and also compare prices of similar products. when they are completely satisfied then they visit the offline store to make the final purchase. So, having an offline presence without an online presence could be a threat to your business.  Being a creative web designer in Kolkata , Webapps software solutions help their clients to get a website that stands on every aspect of a website.They have experience with every variety of website designing, of course, website designing is not as easy as it sounds. It requires significant thoughts and efforts according to the client's business and requirements so that the visitors of the website stay engaged. Your visitors do care about your website design if your website is eye-catching and soothing to the eyes visuals are good, and if people naturally get drawn this will have a good impact on your website and ultimately your sales. Not only this, but people also judge your business and make their decisions through your website. If you come across a website that's too bright and cluttered you will leave that website and never return.",

    heading2Text:
      "Let's move on to our main topic: how to choose the right web design company for your brand? Choosing the right web designing company  can be a boon or a bane. Either it can make your website a success or leave you with a failure. But now that you know how a website design agency could be a game-changer, it's necessary to hire one. Following the below tips can assist you in choosing the right web design company for your business Before you start searching for a website designing company , make a list of all your website needs and goals. As the owner, you must have an idea of where your website lacks. Check the data, and consult with your developers and marketing team as to what your website lacks and the features it should have to make you achieve your goals. Making a list of all your wants will also help you and your meeting with the agency. You will be able to pitch in your needs and check the reaction of the agencies. ",

    heading3Text:
      "Now that you are done with making a list of all your needs, it's time to hunt for the best agency for your plan. Make a list of all the national and local web design agencies that guarantee a good design. Don't worry you can even higher a national agency without any problem zone and Google meets have made it easy to collaborate with agencies all over the world. You can search for agencies in your local area and nationwide if you want one that is experienced and has rendered services to many clients. Now that you have shortlisted the agencies you want to partner with, it's time to check their portfolio, clients, previous works, and reviews on different sites and local directories. Three things are really important to consider: experience, culture, and portfolio. visit the websites of all the shortlisted agencies and check their works and their website. Only if their website matches your goals and standards, can they fulfil your needs.",

    endText:
      "The agency that you choose will not only cost you money but effort and time too. The changes they do to your existing website can be a success and if not can have an adverse effect. If you are looking for a web designing agency in Kolkata , Webapps Software Solutions is the answer. They have an in-house team of developers and designers and they have experience with a variety of industries. Check out their website and book an appointment today! ",
  },

  {
    id: 5,
    img: blog5,
    desImage: altImage5,
    banner: "",
    heading1: "What is responsive design ?",
    heading2: "Importance Of Responsive Design ?",
    heading3: " How to make your website responsive ?",
    date: "April 18, 2022",
    author: "Shubha Agrawal",
    title: "What is responsive design and why it is important ?",

    tags: [
      { tag: "Business Plans" },
      { tag: "Competitive Research" },
      { tag: "Economics" },
      { tag: "Finance & Accounting" },
      { tag: "Funding Trends" },
      { tag: "Innovation" },
    ],

    description:
      " Today, the market is full of varieties of devices. A user's journey across the internet has become more complicated with time. Since 2015, mobile traffic has increased with the increase in the number of smartphones. Talking about stats, more than 79% of all online searches come from mobile devices. People hope from one device to another, accessing the data they want from different devices such as smartphones, tablets, laptops, desktop computers, TVs, and smartwatches. To make sure your website loads on every device and looks equally good on each screen of various shapes and sizes, you must make your website responsive. Every business going digitally, the competition has increased online in every industry. Your website has to be responsive to stand out from your competitors and give your visitors a good user experience. It's a fact that most of the online traffic comes from mobile, but your website has to look equally good on every device. If someone opens your website on a smartphone, it will appear different than the same page on a laptop or a desktop. The site reformats the pages according to the device so that the user does not face any problems with your website. If you're looking for a website designer in Kolkata , you are at the right place. Webapps Software Solutions is one of the best web designing company in Kolkata .",

    heading2Text:
      "As we have discussed, a website that loads on every device will provide the best user experience to your visitors. If you have created a website for desktops and a mobile user visits it, they will have to zoom out and resize it according to the device's size. As the competition has increased, no one wants to put in this much effort. The visitor will leave the site and hop on to another. As we have discussed, a website that loads on every device will provide the best user experience to your visitors. If you have created a website for desktops and a mobile user visits it, they will have to zoom out and resize it according to the device's size. As the competition has increased, no one wants to put in this much effort. The visitor will leave the site and hop on to another.  If your website is mobile-friendly, you will get traffic from mobile users. With more than half of the global internet traffic being occupied by mobile traffic, it's essential that your website loads on mobiles. You will see a drastic increase in your website visits and also the duration spent by them on your website if you make it responsive. Google gives preference to the responsive website, i.e. loads on every device. It is one of the factors of Google for ranking the websites on search engine result pages. In case your website is not responsive, it will lower your chances of ranking.",

    heading3Text:
      "The first and most important step is to set up different sizes for different layout elements, which depend on the media query. Creating a fluid grid will help you size the elements of your site proportionately, depending on the size of the device. Rather than making different elements, it's easier to size one thing for different screens. The responsive grid is divided into columns, and the heights and widths of those columns are scaled. The proportions of the height and weight depend on how large the screen is. They are not constant. The users have the best experience with the fluid grid regardless of the device they view your responsive website on. A website looks different when opened on different devices. If you view a website on a desktop, it's quite different from what you see on a mobile phone. That's what responsive design is about. If you are using images, keeping your mobile visitors in mind, images might break on the desktop version. So, you have to make sure you always include a 'srcset' with different sizes of your photo while adding images to your pages. Just like images, you have to take care of your website text as well. The main focus of responsive web design is on the responsiveness of the layout: the blocks, elements, and media. Texts are taken care of after that. SCSS specification includes a unit named 'rems', which works almost identically to the 'em' unit. They help in the alignment of text on both the desktop and mobile.",

    endText:
      "Responsive design can give you good returns and can help you improve your SEO. If you are looking for an agency that caters to all your website needs, Webapps Software Solutions is a one-stop solution. Only an agency that is also a ",
  },

  {
    id: 6,
    img: blog6,
    desImage: altImage5,
    banner: "",
    heading1: "Do you need to keep your website secure ?",
    heading2: "Are you technically skilled in securing your website ?",
    heading3: " How to choose a smart password ?",
    date: "April 18, 2022",
    author: "Shubha Agrawal",
    title: "5 Tips to Secure a website",

    tags: [
      { tag: "Business Plans" },
      { tag: "Competitive Research" },
      { tag: "Economics" },
      { tag: "Finance & Accounting" },
      { tag: "Funding Trends" },
      { tag: "Innovation" },
    ],

    description:
      "With every business going online, the number of websites has increased, and so has the chances of getting them hacked. Webapps Software Solution , the leading  website developer in Kolkata , is here to give you some tips on how you can secure your website from swindling activities. You might think that your website has nothing important that a hacker would be interested in, but a website is compromised. The hacking is not only done to mess up with your data or change the website layout but the server can be used as an email relay for spam or to set up a temporary web server and conduct illegal activities. Data breaches are common practice. According to a study, it is believed that every 39 seconds, a website is hacked, and around 2200 websites are hacked every day. And hence, this makes securing a website essential. It helps you avoid bugs, but you can also save your data from hackers. Even when a website is essential for a business, people tend to neglect the security needed to save it from hackers. They think that if they are not making money through their website, they do not need protection. But the truth is quite different. It is not just about losing money; hacking a website can cost you the traffic you receive to your website. The efforts you have put into its SEO till now can all go in vain. Your site might get suspended or crash, and most importantly, your data and the data of your visitors might be at risk. For example, when customers use their credit card or debit card for online transactions, they feed the data because they are sure that the data is safe with your website. No one wants their data to fall into the wrong hands. Whether you own a small business or a big enterprise, providing a safe online experience to your users is a must.",

    heading2Text:
      "Well, you don't have to be tech-savvy to secure your website. You need to take care or make some changes to your website, and you are good to go. You can also hire a website development agency Kolkata  that offers websites with all the security and implement all the elements required to secure a website. Nothing can guarantee you a hacker-free website, and there is always a chance that your website can get hacked. But, if you take care of the following steps, you might secure your website from being hacked. You must have come across the SSL when you browse the internet. The 's' in 'HTTPS' stands for secure and ensures that the website has the SSL certificate. Google warns the visitors of websites without SSL if they want to proceed with it, claiming that the website is not secured and is unsafe. Also, not having an SSL certificate can affect your ranking on Search Engine Result Pages. Choose a password that will take the hacker an eternity to figure out. Keeping a web password like 12345678 or 111111 or 'password,' which is really easy to guess, is like inviting the hackers to hack your website. Use a combination of numbers, symbols, uppercase, and lowercase letters. The password must be at least 12 characters long. Create a unique password for every new login request. Create a password that is complicated, random, and of course, difficult to guess and change it after every three months or so. Try to avoid repeating the passwords.",

    heading3Text:
      "Choose a password that will take the hacker an eternity to figure out. Keeping a web password like 12345678 or 111111 or 'password,' which is really easy to guess, is like inviting the hackers to hack your website. Use a combination of numbers, symbols, uppercase, and lowercase letters. The password must be at least 12 characters long. Create a unique password for every new login request. Create a password that is complicated, random, and of course, difficult to guess and change it after every three months or so. Try to avoid repeating the passwords. To keep your website healthy and provide security, you have to update your software and plugins installed frequently. Hackers and bots are scanning continuously to attack sites that lack updates and security. Take the request for updates to your software and plugins seriously and try to act on it ASAP. Hackers are quite smart in their business. They can mail you a link or comment on your post, a link that can make them hack your website. Manually approve the comments on your website and stay alert while you receive an email with a link attached from an untrusted source. ",

    endText:
      "Now that you know how to secure your website, you can easily save your and your users data from getting hacked. But, if you still find it difficult, you can seek help from the website developer in Kolkata , which has experience in working with every industry and has served its clients with the best services. You can also reach out to them if you are looking for a web designing company in Kolkata  for the best services. ",
  },

  {
    id: 6,
    img: blog6,
    desImage: altImage6,
    banner: "",
    heading1: "Do you realy need a website designer ?",
    heading2: "How to choose the right web designing company for your brand ?",
    heading3: " How to choose a smart password ?",
    date: "April 18, 2022",
    author: "Shubha Agrawal",
    title: "How to choose the Web Design company in Kolkata ?",

    tags: [
      { tag: "Business Plans" },
      { tag: "Competitive Research" },
      { tag: "Economics" },
      { tag: "Finance & Accounting" },
      { tag: "Funding Trends" },
      { tag: "Innovation" },
    ],

    description:
      " With the rise in digitalization, it is mandatory for every business to have its website. Not having one can lower down your chances to attract new customers and compete with your competitors if you have a website but having a website is not enough. You should have a website that's designed to catch people's attention; a website that is a replica of your brand. Your website works as a sales team for your business. Today, before visiting a store, users have a habit of checking the website of that store to get an idea of what product they offer and also compare prices of similar products. when they are completely satisfied then they visit the offline store to make the final purchase. So, having an offline presence without an online presence could be a threat to your business.  Being a creative web designer in Kolkata  , Webapps software solutions help their clients to get a website that stands on every aspect of a website.They have experience with every variety of website designing, of course, website designing is not as easy as it sounds. It requires significant thoughts and efforts according to the client's business and requirements so that the visitors of the website stay engaged. Your visitors do care about your website design if your website is eye-catching and soothing to the eyes visuals are good, and if people naturally get drawn this will have a good impact on your website and ultimately your sales. Not only this, but people also judge your business and make their decisions through your website. If you come across a website that's too bright and cluttered you will leave that website and never return.",

    heading2Text:
      "Let's move on to our main topic: how to choose the right web design company for your brand? Choosing the right web designing company  can be a boon or a bane. Either it can make your website a success or leave you with a failure. But now that you know how a website design agency could be a game-changer, it's necessary to hire one. Following the below tips can assist you in choosing the right web design company for your business Make a list of your website needs and website goals Before you start searching for a website designing company , make a list of all your website needs and goals. As the owner, you must have an idea of where your website lacks. Check the data, and consult with your developers and marketing team as to what your website lacks and the features it should have to make you achieve your goals. Making a list of all your wants will also help you and your meeting with the agency. You will be able to pitch in your needs and check the reaction of the agencies. Now that you are done with making a list of all your needs, it's time to hunt for the best agency for your plan. Make a list of all the national and local web design agencies that guarantee a good design.Dont worry you can even higher a national agency without any problem zone and Google meets have made it easy to collaborate with agencies all over the world. You can search for agencies in your local area and nationwide if you want one that is experienced and has rendered services to many clients. ",

    heading3Text:
      "Now that you are done with making a list of all your needs, it's time to hunt for the best agency for your plan. Make a list of all the national and local web design agencies that guarantee a good design.Don't worry you can even higher a national agency without any problem zone and Google meets have made it easy to collaborate with agencies all over the world. You can search for agencies in your local area and nationwide if you want one that is experienced and has rendered services to many clients. Now that you have shortlisted the agencies you want to partner with, it's time to check their portfolio, clients, previous works, and reviews on different sites and local directories. Three things are really important to consider: experience, culture, and portfolio. visit the websites of all the shortlisted agencies and check their works and their website. Only if their website matches your goals and standards, can they fulfil your needs.",

    endText:
      "The agency that you choose will not only cost you money but effort and time too. The changes they do to your existing website can be a success and if not can have an adverse effect. If you are looking for a web designing agency in Kolkata , Webapps Software Solutions is the answer. They have an in-house team of developers and designers and they have experience with a variety of industries. Check out their website and book an appointment today! ",
  },
];
